import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-07dcef36"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["xlink:href"]

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    class: _normalizeClass(_ctx.className),
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("title", _hoisted_1, _toDisplayString(_ctx.title || _ctx.name), 1))
      : _createCommentVNode("", true),
    _createElementVNode("use", {
      "xlink:href": _ctx.iconPath,
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }, null, 8, _hoisted_2)
  ], 2))
}