export type { ILoading } from './type'

export const DEFAULT_LOADING_VALUE = false

export const STORY_SETTINGS = {
  name: 'loading',
  type: { name: 'boolean', required: false },
  table: {
    type: { summary: 'boolean' },
    defaultValue: { summary: DEFAULT_LOADING_VALUE },
  },
  control: 'boolean',
  description:
    'Element is disabled and indicates that it waiting for loading ends',
  defaultValue: DEFAULT_LOADING_VALUE,
}

export default {
  type: Boolean,
  default: DEFAULT_LOADING_VALUE,
}
