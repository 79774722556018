import { FilterCounterInfo, DashboardInfoItem } from './types'

export interface State {
  dashboardInfo: DashboardInfoItem[]
  counterInfo: FilterCounterInfo
  isLoaded: boolean
}

export default (): State => ({
  dashboardInfo: [],
  counterInfo: { interventions: '', awaitingInterventions: '', time: '' },
  isLoaded: false,
})
