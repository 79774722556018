export type { INative } from './types'

export const DEFAULT_NATIVE_VALUE = false

export const STORY_SETTINGS = {
  name: 'native',
  type: { name: 'boolean', required: false },
  table: {
    type: { summary: 'boolean' },
    defaultValue: { summary: DEFAULT_NATIVE_VALUE },
  },
  control: 'boolean',
  description: 'Indicates that component will use native element',
  defaultValue: DEFAULT_NATIVE_VALUE,
}

export default {
  type: Boolean,
  default: DEFAULT_NATIVE_VALUE,
}
