import { IClientsInfo, CompanyInfoResponse } from './types'

export interface State {
  companyInfo: CompanyInfoResponse[]
  organismInfo: CompanyInfoResponse[]
  clientsInfo: IClientsInfo[]
  isLoaded: boolean
}

export default (): State => ({
  companyInfo: [],
  organismInfo: [],
  clientsInfo: [],
  isLoaded: false,
})
