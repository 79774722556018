import { mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-06d38de8"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["checked", "value"]

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("input", _mergeProps({
    type: "radio",
    checked: _ctx.modelValue === _ctx.value,
    value: _ctx.value
  }, _ctx.$attrs, {
    onChange: _cache[0] || (_cache[0] = $event => (_ctx.$emit('update:modelValue', _ctx.value))),
    class: [
    `ui-native-radio`,
  ]
  }), null, 16, _hoisted_1))
}