import { defineComponent } from 'vue'
import { default as props } from './props'

export default defineComponent({
  name: 'QNativeCheckbox',
  props,
  emits: ['update:checked', 'change'],
  setup() {
    return {}
  },
})
