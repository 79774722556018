import {
  createStore,
  createLogger,
  Store as VuexStore,
  useStore as baseUseStore,
} from 'vuex'
import { InjectionKey } from 'vue'

import api, { Store as ApiStore } from './api'
import { State as ApiState } from './api/state'

import auth, { Store as AuthStore } from './auth'
import { State as AuthState } from './auth/state'

import dashboard, { Store as DashboardStore } from './dashboard'
import { State as DashboardState } from './dashboard/state'

import monitoring, { Store as MonitoringStore } from './monitoring'
import { State as MonitoringState } from './monitoring/state'

import realEstate, { Store as RealEstateStore } from './space'
import { State as RealEstateState } from './space/state'

import userSettings, { Store as UserSettingsStore } from './settings'
import { State as userSettingsState } from './settings/state'

import activity, { Store as ActivityStore } from './device'
import { State as activityState } from './device/state'

import company, { Store as CompanyStore } from './company'
import { State as companyState } from './company/state'

import space, { Store as SpaceStore } from './space'
import { State as spaceState } from './space/state'

import contact, { Store as ContactStore } from './contact'
import { State as contactState } from './contact/state'

export const storeKey: InjectionKey<VuexStore<RootState>> = Symbol()

export interface RootState {
  api: ApiState
  auth: AuthState
  dashboard: DashboardState
  monitoring: MonitoringState
  realEstate: RealEstateState
  userSettings: userSettingsState
  activity: activityState
  company: companyState
  space: spaceState
  contact: contactState
}

export type RootStore = ApiStore<Pick<RootState, 'api'>> &
  AuthStore<Pick<RootState, 'auth'>> &
  DashboardStore<Pick<RootState, 'dashboard'>> &
  MonitoringStore<Pick<RootState, 'monitoring'>> &
  RealEstateStore<Pick<RootState, 'realEstate'>> &
  SpaceStore<Pick<RootState, 'space'>> &
  UserSettingsStore<Pick<RootState, 'userSettings'>> &
  ActivityStore<Pick<RootState, 'activity'>> &
  CompanyStore<Pick<RootState, 'company'>> &
  ContactStore<Pick<RootState, 'contact'>>

export const store = createStore<RootState>({
  modules: {
    api,
    auth,
    dashboard,
    monitoring,
    realEstate,
    userSettings,
    activity,
    company,
    space,
    contact
  },
  plugins: process.env.NODE_ENV !== 'production' ? [createLogger()] : [],
  strict: !!process.env.DEBUGGING,
})

export default function useStore(): RootStore {
  return baseUseStore(storeKey)
}
