export type { IKeyValue } from './types'

export const DEFAULT_KEY_VALUE_VALUE = 'value'

export const STORY_SETTINGS = {
  name: 'keyValue',
  type: { name: 'string', required: false },
  table: {
    type: { summary: 'string' },
    defaultValue: { summary: DEFAULT_KEY_VALUE_VALUE },
  },
  control: 'text',
  description: 'The value key in the options object',
  defaultValue: DEFAULT_KEY_VALUE_VALUE,
}

export default {
  type: String,
  default: DEFAULT_KEY_VALUE_VALUE,
}
