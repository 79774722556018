export type { IAutocomplete } from './types'

export const DEFAULT_AUTOCOMPLETE_VALUE = 'off'

export const STORY_SETTINGS = {
  name: 'autocomplete',
  type: { name: 'string', required: false },
  table: {
    type: { summary: 'string' },
    defaultValue: { summary: DEFAULT_AUTOCOMPLETE_VALUE },
  },
  control: 'text',
  description:
    'The attribute specifies whether or not an input field should have autocomplete enabled or specify it',
  defaultValue: DEFAULT_AUTOCOMPLETE_VALUE,
}

export default {
  type: String,
  default: DEFAULT_AUTOCOMPLETE_VALUE,
}
