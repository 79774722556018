import { defineComponent, computed, ref, watch } from 'vue'
import { IProps } from './props'
import props from './props'

export default defineComponent({
  props,
  setup(props: IProps) {
    const className = computed(
      () => `ui-icon ui-icon__${props.name} ${props.size}`
    )
    const iconPath = ref<string>()
    const getIconPath = async () => {
      let icon = await import(`@/assets/icons/${props.name}.svg`)
      if (Object.prototype.hasOwnProperty.call(icon, 'default')) {
        icon = icon.default
      }
      iconPath.value = icon.url
    }

    watch(
      () => props.name,
      () => {
        getIconPath()
      },
      {
        immediate: true,
      }
    )

    return {
      ...props,
      className,
      iconPath,
    }
  },
})
