import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache) {
  const _component_UiIcon = _resolveComponent("UiIcon")
  const _component_UiInputText = _resolveComponent("UiInputText")

  return (_openBlock(), _createBlock(_component_UiInputText, _mergeProps({ type: "search" }, {..._ctx.$attrs, ..._ctx.$props}, {
    "model-value": _ctx.modelValue,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (_ctx.$emit('update:modelValue', $event))),
    class: "ui-input-search"
  }), _createSlots({
    append: _withCtx(() => [
      _withDirectives(_createElementVNode("div", {
        class: "ui-input-search__icon",
        onClick: _cache[0] || (_cache[0] = $event => (_ctx.$emit('update:modelValue', '')))
      }, [
        _createVNode(_component_UiIcon, { name: "cross" })
      ], 512), [
        [_vShow, _ctx.modelValue]
      ])
    ]),
    _: 2
  }, [
    _renderList(_ctx.$slots, (_, slot) => {
      return {
        name: slot,
        fn: _withCtx((scope) => [
          _renderSlot(_ctx.$slots, slot, _normalizeProps(_guardReactiveProps(scope)), undefined, true)
        ])
      }
    })
  ]), 1040, ["model-value"]))
}