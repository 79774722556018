import { NON_DIGITS_REGEX } from '@/helpers/regex'
import i18n from '@/plugins/i18n'
const {
  global: { t },
} = i18n

export const sleep = (ms: number): Promise<void> =>
  new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve()
    }, ms)
  })

export const minutesToHours = (minutes: number): string => {
  return Math.floor(minutes / 60) + 'h'
}

export const hoursToMinutes = (hours: string): number => {
  if (hours === t('ACTIVITY_TABLE_IMMEDIATELY')) {
    return 0
  } else {
    return parseInt(hours.replace(NON_DIGITS_REGEX, '')) * 60
  }
}
