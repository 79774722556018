import { RouteRecordRaw, RouteComponent } from 'vue-router'

//Layouts
const MainLayout = (): RouteComponent => import('../layouts/MainLayout.vue')
const AuthLayout = (): RouteComponent => import('../layouts/AuthLayout.vue')
const SettingsLayout = (): RouteComponent =>
  import('../layouts/SettingsLayout.vue')

//Auth routes
const AuthorizationView = (): RouteComponent =>
  import('../views/auth/AuthorizationView.vue')
const ForgetPasswordView = (): RouteComponent =>
  import('../views/auth/ForgetPasswordView.vue')
const ResetPasswordView = (): RouteComponent =>
  import('../views/auth/ResetPasswordView.vue')

//Main routes
const HomeView = (): RouteComponent => import('../views/HomeView.vue')
const RealEstateView = (): RouteComponent => import('../views/RealEstateView.vue')
const ActivityView = (): RouteComponent => import('../views/ActivityView.vue')
const MonitoringView = (): RouteComponent => import('../views/MonitoringView.vue')
const ContactView = (): RouteComponent => import('../views/ContactView.vue')

//Settings
const MySettingsView = (): RouteComponent =>
  import('../views/settings/MySettingsView.vue')
const PasswordSettingsView = (): RouteComponent =>
  import('../views/settings/PasswordSettingsView.vue')
const UsersSettingsView = (): RouteComponent =>
  import('../views/settings/UsersSettingsView.vue')

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'MainLayout',
    component: MainLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'HomeView',
        component: HomeView,
      },
      {
        path: '/real-estate',
        name: 'RealEstateView',
        component: RealEstateView,
      },
      {
        path: '/device',
        name: 'ActivityView',
        component: ActivityView,
      },
      {
        path: '/monitoring',
        name: 'MonitoringView',
        component: MonitoringView,
      },
      {
        path: '/contact',
        name: 'ContactView',
        component: ContactView,
      },
      {
        path: '/settings',
        name: 'SettingsLayout',
        component: SettingsLayout,
        children: [
          {
            path: '',
            name: 'MySettingsView',
            component: MySettingsView,
          },
          {
            path: '/settings/my-password',
            name: 'PasswordSettingsView',
            component: PasswordSettingsView,
          },
          {
            path: '/settings/users',
            name: 'UsersSettingsView',
            component: UsersSettingsView,
          },
        ],
      },
    ],
  },
  {
    path: '/auth',
    name: 'AuthorizationLayout',
    component: AuthLayout,
    meta: {
      requiresAuth: false,
    },
    children: [
      {
        path: '',
        name: 'AuthorizationView',
        component: AuthorizationView,
      },
      {
        path: '/forget-password',
        name: 'ForgetPasswordView',
        component: ForgetPasswordView,
      },
      {
        path: '/reset-password/:id',
        name: 'ResetPasswordView',
        component: ResetPasswordView,
      },
    ],
  },
]

export default routes
