import { MutationTree } from 'vuex'
import { get } from 'lodash'
import { State } from './state'

export enum SPACE_MUTATION_TYPES {
  SET_SPACES = 'SET_SPACES',
  SET_SPACES_FILTER = 'SET_SPACES_FILTER',
  SET_LOADING_STATE = 'SET_LOADING_STATE',
}

export type Mutations = {
  [SPACE_MUTATION_TYPES.SET_SPACES](state: State, spaces: any): void
  [SPACE_MUTATION_TYPES.SET_LOADING_STATE](state: State): void
}

export const mutations: MutationTree<State> & Mutations = {
  [SPACE_MUTATION_TYPES.SET_SPACES](state, spaces) {
    const mappingSpaces = spaces
      .filter((item: any) => item.type === 'batiment')
      .map((item: any) => {
        const guardian = item.guardian
        return {
          id: item.id,
          type: item.type,
          parent: item.parent,
          parentName: item.parent.name,
          levelMin: item.levelMin,
          levelMax: item.levelMax,
          stairsMin: item.stairsMin,
          stairsMax: item.stairsMax,
          address: item.address,
          city: item.city,
          entryCode1: item.entryCode1,
          entryCode2: item.entryCode2,
          build: item.name,
          zipCode: item.zipCode,
          guardian,
          guardianName: guardian?.firstname + ' ' + guardian?.lastname,
          guardianMail: guardian?.email,
          guardianPhone: guardian?.phoneNumber,
          manager: item.parent.manager,
          managerName: item.parent.manager.firstname + ' ' + item.parent.manager.lastname,
          managerMail: item.parent.manager.email,
          managerPhone: item.parent.manager.phoneNumber,
          companyName: item.company.name,
          company: item.company,
          userOrganization: item.userOrganization,
        }
      })
      const mappingResidenceList = spaces
      .filter((item: any) => item.type === 'residence')
      .map((item: any) => {
        return {
          id: item.id,
          type: item.type,
          address: item.address,
          city: item.city,
          entryCode1: item.entryCode1,
          entryCode2: item.entryCode2,
          build: item.name,
          zipCode: item.zipCode,
          manager: item.manager,
          managerName: item.manager.firstname + ' ' + item.manager.lastname,
          managerMail: item.manager.email,
          managerPhone: item.manager.phoneNumber,
          companyName: item.company.name,
          company: item.company,
          userOrganization: item.userOrganization,
        }
      })
    let mappingGuardians = mappingSpaces.map((item: any) => {
      return {
        id: item.guardian?.id,
        iri: "/contacts/" + item.guardian?.id,
        type: item.guardian?.type,
        company: item.guardian?.company,
        email: item.guardian?.email,
        firstname: item.guardian?.firstname,
        lastname: item.guardian?.lastname,
        phoneNumber: item.guardian?.phoneNumber,
        userOrganization: item.guardian?.userOrganization,
        name: item.guardian?.firstname + ' ' + item.guardian?.lastname,
      }
    })
    mappingGuardians = mappingGuardians.filter((value: any, index: any, self: any) =>
      index === self.findIndex((t: any) => (
        t.id === value.id
      ))
    )
    let mappingManagers = mappingSpaces.map((item: any) => {
      return {
        id: item.manager.id,
        iri: "/contacts/" + item.manager.id,
        type: item.manager.type,
        company: item.manager.company,
        email: item.manager.email,
        firstname: item.manager.firstname,
        lastname: item.manager.lastname,
        phoneNumber: item.manager.phoneNumber,
        userOrganization: item.manager.userOrganization,
        name: item.manager.firstname + ' ' + item.manager.lastname,
      }
    })
    mappingManagers = mappingManagers.filter((value: any, index: any, self: any) =>
      index === self.findIndex((t: any) => (
        t.id === value.id
      ))
    )
    let mappingResidence = mappingSpaces.map((item: any) => {
      return {
        id: item.parent.id,
        iri: "/spaces/" + item.parent.id,
        type: item.parent.type,
        company: item.parent.company,
        build: item.parent.name,
      }
    })
    mappingResidence = mappingResidence.filter((value: any, index: any, self: any) =>
      index === self.findIndex((t: any) => (
        t.id === value.id
      ))
    )
    let mappingCompany = mappingSpaces.map((item: any) => {
      return {
        id: item.company.id,
        iri: "/companies/" + item.company.id,
        companyType: item.company.companyType,
        userOrganization: item.company.userOrganization,
        name: item.company.name,
      }
    })
    mappingCompany = mappingCompany.filter((value: any, index: any, self: any) =>
      index === self.findIndex((t: any) => (
        t.id === value.id
      ))
    )
    state.managerFilter = mappingManagers
    state.guardianFilter = mappingGuardians
    state.companyFilter = mappingCompany
    state.residenceFilter = mappingResidence
    state.residenceList = mappingResidenceList
    state.spaces = mappingSpaces
    state.isLoaded = false
  },
  [SPACE_MUTATION_TYPES.SET_SPACES_FILTER](state, spaces) {
    const mappingSpaces = spaces
      .filter((item: any) => item.type === 'batiment')
      .map((item: any) => {
        return {
          id: item.id,
          type: item.type,
          parent: item.parent,
          parentName: item.parent.name,
          levelMin: item.levelMin,
          levelMax: item.levelMax,
          stairsMin: item.stairsMin,
          stairsMax: item.stairsMax,
          address: item.address,
          city: item.city,
          entryCode1: item.entryCode1,
          entryCode2: item.entryCode2,
          build: item.name,
          zipCode: item.zipCode,
          guardian: item.guardian,
          guardianName: item.guardian.firstname + ' ' + item.guardian.lastname,
          guardianMail: item.guardian.email,
          guardianPhone: item.guardian.phoneNumber,
          manager: item.parent.manager,
          managerName: item.parent.manager.firstname + ' ' + item.parent.manager.lastname,
          managerMail: item.parent.manager.email,
          managerPhone: item.parent.manager.phoneNumber,
          companyName: item.company.name,
          company: item.company,
          userOrganization: item.userOrganization,
        }
      })
    state.spaces = mappingSpaces
    state.isLoaded = false
  },
  [SPACE_MUTATION_TYPES.SET_LOADING_STATE](state) {
    state.isLoaded = true
  },
}
