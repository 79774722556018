import { computed, defineComponent, ref, watch, ComputedRef } from 'vue'
import {
  default as props,
  IProps,
  SortDirection,
  IDataItem,
  IHeader,
} from './props'
import { filterDataByString, getSortedDataByField } from './methods'

import UiPagination from '../Pagination/Pagination.vue'
import UiSelect from '../Select/Select.vue'
import UiInputCheckbox from '../InputCheckbox/InputCheckbox.vue'
import UiNativeSelect from '../NativeSelect/NativeSelect.vue'

export interface IModifiedDataItem extends IDataItem {
  _tableItemId: number
  _lastPriorityEl: boolean
}

export default defineComponent({
  components: {
    UiPagination,
    UiSelect,
    UiInputCheckbox,
    UiNativeSelect,
  },
  props,
  emits: [
    'update:page',
    'update:perPage',
    'update:sortColumn',
    'update:sortDirection',
    'onSelectAll',
    'onSelectNone',
    'loadMore',
  ],
  setup(props: IProps, { emit }) {
    const page = ref(1)
    const sortColumn = ref(props.defaultSortColumn)
    const sortDirection = ref(props.defaultSortDirection)

    const selectedAll = ref(false)

    const onSort = (header: IHeader): void => {
      if (!props.sortable || !header.sortable) {
        return
      }

      const column = header.field

      if (column === sortColumn.value) {
        switch (sortDirection.value) {
          case SortDirection.ASC:
            sortDirection.value = SortDirection.DESC
            break
          case SortDirection.DESC:
            sortDirection.value = null
            sortColumn.value = null
            break
          case null:
            sortDirection.value = SortDirection.ASC
            break
        }
      } else {
        sortColumn.value = column
        sortDirection.value = SortDirection.ASC
      }
    }

    const filteredData = computed(() => {
      let data = [...props.data]
      props.filters.forEach((filter) => {
        if (typeof filter === 'string') {
          data = filterDataByString(filter, data)
        }
        if (typeof filter === 'function') data = filter(data)
      })
      return data
    })

    const tableData: ComputedRef<IDataItem[]> = computed(() => {
      const { sortable, priorityRowKey, pagination, perPage } = props
      let data = filteredData.value

      if (sortable && sortColumn.value && sortDirection.value) {
        const customSort = props.headers.find(
          (header) => header.field === sortColumn.value
        )?.customSort

        if (typeof customSort === 'function') {
          data = data.sort((rowA, rowB) => {
            const res = customSort(rowA, rowB)
            return sortDirection.value === SortDirection.DESC ? res : res * -1
          })
        } else
          data = getSortedDataByField(
            data,
            sortColumn.value,
            sortDirection.value
          )
      }

      if (priorityRowKey) {
        data = getSortedDataByField(data, priorityRowKey, SortDirection.DESC)
        data.forEach((el) => (el._lastPriorityEl = false))
        const priorityRows = data.filter((el) => el[priorityRowKey])
        priorityRows.length &&
          (priorityRows[priorityRows.length - 1]._lastPriorityEl = true)
      }

      if (pagination) {
        data = data.slice(perPage * (page.value - 1), perPage * page.value)
      }

      return data
    })

    const cellTitle = (val: unknown) => {
      if (typeof val === 'string' || typeof val === 'number') return val
      return null
    }

    const showMoreButton = computed(() => {
      return page.value * props.perPage < filteredData.value.length
    })
    const showNoData = computed(() => {
      return !showLoading.value && !props.data.length
    })
    const showNoFilteredData = computed(() => {
      return !showLoading.value && props.data.length && !tableData.value.length
    })
    const showData = computed(() => {
      return !showLoading.value && tableData.value.length
    })
    const showLoading = computed(() => {
      return props.loading
    })

    watch(sortColumn, (value) => {
      emit('update:sortColumn', value)
    })
    watch(sortDirection, (value) => {
      emit('update:sortDirection', value)
    })

    return {
      filteredData,
      tableData,
      page,
      sortColumn,
      sortDirection,
      selectedAll,
      onSort,
      cellTitle,
      showData,
      showLoading,
      showNoData,
      showNoFilteredData,
      showMoreButton,
    }
  },
})
