import { ActionTree } from 'vuex'
import { Mutations, API_MUTATION_TYPES } from './mutations'
import { State } from './state'
import { RootState } from '@/store'
import { v4 as uuid } from 'uuid'

import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios'
import { $axios } from '@/plugins/axios'
import { GenericActionContext } from '../types'
import { isTokenExist, getToken, getRefreshToken, isRefreshTokenExist,deleteToken } from '@/helpers/token'

import { AUTH_ACTION_TYPES } from '@/store/auth/actions'

export interface ApiCallConfig extends AxiosRequestConfig {
  requestId?: string
  showLoading?: boolean
  type: string
}

export enum API_ACTION_TYPES {
  API_CALL = 'API_API_CALL',
  CANCEL_REQUEST = 'API_CANCEL_REQUEST',
  CANCEL_ALL_REQUESTS = 'API_CANCEL_ALL_REQUESTS',
}

type AugmentedActionContext = GenericActionContext<State, Mutations>

export type Actions = {
  [API_ACTION_TYPES.API_CALL](
    ctx: AugmentedActionContext,
    payload: ApiCallConfig
  ): Promise<unknown>
  [API_ACTION_TYPES.CANCEL_REQUEST](
    ctx: AugmentedActionContext,
    requestId: string
  ): Promise<true>
  [API_ACTION_TYPES.CANCEL_ALL_REQUESTS](
    ctx: AugmentedActionContext
  ): Promise<true>
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [API_ACTION_TYPES.API_CALL]({ commit, dispatch }, config) {
    const requestId = config.requestId ?? uuid();
    const showLoading = config.showLoading ?? true;
    let headers;
    if(config.method ==='PATCH')
    {
       headers = {
        Authorization: '',
        "content-type" : "application/merge-patch+json",
        "Access-Control-Allow-Origin": "https://dev.api.hyperion-smart-building.app/"
       }
    }
    else
    {
      headers = {
        Authorization: '',
        "Access-Control-Allow-Origin": "https://dev.api.hyperion-smart-building.app/"
       }
    }

    

    if (isTokenExist()) {
      headers.Authorization = `Bearer ${getToken()}`
    }

    try {
      const CancelToken = axios.CancelToken
      const { data }: AxiosResponse = await $axios.request({
        ...config,
        headers,
        cancelToken: new CancelToken((handler) => {
          commit(API_MUTATION_TYPES.SET_REQUEST, {
            requestId,
            handler,
            showLoading,
          })
        }),
      })

      return Promise.resolve(data)
    } catch (error) {
      const axiosErr = error as AxiosError
      const errorStatus = axiosErr.response?.status
      const data = axiosErr.response?.data
      if( data && data.message === "Expired JWT Token")
      {
        deleteToken()
      }else{
        if (errorStatus === 401 && isTokenExist() && isRefreshTokenExist()) {
          try {
            await dispatch(AUTH_ACTION_TYPES.REFRESH_TOKEN, getRefreshToken())
            const reCall = dispatch(API_ACTION_TYPES.API_CALL, config)
            return Promise.resolve(reCall)
          } catch (e) {
            return Promise.reject(e)
          }
        }
      }
      return Promise.reject(error)
    } finally {
      commit(API_MUTATION_TYPES.DELETE_REQUEST, requestId)
    }
  },
  [API_ACTION_TYPES.CANCEL_REQUEST](
    { state, commit },
    requestId
  ): Promise<true> {
    if (state.requests[requestId]) {
      commit(API_MUTATION_TYPES.DELETE_REQUEST, requestId)
    }
    return Promise.resolve(true)
  },
  [API_ACTION_TYPES.CANCEL_ALL_REQUESTS]({ state, dispatch }): Promise<true> {
    Object.keys(state.requests).forEach((key) => {
      dispatch('CANCEL_REQUEST', key)
    })
    return Promise.resolve(true)
  },
}
