import { ActionTree } from 'vuex'
import { Mutations, DEVICE_MUTATION_TYPES } from './mutations'
import { State } from './state'
import { RootState } from '@/store'
import { baseURL } from '@/plugins/axios'
import { ApiCallConfig, API_ACTION_TYPES } from '../api/actions'
import { ActivityInfoResponse, IDevice, IClientsInfo, mockedActivityInfoResponse, mockedClientInfoResponse } from './types'
import { GenericActionContext } from '../types'
import { sleep } from '@/helpers/utils'

export enum DEVICE_ACTION_TYPES {
  GET_DEVICE_INFO = 'GET_DEVICE_INFO',
  POST_DEVICE_INFO = 'POST_DEVICE_INFO',
  GET_ONE_DEVICE_INFO = 'GET_ONE_DEVICE_INFO',
  PUT_ONE_DEVICE_INFO = 'PUT_ONE_DEVICE_INFO',
  DELETE_ONE_DEVICE_INFO = 'DELETE_ONE_DEVICE_INFO',
  PATCH_ONE_DEVICE_INFO = 'PATCH_ONE_DEVICE_INFO',

  GET_DEVICE_BRAND = 'GET_DEVICE_BRAND',
  POST_DEVICE_BRAND = 'POST_DEVICE_BRAND',
  GET_ONE_DEVICE_BRAND = 'GET_ONE_DEVICE_BRAND',
  PUT_ONE_DEVICE_BRAND = 'PUT_ONE_DEVICE_BRAND',
  DELETE_ONE_DEVICE_BRAND = 'DELETE_ONE_DEVICE_BRAND',
  PATCH_ONE_DEVICE_BRAND = 'PATCH_ONE_DEVICE_BRAND',

  GET_DEVICE_DATA = 'GET_DEVICE_DATA',
  POST_DEVICE_DATA = 'POST_DEVICE_DATA',
  GET_ONE_DEVICE_DATA = 'GET_ONE_DEVICE_DATA',
  PUT_ONE_DEVICE_DATA = 'PUT_ONE_DEVICE_DATA',
  DELETE_ONE_DEVICE_DATA = 'DELETE_ONE_DEVICE_DATA',
  PATCH_ONE_DEVICE_DATA = 'PATCH_ONE_DEVICE_DATA',

  GET_DEVICE_MODEL = 'GET_DEVICE_MODEL',
  POST_DEVICE_MODEL = 'POST_DEVICE_MODEL',
  GET_ONE_DEVICE_MODEL = 'GET_ONE_DEVICE_MODEL',
  PUT_ONE_DEVICE_MODEL = 'PUT_ONE_DEVICE_MODEL',
  DELETE_ONE_DEVICE_MODEL = 'DELETE_ONE_DEVICE_MODEL',
  PATCH_ONE_DEVICE_MODEL = 'PATCH_ONE_DEVICE_MODEL',

  GET_DEVICE_SHARING_REQUEST = 'GET_DEVICE_SHARING_REQUEST',
  POST_DEVICE_SHARING_REQUEST = 'POST_DEVICE_SHARING_REQUEST',
  GET_ONE_DEVICE_SHARING_REQUEST = 'GET_ONE_DEVICE_SHARING_REQUEST',
  PUT_ONE_DEVICE_SHARING_REQUEST = 'PUT_ONE_DEVICE_SHARING_REQUEST',
  DELETE_ONE_DEVICE_SHARING_REQUEST = 'DELETE_ONE_DEVICE_SHARING_REQUEST',
  PATCH_ONE_DEVICE_SHARING_REQUEST = 'PATCH_ONE_DEVICE_SHARING_REQUEST',

  GET_DEVICE_USE_PARAM = 'GET_DEVICE_USE_PARAM',
  POST_DEVICE_USE_PARAM = 'POST_DEVICE_USE_PARAM',
  GET_ONE_DEVICE_USE_PARAM = 'GET_ONE_DEVICE_USE_PARAM',
  PUT_ONE_DEVICE_USE_PARAM = 'PUT_ONE_DEVICE_USE_PARAM',
  DELETE_ONE_DEVICE_USE_PARAM = 'DELETE_ONE_DEVICE_USE_PARAM',
  PATCH_ONE_DEVICE_USE_PARAM = 'PATCH_ONE_DEVICE_USE_PARAM',

  GET_LICENCED_DEVICE_USE_PARAM = 'GET_LICENCED_DEVICE_USE_PARAM',
  POST_LICENCED_DEVICE_USE_PARAM = 'POST_LICENCED_DEVICE_USE_PARAM',
  GET_ONE_LICENCED_DEVICE_USE_PARAM = 'GET_ONE_LICENCED_DEVICE_USE_PARAM',
  PUT_ONE_LICENCED_DEVICE_USE_PARAM = 'PUT_ONE_LICENCED_DEVICE_USE_PARAM',
  DELETE_ONE_LICENCED_DEVICE_USE_PARAM = 'DELETE_ONE_LICENCED_DEVICE_USE_PARAM',
  PATCH_ONE_LICENCED_DEVICE_USE_PARAM = 'PATCH_ONE_LICENCED_DEVICE_USE_PARAM',

  GET_DEVICE_USE = 'GET_DEVICE_USE',
  POST_DEVICE_USE = 'POST_DEVICE_USE',
  GET_ONE_DEVICE_USE = 'GET_ONE_DEVICE_USE',
  PUT_ONE_DEVICE_USE = 'PUT_ONE_DEVICE_USE',
  DELETE_ONE_DEVICE_USE = 'DELETE_ONE_DEVICE_USE',
  PATCH_ONE_DEVICE_USE = 'PATCH_ONE_DEVICE_USE',

  UPDATE_RESIDENCE = 'UPDATE_RESIDENCE',
  DELETE_RESIDENCE = 'DELETE_RESIDENCE',
  GET_CLIENTS_INFO = 'GET_CLIENTS_INFO',
  ADD_NEW_RESIDENCE = 'ADD_NEW_RESIDENCE',
}

enum DEVICE_API_ENDPOINT {
  DEVICES = 'devices',
  DEVICE_BRAND = 'device_brands',
  DEVICE_DATA = 'device_datas',
  DEVICE_MODEL = 'device_models',
  DEVICE_SHARING_REQUEST = 'device_sharing_requests',
  DEVICE_USE_PARAM = 'device_use_params',
  DEVICE_USE = 'device_uses',
  LICENCED_DEVICE_USE_PARAM = 'licenced_device_use_params',
}

type AugmentedActionContext = GenericActionContext<State, Mutations>

export type Actions = {
  [DEVICE_ACTION_TYPES.GET_DEVICE_INFO](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<ActivityInfoResponse>
  [DEVICE_ACTION_TYPES.POST_DEVICE_INFO](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<ActivityInfoResponse>
  [DEVICE_ACTION_TYPES.GET_ONE_DEVICE_INFO](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<ActivityInfoResponse>
  [DEVICE_ACTION_TYPES.PUT_ONE_DEVICE_INFO](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<ActivityInfoResponse>
  [DEVICE_ACTION_TYPES.DELETE_ONE_DEVICE_INFO](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<ActivityInfoResponse>
  [DEVICE_ACTION_TYPES.PATCH_ONE_DEVICE_INFO](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<ActivityInfoResponse>

  [DEVICE_ACTION_TYPES.GET_DEVICE_BRAND](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<ActivityInfoResponse>
  [DEVICE_ACTION_TYPES.POST_DEVICE_BRAND](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<ActivityInfoResponse>
  [DEVICE_ACTION_TYPES.GET_ONE_DEVICE_BRAND](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<ActivityInfoResponse>
  [DEVICE_ACTION_TYPES.PUT_ONE_DEVICE_BRAND](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<ActivityInfoResponse>
  [DEVICE_ACTION_TYPES.DELETE_ONE_DEVICE_BRAND](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<ActivityInfoResponse>
  [DEVICE_ACTION_TYPES.PATCH_ONE_DEVICE_BRAND](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<ActivityInfoResponse>

  [DEVICE_ACTION_TYPES.GET_DEVICE_DATA](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<ActivityInfoResponse>
  [DEVICE_ACTION_TYPES.POST_DEVICE_DATA](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<ActivityInfoResponse>
  [DEVICE_ACTION_TYPES.GET_ONE_DEVICE_DATA](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<ActivityInfoResponse>
  [DEVICE_ACTION_TYPES.PUT_ONE_DEVICE_DATA](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<ActivityInfoResponse>
  [DEVICE_ACTION_TYPES.DELETE_ONE_DEVICE_DATA](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<ActivityInfoResponse>
  [DEVICE_ACTION_TYPES.PATCH_ONE_DEVICE_DATA](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<ActivityInfoResponse>

  [DEVICE_ACTION_TYPES.GET_DEVICE_MODEL](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<ActivityInfoResponse>
  [DEVICE_ACTION_TYPES.POST_DEVICE_MODEL](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<ActivityInfoResponse>
  [DEVICE_ACTION_TYPES.GET_ONE_DEVICE_MODEL](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<ActivityInfoResponse>
  [DEVICE_ACTION_TYPES.PUT_ONE_DEVICE_MODEL](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<ActivityInfoResponse>
  [DEVICE_ACTION_TYPES.DELETE_ONE_DEVICE_MODEL](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<ActivityInfoResponse>
  [DEVICE_ACTION_TYPES.PATCH_ONE_DEVICE_MODEL](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<ActivityInfoResponse>

  [DEVICE_ACTION_TYPES.GET_DEVICE_SHARING_REQUEST](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<ActivityInfoResponse>
  [DEVICE_ACTION_TYPES.POST_DEVICE_SHARING_REQUEST](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<ActivityInfoResponse>
  [DEVICE_ACTION_TYPES.GET_ONE_DEVICE_SHARING_REQUEST](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<ActivityInfoResponse>
  [DEVICE_ACTION_TYPES.PUT_ONE_DEVICE_SHARING_REQUEST](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<ActivityInfoResponse>
  [DEVICE_ACTION_TYPES.DELETE_ONE_DEVICE_SHARING_REQUEST](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<ActivityInfoResponse>
  [DEVICE_ACTION_TYPES.PATCH_ONE_DEVICE_SHARING_REQUEST](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<ActivityInfoResponse>

  [DEVICE_ACTION_TYPES.GET_DEVICE_USE_PARAM](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<ActivityInfoResponse>
  [DEVICE_ACTION_TYPES.POST_DEVICE_USE_PARAM](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<ActivityInfoResponse>
  [DEVICE_ACTION_TYPES.GET_ONE_DEVICE_USE_PARAM](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<ActivityInfoResponse>
  [DEVICE_ACTION_TYPES.PUT_ONE_DEVICE_USE_PARAM](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<ActivityInfoResponse>
  [DEVICE_ACTION_TYPES.DELETE_ONE_DEVICE_USE_PARAM](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<ActivityInfoResponse>
  [DEVICE_ACTION_TYPES.PATCH_ONE_DEVICE_USE_PARAM](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<ActivityInfoResponse>

  [DEVICE_ACTION_TYPES.GET_LICENCED_DEVICE_USE_PARAM](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<ActivityInfoResponse>
  [DEVICE_ACTION_TYPES.POST_LICENCED_DEVICE_USE_PARAM](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<ActivityInfoResponse>
  [DEVICE_ACTION_TYPES.GET_ONE_LICENCED_DEVICE_USE_PARAM](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<ActivityInfoResponse>
  [DEVICE_ACTION_TYPES.PUT_ONE_LICENCED_DEVICE_USE_PARAM](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<ActivityInfoResponse>
  [DEVICE_ACTION_TYPES.DELETE_ONE_LICENCED_DEVICE_USE_PARAM](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<ActivityInfoResponse>
  [DEVICE_ACTION_TYPES.PATCH_ONE_LICENCED_DEVICE_USE_PARAM](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<ActivityInfoResponse>

  [DEVICE_ACTION_TYPES.GET_DEVICE_USE](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<ActivityInfoResponse>
  [DEVICE_ACTION_TYPES.POST_DEVICE_USE](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<ActivityInfoResponse>
  [DEVICE_ACTION_TYPES.GET_ONE_DEVICE_USE](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<ActivityInfoResponse>
  [DEVICE_ACTION_TYPES.PUT_ONE_DEVICE_USE](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<ActivityInfoResponse>
  [DEVICE_ACTION_TYPES.DELETE_ONE_DEVICE_USE](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<ActivityInfoResponse>
  [DEVICE_ACTION_TYPES.PATCH_ONE_DEVICE_USE](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<ActivityInfoResponse>

  [DEVICE_ACTION_TYPES.UPDATE_RESIDENCE](
    arg1: AugmentedActionContext,
    payload: IDevice
  ): Promise<unknown>
  [DEVICE_ACTION_TYPES.DELETE_RESIDENCE](
    arg1: AugmentedActionContext,
    id: number
  ): Promise<unknown>
  [DEVICE_ACTION_TYPES.GET_CLIENTS_INFO](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<IClientsInfo>
  [DEVICE_ACTION_TYPES.ADD_NEW_RESIDENCE](
    arg1: AugmentedActionContext,
    residence: unknown
  ): Promise<unknown>
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [DEVICE_ACTION_TYPES.GET_DEVICE_INFO](
    { dispatch, commit },
    cancelToken
  ) {
    commit(DEVICE_MUTATION_TYPES.SET_LOADING_STATE)
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + DEVICE_API_ENDPOINT.DEVICES,
        requestId: cancelToken,
        method: 'GET',
        type: API_ACTION_TYPES.API_CALL,
      })
      console.log("res", res)
      // await sleep(1500)
      // const res = mockedActivityInfoResponse
      commit(DEVICE_MUTATION_TYPES.SET_DEVICE_INFO, res.activityInfo)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DEVICE_ACTION_TYPES.POST_DEVICE_INFO](
    { dispatch, commit },
    cancelToken
  ) {
    commit(DEVICE_MUTATION_TYPES.SET_LOADING_STATE)
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + DEVICE_API_ENDPOINT.DEVICES,
        requestId: cancelToken,
        method: 'POST',
        data: {
          id: "",
          manufacturerRef: "",
          networkId: "",
          status: "",
          batteryLevel: 0,
          equipmentState: 0,
          networkType: "",
          deviceModel: "",
          deviceUse: "",
          createdBy: "",
          createdAt: "",
          updatedBy: "",
          updatedAt: "",
          deletedBy: "",
          deletedAt: "",
          isActive: true,
          active: true
        },
        type: API_ACTION_TYPES.API_CALL,
      })
      console.log("res", res)
      // await sleep(1500)
      // const res = mockedActivityInfoResponse
      commit(DEVICE_MUTATION_TYPES.SET_DEVICE_INFO, res.activityInfo)
      dispatch(DEVICE_ACTION_TYPES.GET_DEVICE_INFO)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DEVICE_ACTION_TYPES.GET_ONE_DEVICE_INFO](
    { dispatch, commit },
    id,
  ) {
    commit(DEVICE_MUTATION_TYPES.SET_LOADING_STATE) // chargement en cours
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + DEVICE_API_ENDPOINT.DEVICES + "/" + id,
        method: 'GET',
        type: API_ACTION_TYPES.API_CALL,
      })
      commit(DEVICE_MUTATION_TYPES.SET_DEVICE_INFO, res.activityInfo)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DEVICE_ACTION_TYPES.PUT_ONE_DEVICE_INFO](
    { dispatch, commit },
    id,
  ) {
    commit(DEVICE_MUTATION_TYPES.SET_LOADING_STATE)
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + DEVICE_API_ENDPOINT.DEVICES + "/" + id,
        method: 'PUT',
        data: {
          id: "",
          manufacturerRef: "",
          networkId: "",
          status: "",
          batteryLevel: 0,
          equipmentState: 0,
          networkType: "",
          deviceModel: "",
          deviceUse: "",
          createdBy: "",
          createdAt: "",
          updatedBy: "",
          updatedAt: "",
          deletedBy: "",
          deletedAt: "",
          isActive: true,
          active: true
        },
        type: API_ACTION_TYPES.API_CALL,
      })
      console.log("res", res)
      // await sleep(1500)
      // const res = mockedActivityInfoResponse
      commit(DEVICE_MUTATION_TYPES.SET_DEVICE_INFO, res.activityInfo)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DEVICE_ACTION_TYPES.DELETE_ONE_DEVICE_INFO](
    { dispatch, commit },
    id,
  ) {
    commit(DEVICE_MUTATION_TYPES.SET_LOADING_STATE)
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + DEVICE_API_ENDPOINT.DEVICES + "/" + id,
        method: 'DELETE',
        type: API_ACTION_TYPES.API_CALL,
      })
      console.log("res", res)
      // await sleep(1500)
      // const res = mockedActivityInfoResponse
      commit(DEVICE_MUTATION_TYPES.SET_DEVICE_INFO, res.activityInfo)
      dispatch(DEVICE_ACTION_TYPES.GET_DEVICE_INFO)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DEVICE_ACTION_TYPES.PATCH_ONE_DEVICE_INFO](
    { dispatch, commit },
    id,
  ) {
    commit(DEVICE_MUTATION_TYPES.SET_LOADING_STATE)
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + DEVICE_API_ENDPOINT.DEVICES + "/" + id,
        method: 'PATCH',
        data: {
          manufacturerRef: "",
          networkId: "",
          status: "",
          batteryLevel: 0,
          equipmentState: 0,
          networkType: "",
          deviceModel: "",
          deviceUse: "",
          createdBy: "",
          createdAt: "",
          updatedBy: "",
          updatedAt: "",
          deletedBy: "",
          deletedAt: "",
          isActive: true,
          active: true
        },
        type: API_ACTION_TYPES.API_CALL,
      })
      console.log("res", res)
      // await sleep(1500)
      // const res = mockedActivityInfoResponse
      commit(DEVICE_MUTATION_TYPES.SET_DEVICE_INFO, res.activityInfo)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },


  async [DEVICE_ACTION_TYPES.GET_DEVICE_BRAND](
    { dispatch, commit },
    cancelToken
  ) {
    commit(DEVICE_MUTATION_TYPES.SET_LOADING_STATE)
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + DEVICE_API_ENDPOINT.DEVICE_BRAND,
        requestId: cancelToken,
        method: 'GET',
        type: API_ACTION_TYPES.API_CALL,
      })
      console.log("res", res)
      // await sleep(1500)
      // const res = mockedActivityInfoResponse
      commit(DEVICE_MUTATION_TYPES.SET_DEVICE_INFO, res.activityInfo)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DEVICE_ACTION_TYPES.POST_DEVICE_BRAND](
    { dispatch, commit },
    cancelToken
  ) {
    commit(DEVICE_MUTATION_TYPES.SET_LOADING_STATE)
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + DEVICE_API_ENDPOINT.DEVICE_BRAND,
        requestId: cancelToken,
        method: 'POST',
        data: {
          name: "",
          isActive: true
        },
        type: API_ACTION_TYPES.API_CALL,
      })
      console.log("res", res)
      // await sleep(1500)
      // const res = mockedActivityInfoResponse
      commit(DEVICE_MUTATION_TYPES.SET_DEVICE_INFO, res.activityInfo)
      dispatch(DEVICE_ACTION_TYPES.GET_DEVICE_BRAND)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DEVICE_ACTION_TYPES.GET_ONE_DEVICE_BRAND](
    { dispatch, commit },
    id,
  ) {
    commit(DEVICE_MUTATION_TYPES.SET_LOADING_STATE) // chargement en cours
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + DEVICE_API_ENDPOINT.DEVICE_BRAND + "/" + id,
        method: 'GET',
        type: API_ACTION_TYPES.API_CALL,
      })
      commit(DEVICE_MUTATION_TYPES.SET_DEVICE_INFO, res.activityInfo)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DEVICE_ACTION_TYPES.PUT_ONE_DEVICE_BRAND](
    { dispatch, commit },
    id,
  ) {
    commit(DEVICE_MUTATION_TYPES.SET_LOADING_STATE)
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + DEVICE_API_ENDPOINT.DEVICE_BRAND + "/" + id,
        method: 'PUT',
        data: {
          name: "",
          isActive: true
        },
        type: API_ACTION_TYPES.API_CALL,
      })
      console.log("res", res)
      // await sleep(1500)
      // const res = mockedActivityInfoResponse
      commit(DEVICE_MUTATION_TYPES.SET_DEVICE_INFO, res.activityInfo)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DEVICE_ACTION_TYPES.DELETE_ONE_DEVICE_BRAND](
    { dispatch, commit },
    id,
  ) {
    commit(DEVICE_MUTATION_TYPES.SET_LOADING_STATE)
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + DEVICE_API_ENDPOINT.DEVICE_BRAND + "/" + id,
        method: 'DELETE',
        type: API_ACTION_TYPES.API_CALL,
      })
      console.log("res", res)
      // await sleep(1500)
      // const res = mockedActivityInfoResponse
      commit(DEVICE_MUTATION_TYPES.SET_DEVICE_INFO, res.activityInfo)
      dispatch(DEVICE_ACTION_TYPES.GET_DEVICE_BRAND)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DEVICE_ACTION_TYPES.PATCH_ONE_DEVICE_BRAND](
    { dispatch, commit },
    id,
  ) {
    commit(DEVICE_MUTATION_TYPES.SET_LOADING_STATE)
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + DEVICE_API_ENDPOINT.DEVICE_BRAND + "/" + id,
        method: 'PATCH',
        data: {
          name: "",
          isActive: true
        },
        type: API_ACTION_TYPES.API_CALL,
      })
      console.log("res", res)
      // await sleep(1500)
      // const res = mockedActivityInfoResponse
      commit(DEVICE_MUTATION_TYPES.SET_DEVICE_INFO, res.activityInfo)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },


  async [DEVICE_ACTION_TYPES.GET_DEVICE_DATA](
    { dispatch, commit },
    cancelToken
  ) {
    commit(DEVICE_MUTATION_TYPES.SET_LOADING_STATE)
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + DEVICE_API_ENDPOINT.DEVICE_DATA,
        requestId: cancelToken,
        method: 'GET',
        type: API_ACTION_TYPES.API_CALL,
      })
      console.log("res", res)
      // await sleep(1500)
      // const res = mockedActivityInfoResponse
      commit(DEVICE_MUTATION_TYPES.SET_DEVICE_INFO, res.activityInfo)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DEVICE_ACTION_TYPES.POST_DEVICE_DATA](
    { dispatch, commit },
    cancelToken
  ) {
    commit(DEVICE_MUTATION_TYPES.SET_LOADING_STATE)
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + DEVICE_API_ENDPOINT.DEVICE_DATA,
        requestId: cancelToken,
        method: 'POST',
        data: {
          rawData: "",
          receivedAt: "",
          decodedData: "",
          device: ""
        },
        type: API_ACTION_TYPES.API_CALL,
      })
      console.log("res", res)
      // await sleep(1500)
      // const res = mockedActivityInfoResponse
      commit(DEVICE_MUTATION_TYPES.SET_DEVICE_INFO, res.activityInfo)
      dispatch(DEVICE_ACTION_TYPES.GET_DEVICE_DATA)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DEVICE_ACTION_TYPES.GET_ONE_DEVICE_DATA](
    { dispatch, commit },
    id,
  ) {
    commit(DEVICE_MUTATION_TYPES.SET_LOADING_STATE) // chargement en cours
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + DEVICE_API_ENDPOINT.DEVICE_DATA + "/" + id,
        method: 'GET',
        type: API_ACTION_TYPES.API_CALL,
      })
      commit(DEVICE_MUTATION_TYPES.SET_DEVICE_INFO, res.activityInfo)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DEVICE_ACTION_TYPES.PUT_ONE_DEVICE_DATA](
    { dispatch, commit },
    id,
  ) {
    commit(DEVICE_MUTATION_TYPES.SET_LOADING_STATE)
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + DEVICE_API_ENDPOINT.DEVICE_DATA + "/" + id,
        method: 'PUT',
        data: {
          rawData: "string",
          receivedAt: "CURRENT_TIMESTAMP",
          decodedData: "string",
          device: "string"
        },
        type: API_ACTION_TYPES.API_CALL,
      })
      console.log("res", res)
      // await sleep(1500)
      // const res = mockedActivityInfoResponse
      commit(DEVICE_MUTATION_TYPES.SET_DEVICE_INFO, res.activityInfo)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DEVICE_ACTION_TYPES.DELETE_ONE_DEVICE_DATA](
    { dispatch, commit },
    id,
  ) {
    commit(DEVICE_MUTATION_TYPES.SET_LOADING_STATE)
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + DEVICE_API_ENDPOINT.DEVICE_DATA + "/" + id,
        method: 'DELETE',
        type: API_ACTION_TYPES.API_CALL,
      })
      console.log("res", res)
      // await sleep(1500)
      // const res = mockedActivityInfoResponse
      commit(DEVICE_MUTATION_TYPES.SET_DEVICE_INFO, res.activityInfo)
      dispatch(DEVICE_ACTION_TYPES.GET_DEVICE_DATA)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DEVICE_ACTION_TYPES.PATCH_ONE_DEVICE_DATA](
    { dispatch, commit },
    id,
  ) {
    commit(DEVICE_MUTATION_TYPES.SET_LOADING_STATE)
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + DEVICE_API_ENDPOINT.DEVICE_DATA + "/" + id,
        method: 'PATCH',
        data: {
          rawData: "string",
          receivedAt: "CURRENT_TIMESTAMP",
          decodedData: "string",
          device: "string"
        },
        type: API_ACTION_TYPES.API_CALL,
      })
      console.log("res", res)
      // await sleep(1500)
      // const res = mockedActivityInfoResponse
      commit(DEVICE_MUTATION_TYPES.SET_DEVICE_INFO, res.activityInfo)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DEVICE_ACTION_TYPES.GET_DEVICE_MODEL](
    { dispatch, commit },
    cancelToken
  ) {
    commit(DEVICE_MUTATION_TYPES.SET_LOADING_STATE)
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + DEVICE_API_ENDPOINT.DEVICE_MODEL,
        requestId: cancelToken,
        method: 'GET',
        type: API_ACTION_TYPES.API_CALL,
      })
      console.log("res", res)
      // await sleep(1500)
      // const res = mockedActivityInfoResponse
      commit(DEVICE_MUTATION_TYPES.SET_DEVICE_INFO, res.activityInfo)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DEVICE_ACTION_TYPES.POST_DEVICE_MODEL](
    { dispatch, commit },
    cancelToken
  ) {
    commit(DEVICE_MUTATION_TYPES.SET_LOADING_STATE)
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + DEVICE_API_ENDPOINT.DEVICE_MODEL,
        requestId: cancelToken,
        method: 'POST',
        data: {
          downlinkDataString: "",
          brand: "",
          networkType: "",
          isActive: true
        },
        type: API_ACTION_TYPES.API_CALL,
      })
      console.log("res", res)
      // await sleep(1500)
      // const res = mockedActivityInfoResponse
      commit(DEVICE_MUTATION_TYPES.SET_DEVICE_INFO, res.activityInfo)
      dispatch(DEVICE_ACTION_TYPES.GET_DEVICE_MODEL)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DEVICE_ACTION_TYPES.GET_ONE_DEVICE_MODEL](
    { dispatch, commit },
    id,
  ) {
    commit(DEVICE_MUTATION_TYPES.SET_LOADING_STATE) // chargement en cours
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + DEVICE_API_ENDPOINT.DEVICE_MODEL + "/" + id,
        method: 'GET',
        type: API_ACTION_TYPES.API_CALL,
      })
      commit(DEVICE_MUTATION_TYPES.SET_DEVICE_INFO, res.activityInfo)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DEVICE_ACTION_TYPES.PUT_ONE_DEVICE_MODEL](
    { dispatch, commit },
    id,
  ) {
    commit(DEVICE_MUTATION_TYPES.SET_LOADING_STATE)
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + DEVICE_API_ENDPOINT.DEVICE_MODEL + "/" + id,
        method: 'PUT',
        data: {
          downlinkDataString: "",
          brand: "",
          networkType: "",
          isActive: true
        },
        type: API_ACTION_TYPES.API_CALL,
      })
      console.log("res", res)
      // await sleep(1500)
      // const res = mockedActivityInfoResponse
      commit(DEVICE_MUTATION_TYPES.SET_DEVICE_INFO, res.activityInfo)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DEVICE_ACTION_TYPES.DELETE_ONE_DEVICE_MODEL](
    { dispatch, commit },
    id,
  ) {
    commit(DEVICE_MUTATION_TYPES.SET_LOADING_STATE)
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + DEVICE_API_ENDPOINT.DEVICE_MODEL + "/" + id,
        method: 'DELETE',
        type: API_ACTION_TYPES.API_CALL,
      })
      console.log("res", res)
      // await sleep(1500)
      // const res = mockedActivityInfoResponse
      commit(DEVICE_MUTATION_TYPES.SET_DEVICE_INFO, res.activityInfo)
      dispatch(DEVICE_ACTION_TYPES.GET_DEVICE_INFO)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DEVICE_ACTION_TYPES.PATCH_ONE_DEVICE_MODEL](
    { dispatch, commit },
    id,
  ) {
    commit(DEVICE_MUTATION_TYPES.SET_LOADING_STATE)
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + DEVICE_API_ENDPOINT.DEVICE_MODEL + "/" + id,
        method: 'PATCH',
        data: {
          downlinkDataString: "",
          brand: "",
          networkType: "",
          isActive: true
        },
        type: API_ACTION_TYPES.API_CALL,
      })
      console.log("res", res)
      // await sleep(1500)
      // const res = mockedActivityInfoResponse
      commit(DEVICE_MUTATION_TYPES.SET_DEVICE_INFO, res.activityInfo)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DEVICE_ACTION_TYPES.GET_DEVICE_SHARING_REQUEST](
    { dispatch, commit },
    cancelToken
  ) {
    commit(DEVICE_MUTATION_TYPES.SET_LOADING_STATE)
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + DEVICE_API_ENDPOINT.DEVICE_SHARING_REQUEST,
        requestId: cancelToken,
        method: 'GET',
        type: API_ACTION_TYPES.API_CALL,
      })
      console.log("res", res)
      // await sleep(1500)
      // const res = mockedActivityInfoResponse
      commit(DEVICE_MUTATION_TYPES.SET_DEVICE_INFO, res.activityInfo)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DEVICE_ACTION_TYPES.POST_DEVICE_SHARING_REQUEST](
    { dispatch, commit },
    cancelToken
  ) {
    commit(DEVICE_MUTATION_TYPES.SET_LOADING_STATE)
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + DEVICE_API_ENDPOINT.DEVICE_SHARING_REQUEST,
        requestId: cancelToken,
        method: 'POST',
        data: {
          status: "",
          acceptedBy: "",
          acceptedAt: "",
          revokedBy: "",
          revokedAt: "",
          requestingUserOrg: "",
          device: "",
          createdBy: "",
          createdAt: ""
        },
        type: API_ACTION_TYPES.API_CALL,
      })
      console.log("res", res)
      // await sleep(1500)
      // const res = mockedActivityInfoResponse
      commit(DEVICE_MUTATION_TYPES.SET_DEVICE_INFO, res.activityInfo)
      dispatch(DEVICE_ACTION_TYPES.GET_DEVICE_SHARING_REQUEST)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DEVICE_ACTION_TYPES.GET_ONE_DEVICE_SHARING_REQUEST](
    { dispatch, commit },
    id,
  ) {
    commit(DEVICE_MUTATION_TYPES.SET_LOADING_STATE) // chargement en cours
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + DEVICE_API_ENDPOINT.DEVICE_SHARING_REQUEST + "/" + id,
        method: 'GET',
        type: API_ACTION_TYPES.API_CALL,
      })
      commit(DEVICE_MUTATION_TYPES.SET_DEVICE_INFO, res.activityInfo)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DEVICE_ACTION_TYPES.PUT_ONE_DEVICE_SHARING_REQUEST](
    { dispatch, commit },
    id,
  ) {
    commit(DEVICE_MUTATION_TYPES.SET_LOADING_STATE)
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + DEVICE_API_ENDPOINT.DEVICE_SHARING_REQUEST + "/" + id,
        method: 'PUT',
        data: {
          status: "",
          acceptedBy: "",
          acceptedAt: "",
          revokedBy: "",
          revokedAt: "",
          requestingUserOrg: "",
          device: "",
          createdBy: "",
          createdAt: ""
        },
        type: API_ACTION_TYPES.API_CALL,
      })
      console.log("res", res)
      // await sleep(1500)
      // const res = mockedActivityInfoResponse
      commit(DEVICE_MUTATION_TYPES.SET_DEVICE_INFO, res.activityInfo)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DEVICE_ACTION_TYPES.DELETE_ONE_DEVICE_SHARING_REQUEST](
    { dispatch, commit },
    id,
  ) {
    commit(DEVICE_MUTATION_TYPES.SET_LOADING_STATE)
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + DEVICE_API_ENDPOINT.DEVICE_SHARING_REQUEST + "/" + id,
        method: 'DELETE',
        type: API_ACTION_TYPES.API_CALL,
      })
      console.log("res", res)
      // await sleep(1500)
      // const res = mockedActivityInfoResponse
      commit(DEVICE_MUTATION_TYPES.SET_DEVICE_INFO, res.activityInfo)
      dispatch(DEVICE_ACTION_TYPES.GET_DEVICE_SHARING_REQUEST)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DEVICE_ACTION_TYPES.PATCH_ONE_DEVICE_SHARING_REQUEST](
    { dispatch, commit },
    id,
  ) {
    commit(DEVICE_MUTATION_TYPES.SET_LOADING_STATE)
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + DEVICE_API_ENDPOINT.DEVICE_SHARING_REQUEST + "/" + id,
        method: 'PATCH',
        data: {
          status: "",
          acceptedBy: "",
          acceptedAt: "",
          revokedBy: "",
          revokedAt: "",
          requestingUserOrg: "",
          device: "",
          createdBy: "",
          createdAt: ""
        },
        type: API_ACTION_TYPES.API_CALL,
      })
      console.log("res", res)
      // await sleep(1500)
      // const res = mockedActivityInfoResponse
      commit(DEVICE_MUTATION_TYPES.SET_DEVICE_INFO, res.activityInfo)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DEVICE_ACTION_TYPES.GET_DEVICE_USE_PARAM](
    { dispatch, commit },
    cancelToken
  ) {
    commit(DEVICE_MUTATION_TYPES.SET_LOADING_STATE)
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + DEVICE_API_ENDPOINT.DEVICE_USE_PARAM,
        requestId: cancelToken,
        method: 'GET',
        type: API_ACTION_TYPES.API_CALL,
      })
      console.log("res", res)
      // await sleep(1500)
      // const res = mockedActivityInfoResponse
      commit(DEVICE_MUTATION_TYPES.SET_DEVICE_INFO, res.activityInfo)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DEVICE_ACTION_TYPES.POST_DEVICE_USE_PARAM](
    { dispatch, commit },
    cancelToken
  ) {
    commit(DEVICE_MUTATION_TYPES.SET_LOADING_STATE)
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + DEVICE_API_ENDPOINT.DEVICE_USE_PARAM,
        requestId: cancelToken,
        method: 'POST',
        data: {
          mandatoryManagerConfirmation: true,
          mandatoryLocalVerif: true,
          mandatoryOsNumber: true,
          interventionDelay: 0,
          interventionAutoClosing: true,
          sendInfoToManager: true,
          sendInfoToMaintProvider: true,
          space: "",
          deviceUse: "",
          createdBy: "",
          createdAt: "",
          deletedBy: "",
          deletedAt: "",
          updatedBy: "",
          updatedAt: ""
        },
        type: API_ACTION_TYPES.API_CALL,
      })
      console.log("res", res)
      // await sleep(1500)
      // const res = mockedActivityInfoResponse
      commit(DEVICE_MUTATION_TYPES.SET_DEVICE_INFO, res.activityInfo)
      dispatch(DEVICE_ACTION_TYPES.GET_DEVICE_USE_PARAM)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DEVICE_ACTION_TYPES.GET_ONE_DEVICE_USE_PARAM](
    { dispatch, commit },
    id,
  ) {
    commit(DEVICE_MUTATION_TYPES.SET_LOADING_STATE) // chargement en cours
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + DEVICE_API_ENDPOINT.DEVICE_USE_PARAM + "/" + id,
        method: 'GET',
        type: API_ACTION_TYPES.API_CALL,
      })
      commit(DEVICE_MUTATION_TYPES.SET_DEVICE_INFO, res.activityInfo)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DEVICE_ACTION_TYPES.PUT_ONE_DEVICE_USE_PARAM](
    { dispatch, commit },
    id,
  ) {
    commit(DEVICE_MUTATION_TYPES.SET_LOADING_STATE)
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + DEVICE_API_ENDPOINT.DEVICE_USE_PARAM + "/" + id,
        method: 'PUT',
        data: {
          mandatoryManagerConfirmation: true,
          mandatoryLocalVerif: true,
          mandatoryOsNumber: true,
          interventionDelay: 0,
          interventionAutoClosing: true,
          sendInfoToManager: true,
          sendInfoToMaintProvider: true,
          space: "",
          deviceUse: "",
          createdBy: "",
          createdAt: "",
          deletedBy: "",
          deletedAt: "",
          updatedBy: "",
          updatedAt: ""
        },
        type: API_ACTION_TYPES.API_CALL,
      })
      console.log("res", res)
      // await sleep(1500)
      // const res = mockedActivityInfoResponse
      commit(DEVICE_MUTATION_TYPES.SET_DEVICE_INFO, res.activityInfo)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DEVICE_ACTION_TYPES.DELETE_ONE_DEVICE_USE_PARAM](
    { dispatch, commit },
    id,
  ) {
    commit(DEVICE_MUTATION_TYPES.SET_LOADING_STATE)
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + DEVICE_API_ENDPOINT.DEVICE_USE_PARAM + "/" + id,
        method: 'DELETE',
        type: API_ACTION_TYPES.API_CALL,
      })
      console.log("res", res)
      // await sleep(1500)
      // const res = mockedActivityInfoResponse
      commit(DEVICE_MUTATION_TYPES.SET_DEVICE_INFO, res.activityInfo)
      dispatch(DEVICE_ACTION_TYPES.GET_DEVICE_USE_PARAM)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DEVICE_ACTION_TYPES.PATCH_ONE_DEVICE_USE_PARAM](
    { dispatch, commit },
    id,
  ) {
    commit(DEVICE_MUTATION_TYPES.SET_LOADING_STATE)
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + DEVICE_API_ENDPOINT.DEVICE_USE_PARAM + "/" + id,
        method: 'PATCH',
        data: {
          mandatoryManagerConfirmation: true,
          mandatoryLocalVerif: true,
          mandatoryOsNumber: true,
          interventionDelay: 0,
          interventionAutoClosing: true,
          sendInfoToManager: true,
          sendInfoToMaintProvider: true,
          space: "",
          deviceUse: "",
          createdBy: "",
          createdAt: "",
          deletedBy: "",
          deletedAt: "",
          updatedBy: "",
          updatedAt: ""
        },
        type: API_ACTION_TYPES.API_CALL,
      })
      console.log("res", res)
      // await sleep(1500)
      // const res = mockedActivityInfoResponse
      commit(DEVICE_MUTATION_TYPES.SET_DEVICE_INFO, res.activityInfo)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DEVICE_ACTION_TYPES.GET_LICENCED_DEVICE_USE_PARAM](
    { dispatch, commit },
    cancelToken
  ) {
    commit(DEVICE_MUTATION_TYPES.SET_LOADING_STATE)
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + DEVICE_API_ENDPOINT.LICENCED_DEVICE_USE_PARAM,
        requestId: cancelToken,
        method: 'GET',
        type: API_ACTION_TYPES.API_CALL,
      })
      console.log("res", res)
      commit(DEVICE_MUTATION_TYPES.SET_DEVICE_INFO, res.activityInfo)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DEVICE_ACTION_TYPES.POST_LICENCED_DEVICE_USE_PARAM](
    { dispatch, commit },
    cancelToken
  ) {
    commit(DEVICE_MUTATION_TYPES.SET_LOADING_STATE)
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + DEVICE_API_ENDPOINT.LICENCED_DEVICE_USE_PARAM,
        requestId: cancelToken,
        method: 'POST',
        data: {
          deviceUseId: "",
          userOrgId: ""
        },
        type: API_ACTION_TYPES.API_CALL,
      })
      console.log("res", res)
      commit(DEVICE_MUTATION_TYPES.SET_DEVICE_INFO, res.activityInfo)
      dispatch(DEVICE_ACTION_TYPES.GET_LICENCED_DEVICE_USE_PARAM)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DEVICE_ACTION_TYPES.GET_ONE_LICENCED_DEVICE_USE_PARAM](
    { dispatch, commit },
    id,
  ) {
    commit(DEVICE_MUTATION_TYPES.SET_LOADING_STATE) // chargement en cours
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + DEVICE_API_ENDPOINT.LICENCED_DEVICE_USE_PARAM + "/" + id,
        method: 'GET',
        type: API_ACTION_TYPES.API_CALL,
      })
      commit(DEVICE_MUTATION_TYPES.SET_DEVICE_INFO, res.activityInfo)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DEVICE_ACTION_TYPES.PUT_ONE_LICENCED_DEVICE_USE_PARAM](
    { dispatch, commit },
    id,
  ) {
    commit(DEVICE_MUTATION_TYPES.SET_LOADING_STATE)
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + DEVICE_API_ENDPOINT.LICENCED_DEVICE_USE_PARAM + "/" + id,
        method: 'PUT',
        data: {
          deviceUseId: "",
          userOrgId: ""
        },
        type: API_ACTION_TYPES.API_CALL,
      })
      console.log("res", res)
      commit(DEVICE_MUTATION_TYPES.SET_DEVICE_INFO, res.activityInfo)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DEVICE_ACTION_TYPES.DELETE_ONE_LICENCED_DEVICE_USE_PARAM](
    { dispatch, commit },
    id,
  ) {
    commit(DEVICE_MUTATION_TYPES.SET_LOADING_STATE)
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + DEVICE_API_ENDPOINT.LICENCED_DEVICE_USE_PARAM + "/" + id,
        method: 'DELETE',
        type: API_ACTION_TYPES.API_CALL,
      })
      console.log("res", res)
      commit(DEVICE_MUTATION_TYPES.SET_DEVICE_INFO, res.activityInfo)
      dispatch(DEVICE_ACTION_TYPES.GET_LICENCED_DEVICE_USE_PARAM)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DEVICE_ACTION_TYPES.PATCH_ONE_LICENCED_DEVICE_USE_PARAM](
    { dispatch, commit },
    id,
  ) {
    commit(DEVICE_MUTATION_TYPES.SET_LOADING_STATE)
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + DEVICE_API_ENDPOINT.LICENCED_DEVICE_USE_PARAM + "/" + id,
        method: 'PATCH',
        data: {
          deviceUseId: "",
          userOrgId: ""
        },
        type: API_ACTION_TYPES.API_CALL,
      })
      console.log("res", res)
      commit(DEVICE_MUTATION_TYPES.SET_DEVICE_INFO, res.activityInfo)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DEVICE_ACTION_TYPES.GET_DEVICE_USE](
    { dispatch, commit },
    cancelToken
  ) {
    commit(DEVICE_MUTATION_TYPES.SET_LOADING_STATE)
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + DEVICE_API_ENDPOINT.DEVICE_USE,
        requestId: cancelToken,
        method: 'GET',
        type: API_ACTION_TYPES.API_CALL,
      })
      console.log("res", res)
      // await sleep(1500)
      // const res = mockedActivityInfoResponse
      commit(DEVICE_MUTATION_TYPES.SET_DEVICE_INFO, res.activityInfo)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DEVICE_ACTION_TYPES.POST_DEVICE_USE](
    { dispatch, commit },
    cancelToken
  ) {
    commit(DEVICE_MUTATION_TYPES.SET_LOADING_STATE)
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + DEVICE_API_ENDPOINT.DEVICE_USE,
        requestId: cancelToken,
        method: 'POST',
        data: {
          id: "",
          name: ""
        },
        type: API_ACTION_TYPES.API_CALL,
      })
      console.log("res", res)
      // await sleep(1500)
      // const res = mockedActivityInfoResponse
      commit(DEVICE_MUTATION_TYPES.SET_DEVICE_INFO, res.activityInfo)
      dispatch(DEVICE_ACTION_TYPES.GET_DEVICE_USE)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DEVICE_ACTION_TYPES.GET_ONE_DEVICE_USE](
    { dispatch, commit },
    id,
  ) {
    commit(DEVICE_MUTATION_TYPES.SET_LOADING_STATE) // chargement en cours
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + DEVICE_API_ENDPOINT.DEVICE_USE + "/" + id,
        method: 'GET',
        type: API_ACTION_TYPES.API_CALL,
      })
      commit(DEVICE_MUTATION_TYPES.SET_DEVICE_INFO, res.activityInfo)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DEVICE_ACTION_TYPES.PUT_ONE_DEVICE_USE](
    { dispatch, commit },
    id,
  ) {
    commit(DEVICE_MUTATION_TYPES.SET_LOADING_STATE)
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + DEVICE_API_ENDPOINT.DEVICE_USE + "/" + id,
        method: 'PUT',
        data: {
          name: "",
        },
        type: API_ACTION_TYPES.API_CALL,
      })
      console.log("res", res)
      // await sleep(1500)
      // const res = mockedActivityInfoResponse
      commit(DEVICE_MUTATION_TYPES.SET_DEVICE_INFO, res.activityInfo)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DEVICE_ACTION_TYPES.DELETE_ONE_DEVICE_USE](
    { dispatch, commit },
    id,
  ) {
    commit(DEVICE_MUTATION_TYPES.SET_LOADING_STATE)
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + DEVICE_API_ENDPOINT.DEVICE_USE + "/" + id,
        method: 'DELETE',
        type: API_ACTION_TYPES.API_CALL,
      })
      console.log("res", res)
      // await sleep(1500)
      // const res = mockedActivityInfoResponse
      commit(DEVICE_MUTATION_TYPES.SET_DEVICE_INFO, res.activityInfo)
      dispatch(DEVICE_ACTION_TYPES.GET_DEVICE_USE)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DEVICE_ACTION_TYPES.PATCH_ONE_DEVICE_USE](
    { dispatch, commit },
    id,
  ) {
    commit(DEVICE_MUTATION_TYPES.SET_LOADING_STATE)
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + DEVICE_API_ENDPOINT.DEVICE_USE + "/" + id,
        method: 'PATCH',
        data: {
          name: "",
        },
        type: API_ACTION_TYPES.API_CALL,
      })
      console.log("res", res)
      // await sleep(1500)
      // const res = mockedActivityInfoResponse
      commit(DEVICE_MUTATION_TYPES.SET_DEVICE_INFO, res.activityInfo)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DEVICE_ACTION_TYPES.UPDATE_RESIDENCE](
    { dispatch },
    { id, status, parameters }
  ) {
    try {
      const res = await dispatch<ApiCallConfig>({
        url: `devices/${id}`,
        method: 'patch',
        data: { status, parameters },
        type: API_ACTION_TYPES.API_CALL,
      })
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DEVICE_ACTION_TYPES.DELETE_RESIDENCE]({ dispatch }, id) {
    try {
      const res = await dispatch<ApiCallConfig>({
        url: `devices/${id}`,
        method: 'delete',
        type: API_ACTION_TYPES.API_CALL,
      })
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DEVICE_ACTION_TYPES.ADD_NEW_RESIDENCE]({ dispatch }, residence) {
    try {
      const res = await dispatch<ApiCallConfig>({
        url: `devices/new-residence`,
        method: 'POST',
        data: residence,
        type: API_ACTION_TYPES.API_CALL,
      })
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DEVICE_ACTION_TYPES.GET_CLIENTS_INFO](
    { dispatch, commit },
  ) {
    commit(DEVICE_MUTATION_TYPES.SET_LOADING_STATE)
    try {
      await sleep(1500)
      const res = mockedClientInfoResponse
      //  await dispatch<ApiCallConfig>({
      //   url: 'clients',
      //   requestId: cancelToken,
      //   type: API_ACTION_TYPES.API_CALL,
      // })
      commit(DEVICE_MUTATION_TYPES.SET_CLIENTS_INFO, res.clientsInfo)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },
}
