import { MonitoringInfoItem, MonitoringDeviceItem } from './types'

export interface State {
  monitoringInfo: MonitoringInfoItem[]
  events: MonitoringDeviceItem[],
  isLoaded: boolean
}

export default (): State => ({
  monitoringInfo: [],
  events: [],
  isLoaded: false,
})
