import { defineRule } from 'vee-validate'
import {
  required,
  email,
  min,
  alpha,
  between,
  numeric,
} from '@vee-validate/rules'
import { configure } from 'vee-validate'
import { localize, setLocale } from '@vee-validate/i18n'

const DEFAULT_LOCALE = 'fr'

defineRule('required', required)
defineRule('email', email)
defineRule('min', min)
defineRule('alpha', alpha)
defineRule('between', between)
defineRule('numeric', numeric)
;(async function init() {
  configure({
    generateMessage: localize({
      [DEFAULT_LOCALE]: await import(
        `@vee-validate/i18n/dist/locale/${DEFAULT_LOCALE}.json`
      ),
    }),
  })
  setLocale(DEFAULT_LOCALE)
})()
