import { ActionTree } from 'vuex'
import { Mutations, COMPANY_MUTATION_TYPES } from './mutations'
import { State } from './state'
import { RootState } from '@/store'
import { baseURL } from '@/plugins/axios'
import { ApiCallConfig, API_ACTION_TYPES } from '../api/actions'
import { CompanyInfoResponse, ICompany, IClientsInfo, mockedActivityInfoResponse, mockedClientInfoResponse } from './types'
import { GenericActionContext } from '../types'

export enum COMPANY_ACTION_TYPES {
  GET_COMPANY_INFO = 'GET_COMPANY_INFO',
  POST_COMPANY_INFO = 'POST_COMPANY_INFO',
  GET_ONE_COMPANY_INFO = 'GET_ONE_COMPANY_INFO',
  PUT_ONE_COMPANY_INFO = 'PUT_ONE_COMPANY_INFO',
  DELETE_ONE_COMPANY_INFO = 'DELETE_ONE_COMPANY_INFO',
  PATCH_ONE_COMPANY_INFO = 'PATCH_ONE_COMPANY_INFO',
}

enum COMPANY_API_ENDPOINT {
  COMPANIES = '/companies',
}

type AugmentedActionContext = GenericActionContext<State, Mutations>

export type Actions = {
  [COMPANY_ACTION_TYPES.GET_COMPANY_INFO](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<CompanyInfoResponse>
  [COMPANY_ACTION_TYPES.POST_COMPANY_INFO](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<CompanyInfoResponse>
  [COMPANY_ACTION_TYPES.GET_ONE_COMPANY_INFO](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<CompanyInfoResponse>
  [COMPANY_ACTION_TYPES.PUT_ONE_COMPANY_INFO](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<CompanyInfoResponse>
  [COMPANY_ACTION_TYPES.DELETE_ONE_COMPANY_INFO](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<CompanyInfoResponse>
  [COMPANY_ACTION_TYPES.PATCH_ONE_COMPANY_INFO](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<CompanyInfoResponse>

}

export const actions: ActionTree<State, RootState> & Actions = {
  async [COMPANY_ACTION_TYPES.GET_COMPANY_INFO](
    { dispatch, commit },
    cancelToken
  ) {
    commit(COMPANY_MUTATION_TYPES.SET_LOADING_STATE)
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + COMPANY_API_ENDPOINT.COMPANIES,
        requestId: cancelToken,
        method: 'GET',
        type: API_ACTION_TYPES.API_CALL,
      })
      console.log(res["hydra:member"])
      commit(COMPANY_MUTATION_TYPES.SET_COMPANY_INFO,  res["hydra:member"])
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [COMPANY_ACTION_TYPES.POST_COMPANY_INFO](
    { dispatch, commit },
    cancelToken
  ) {
    commit(COMPANY_MUTATION_TYPES.SET_LOADING_STATE)
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + COMPANY_API_ENDPOINT.COMPANIES,
        requestId: cancelToken,
        method: 'POST',
        data: {
          name: "",
          companyType: "",
          userOrganization: "",
          createdBy: "",
          createdAt: "",
          updatedBy: "",
          updatedAt: "",
          deletedBy: "",
          deletedAt: "",
          isActive: true
        },
        type: API_ACTION_TYPES.API_CALL,
      })
      console.log("res", res)
      // await sleep(1500)
      // const res = mockedCompanyInfoResponse
      commit(COMPANY_MUTATION_TYPES.SET_COMPANY_INFO, res.activityInfo)
      dispatch(COMPANY_ACTION_TYPES.GET_COMPANY_INFO)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [COMPANY_ACTION_TYPES.GET_ONE_COMPANY_INFO](
    { dispatch, commit },
    id,
  ) {
    commit(COMPANY_MUTATION_TYPES.SET_LOADING_STATE) // chargement en cours
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + COMPANY_API_ENDPOINT.COMPANIES + "/" + id,
        method: 'GET',
        type: API_ACTION_TYPES.API_CALL,
      })
      commit(COMPANY_MUTATION_TYPES.SET_COMPANY_INFO, res.activityInfo)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [COMPANY_ACTION_TYPES.PUT_ONE_COMPANY_INFO](
    { dispatch, commit },
    id,
  ) {
    commit(COMPANY_MUTATION_TYPES.SET_LOADING_STATE)
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + COMPANY_API_ENDPOINT.COMPANIES + "/" + id,
        method: 'PUT',
        data: {
          name: "",
          companyType: "",
          userOrganization: "",
          createdBy: "",
          createdAt: "",
          updatedBy: "",
          updatedAt: "",
          deletedBy: "",
          deletedAt: "",
          isActive: true
        },
        type: API_ACTION_TYPES.API_CALL,
      })
      console.log("res", res)
      // await sleep(1500)
      // const res = mockedCompanyInfoResponse
      commit(COMPANY_MUTATION_TYPES.SET_COMPANY_INFO, res.activityInfo)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [COMPANY_ACTION_TYPES.DELETE_ONE_COMPANY_INFO](
    { dispatch, commit },
    id,
  ) {
    commit(COMPANY_MUTATION_TYPES.SET_LOADING_STATE)
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + COMPANY_API_ENDPOINT.COMPANIES + "/" + id,
        method: 'DELETE',
        data: {
        },
        type: API_ACTION_TYPES.API_CALL,
      })
      console.log("res", res)
      // await sleep(1500)
      // const res = mockedCompanyInfoResponse
      commit(COMPANY_MUTATION_TYPES.SET_COMPANY_INFO, res.activityInfo)
      dispatch(COMPANY_ACTION_TYPES.GET_COMPANY_INFO)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [COMPANY_ACTION_TYPES.PATCH_ONE_COMPANY_INFO](
    { dispatch, commit },
    id,
  ) {
    commit(COMPANY_MUTATION_TYPES.SET_LOADING_STATE)
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + COMPANY_API_ENDPOINT.COMPANIES + "/" + id,
        method: 'PATCH',
        data: {
          name: "",
          companyType: "",
          userOrganization: "",
          createdBy: "",
          createdAt: "",
          updatedBy: "",
          updatedAt: "",
          deletedBy: "",
          deletedAt: "",
          isActive: true
        },
        type: API_ACTION_TYPES.API_CALL,
      })
      console.log("res", res)
      // await sleep(1500)
      // const res = mockedCompanyInfoResponse
      commit(COMPANY_MUTATION_TYPES.SET_COMPANY_INFO, res.activityInfo)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },
}
