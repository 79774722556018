import { MutationTree } from 'vuex'
import { State } from './state'
import { UserInfo } from './types'

export enum AUTH_MUTATION_TYPES {
  SET_TOKEN = 'AUTH_SET_TOKEN',
  SET_USER_INFO = 'SET_USER_INFO',
  SET_SMS_CODE = 'SET_SMS_CODE',
  SET_ERROR_STATE = 'SET_ERROR_STATE',
  RESET_ERROR_STATE = 'RESET_ERROR_STATE'
}

export type Mutations = {
  [AUTH_MUTATION_TYPES.SET_TOKEN](state: State, tokens: any): void
  [AUTH_MUTATION_TYPES.SET_USER_INFO](state: State, userInfo: UserInfo): void
  [AUTH_MUTATION_TYPES.SET_SMS_CODE](state: State, smsCode: string): void
}

export const mutations: MutationTree<State> & Mutations = {
  [AUTH_MUTATION_TYPES.SET_TOKEN](state, tokens) {
    state.token = tokens.token
    state.refreshToken = tokens.refresh_token
  },
  [AUTH_MUTATION_TYPES.SET_USER_INFO](state, userInfo) {
    state.userInfo = userInfo
  },
  [AUTH_MUTATION_TYPES.SET_SMS_CODE](state, smsCode) {
    state.smsCode = smsCode
  },
  [AUTH_MUTATION_TYPES.SET_ERROR_STATE](state, error) {
    state.isError = true
    state.error = {status: error.status, message: error.message}
  },
  [AUTH_MUTATION_TYPES.RESET_ERROR_STATE](state) {
    state.isError = false
    state.error = {status: '', message: ''}
  },
}
