export interface IDevice {
  battery_level: number
  device_type: null | string
  id: number
  parameters: DeviceParameters
  reference: string
  residence: DeviceResidence
  space: DeviceSpace
  status: string
  synced_at: Date // DAte à remettre
  type: string
}

export interface DeviceParameters {
  is_contracted: boolean | null
  is_inspection_required: boolean | null
  is_service_order_number_required: boolean | null
  is_transferable_to_manager: boolean | null
  intervention_delay: number | null
}
export interface DeviceSpace {
  is_active: boolean
  parameters: DeviceSpaceParameters
  client: DeviceClient | null
}

export interface DeviceSpaceParameters {
  address_city?: string | null
  address_country?: string | null
  address_locality?: string | null
  daytime_door_code_first?: string | null
  daytime_door_code_second?: string | null
  full_address?: string | null
  is_lessor?: boolean | null
  latitude?: string | null
  longitude?: string | null
  name?: string | null
  night_door_code_first?: string | null
  night_door_code_second?: string | null
  postal_code?: string | null
  street_address?: string | null
}

export interface DeviceResidence {
  is_active: boolean
  parameters: DeviceResidenceParameters
  client: DeviceClient | null
}

export interface DeviceResidenceParameters {
  name: string
  site_code: string
  is_lessor: boolean | null
}
export interface DeviceClient {
  id: string
  name: string
}


export interface IClientsInfo {
  clientsInfo: IClient[]
}
export interface IClient {
  id: string
  name: string
  spaces: DeviceSpace[]
}

export interface IContact {
  id: string
  firstname: string
  lastname: string
  type: string
  email: string
  phoneNumber: string
  addDate: string
  userOrganization: string
  company: string
  companyName: string
  createdBy: string
  createdAt: string
  updatedBy: string
  updatedAt: string
  deletedBy: string
  deletedAt: string
  isActive: boolean
  active: boolean
  readOnly: boolean
  externalDocs: string
}

export interface ContactInfoResponse {
  contactsInfo: IContact[]
}

export const mockedContactInfoResponse = {
  contactsInfo: [
    {
      id: "string",
      firstname: "Michel",
      lastname: "Dupont",
      email: "Michel.Dupont@gmail.com",
      phone: "06.58.96.23.12",
      addDate: '24/08/22',
    },
    {
      id: "string",
      firstname: "Jacqueline",
      lastname: "Dubois",
      email: "Jacqueline.Dubois@gmail.com",
      phone: "06.38.33.02.54",
      addDate: '24/08/22',
    },
    {
      id: "string",
      firstname: "Paul",
      lastname: "Bloat",
      email: "Paul.Bloat@gmail.com",
      phone: "06.78.50.79.30",
      addDate: '24/08/22',
    },
    {
      id: "string",
      firstname: "Philippe",
      lastname: "Dias",
      email: "Philippe.Dias@gmail.com",
      phone: "06.02.63.45.97",
      addDate: '24/08/22',
    },
  ],
}
export const mockedClientInfoResponse = {
  clientsInfo: [
    {
      id: "123123124123",
      name: "name",
      spaces: [
        {
          is_active: true,
          parameters: {
            address_city: "address_city",
            address_country: "address_country",
            address_locality: "address_locality",
            daytime_door_code_first: "daytime_door_code_first",
            daytime_door_code_second: "daytime_door_code_second",
            full_address: "full_address",
            is_lessor: true,
            latitude: "latitude",
            longitude: "longitude",
            name: "name",
            night_door_code_first: "night_door_code_first",
            night_door_code_second: "night_door_code_second",
            postal_code: "postal_code",
            street_address: "street_address",
          },
          client: {
            id: "123124124124",
            name: "name",
          },
        },
      ]
    }
  ],
}