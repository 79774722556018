export type { IClearable } from './types'

export const DEFAULT_CLEARABLE_VALUE = false

export const STORY_SETTINGS = {
  name: 'clearable',
  type: { name: 'boolean', required: false },
  table: {
    type: { summary: 'boolean' },
    defaultValue: { summary: DEFAULT_CLEARABLE_VALUE },
  },
  control: 'boolean',
  description: 'Element`s input can be cleared by special button',
  defaultValue: DEFAULT_CLEARABLE_VALUE,
}

export default {
  type: Boolean,
  default: DEFAULT_CLEARABLE_VALUE,
}
