import { defineComponent, watch } from 'vue'
import QIcon from '../Icon/Icon.vue'
import QButton from '../Button/Button.vue'
import { default as props } from './props'

export default defineComponent({
  components: {
    QIcon,
    QButton,
  },
  props,
  emits: ['close', 'update:modelValue'],
  setup(props, { emit }) {
    let prevWindowScrollY: null | number = null

    const fix = () => {
      prevWindowScrollY = window.scrollY
      document.body.style.position = 'fixed'
      document.body.style.width = '100%'
      document.body.style.top = `-${prevWindowScrollY}px`
    }

    const unfix = () => {
      document.body.style.position = ''
      document.body.style.top = ''
      prevWindowScrollY && window.scrollTo(0, prevWindowScrollY)
    }

    const onClickOverlay = (e: Event) => {
      if (
        !props.persistent &&
        e.target instanceof HTMLDivElement &&
        e.target.attributes.getNamedItem('overlay-capture-click')
      ) {
        emit('close', e)
        emit('update:modelValue', false)
      }
    }

    watch(
      () => props.modelValue,
      (newVal) => {
        newVal ? fix() : unfix()
      }
    )

    return {
      onClickOverlay,
    }
  },
})
