export interface FilterCounterInfo {
  interventions: string
  awaitingInterventions: string
  time: string
}

export interface IDevice {
  battery_level: number
  device_type: null | string
  id: number
  parameters: string
  reference: string
  residence: DeviceResidence
  space: DeviceSpace
  status: string
  synced_at: Date // Remettre à DATE
  type: string
}

export interface DeviceSpace {
  id: number
  type: string
  is_active: boolean
  parameters: DeviceSpaceParameters
  client: DeviceClient | null
}

export interface DeviceSpaceParameters {
  address_city: string
  daytime_door_code_first: string
  daytime_door_code_second: string
  full_address: string
  is_lessor: boolean
  name: string
  night_door_code_first: string
  night_door_code_second: string
  postal_code: string
  staircase: string
  street_address: string
}

export interface DeviceResidence {
  id: number
  type: string
  is_active: boolean
  parameters: DeviceResidenceParameters
  client: DeviceClient | null
}

export interface DeviceResidenceParameters {
  name: string
  site_code: string
}
export interface DeviceClient {
  id: string
  name: string
}

export interface DashboardInfoItemAdditionalNote {
  created_at: Date
  created_by: DashboardNoteCreatedBy
  event: number
  id: number
  text: string
}

export interface DashboardNoteCreatedBy {
  email: string
  last_name: string
  name: string
  phone_number: string
}

export interface DashboardInfoItem {
  data: unknown
  id: number
  type: string
  device: IDevice
  status: string
  is_readed: boolean
  notes: DashboardInfoItemAdditionalNote[]
  service_order_number: string
  IsOpened?: boolean
}

export interface newNoteRequest {
  id: number
  text: string
}
export interface newOsNumberRequest {
  id: number
  type: string
  status: string
  isReaded: boolean
  serviceOrderNumber: string
}

export interface DashboardInfoResponse {
  dashboardInfo: DashboardInfoItem[]
}

export enum DashboardInfoStatuses {
  READY_TO_SEND = 'ready_to_send',
  SENT_TO_PROVIDER = 'sent_to_provider',
  SENT_TO_MANAGER = 'sent_to_manager',
  TRANSFERED = 'transfered',
  COMPLETED = 'completed',
  CANCELLED = 'canceled',
}

export const mockedDashboardInfoResponse = {
  dashboardInfo: [
    {
      data: "unknown",
      id: 1232412,
      type: 'Skydome',
      device: {
        battery_level: 123124124,
        device_type: null,
        id: 124123151243123,
        parameters: "parameters",
        reference: "24565678BGJ",
        residence: {
          id: 12312412,
          type: "type",
          is_active: true,
          parameters: {
            name: "Les Peupliers",
            site_code: "site_code",
          },
          client: {id: "123124124",
            name: "CDC habitat",
          }
        },
        space: {
          id: 1231251234,
          type: "type",
          is_active: true,
          parameters: {
            address_city: "address_city",
            daytime_door_code_first: "daytime_door_code_first",
            daytime_door_code_second: "daytime_door_code_second",
            full_address: "full_address",
            is_lessor: true,
            name: "Batiment 1",
            night_door_code_first: "night_door_code_first",
            night_door_code_second: "night_door_code_second",
            postal_code: "postal_code",
            staircase: "staircase",
            street_address: "street_address",
          },
          client: {
            id: "123124124",
            name: "CDC habitat",
          },
        },
        status: "status",
        synced_at: new Date(Date.now()- 183400000),
        type: "type",
      },
      status: "status",
      is_readed: true,
      notes: [{
        created_at: new Date(Date.now()- 183400000),
        created_by: {
          email: "email",
          last_name: "last_name",
          name: "name",
          phone_number: "phone_number",
        },
        event: 1231241231,
        id: 1231241231231432,
        text: "text",
      }],
      service_order_number: "14565678BGJ",
      IsOpened: false,
    },
    {
      data: "unknown",
      id: 1232412,
      type: 'Skydome',
      device: {
        battery_level: 123124124,
        device_type: null,
        id: 124123151243123,
        parameters: "parameters",
        reference: "24986578BFD",
        residence: {
          id: 12312412,
          type: "type",
          is_active: true,
          parameters: {
            name: "Les Peupliers",
            site_code: "site_code",
          },
          client: {id: "123124124",
            name: "CDC habitat",
          }
        },
        space: {
          id: 1231251234,
          type: "type",
          is_active: true,
          parameters: {
            address_city: "address_city",
            daytime_door_code_first: "daytime_door_code_first",
            daytime_door_code_second: "daytime_door_code_second",
            full_address: "full_address",
            is_lessor: true,
            name: "Batiment 1",
            night_door_code_first: "night_door_code_first",
            night_door_code_second: "night_door_code_second",
            postal_code: "postal_code",
            staircase: "staircase",
            street_address: "street_address",
          },
          client: {
            id: "123124124",
            name: "CDC habitat",
          },
        },
        status: "status",
        synced_at: new Date(Date.now()- 85200000),
        type: "type",
      },
      status: "status",
      is_readed: true,
      notes: [{
        created_at: new Date(Date.now()- 85200000),
        created_by: {
          email: "email",
          last_name: "last_name",
          name: "name",
          phone_number: "phone_number",
        },
        event: 1231241231,
        id: 1231241231231432,
        text: "text",
      }],
      service_order_number: "13457938JHJ",
      IsOpened: false,
    },
    {
      data: "unknown",
      id: 1232412,
      type: 'Dispositif rat',
      device: {
        battery_level: 123124124,
        device_type: null,
        id: 124123151243123,
        parameters: "parameters",
        reference: "24565753BHJ",
        residence: {
          id: 12312412,
          type: "type",
          is_active: true,
          parameters: {
            name: "Les Clauseaux",
            site_code: "site_code",
          },
          client: {id: "123124124",
            name: "CDC habitat",
          }
        },
        space: {
          id: 1231251234,
          type: "type",
          is_active: true,
          parameters: {
            address_city: "address_city",
            daytime_door_code_first: "daytime_door_code_first",
            daytime_door_code_second: "daytime_door_code_second",
            full_address: "full_address",
            is_lessor: true,
            name: "Batiment 1",
            night_door_code_first: "night_door_code_first",
            night_door_code_second: "night_door_code_second",
            postal_code: "postal_code",
            staircase: "staircase",
            street_address: "street_address",
          },
          client: {
            id: "123124124",
            name: "CDC habitat",
          },
        },
        status: "status",
        synced_at: new Date(Date.now()- 65200000),
        type: "type",
      },
      status: "status",
      is_readed: true,
      notes: [{
        created_at: new Date(Date.now()- 65200000),
        created_by: {
          email: "email",
          last_name: "last_name",
          name: "name",
          phone_number: "phone_number",
        },
        event: 1231241231,
        id: 1231241231231432,
        text: "text",
      }],
      service_order_number: "13455429RSC",
      IsOpened: false,
    },
    {
      data: "unknown",
      id: 1232412,
      type: 'Dispositif rat',
      device: {
        battery_level: 123124124,
        device_type: null,
        id: 124123151243123,
        parameters: "parameters",
        reference: "23565678TRE",
        residence: {
          id: 12312412,
          type: "type",
          is_active: true,
          parameters: {
            name: "Galena",
            site_code: "site_code",
          },
          client: {id: "123124124",
            name: "CDC habitat",
          }
        },
        space: {
          id: 1231251234,
          type: "type",
          is_active: true,
          parameters: {
            address_city: "address_city",
            daytime_door_code_first: "daytime_door_code_first",
            daytime_door_code_second: "daytime_door_code_second",
            full_address: "full_address",
            is_lessor: true,
            name: "Batiment 2",
            night_door_code_first: "night_door_code_first",
            night_door_code_second: "night_door_code_second",
            postal_code: "postal_code",
            staircase: "staircase",
            street_address: "street_address",
          },
          client: {
            id: "123124124",
            name: "CDC habitat",
          },
        },
        status: "status",
        synced_at: new Date(Date.now()),
        type: "type",
      },
      status: "status",
      is_readed: true,
      notes: [{
        created_at: new Date(Date.now()),
        created_by: {
          email: "email",
          last_name: "last_name",
          name: "name",
          phone_number: "phone_number",
        },
        event: 1231241231,
        id: 1231241231231432,
        text: "text",
      }],
      service_order_number: "13455678YHJ",
      IsOpened: false,
    }
  ],
}

export const mockedCounterInfoResponse = {
  counterInfo: [
    {
      interventions: "interventions",
      awaitingInterventions: "awaitingInterventions",
      time: "time",
    }
  ],
}