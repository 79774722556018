export type { IDisabled } from './types'

export const DEFAULT_DISABLED_VALUE = false

export const STORY_SETTINGS = {
  name: 'disabled',
  type: { name: 'boolean', required: false },
  table: {
    type: { summary: 'boolean' },
    defaultValue: { summary: DEFAULT_DISABLED_VALUE },
  },
  control: 'boolean',
  description: 'Indicates that the user cannot interact with the control',
  defaultValue: DEFAULT_DISABLED_VALUE,
}

export default {
  type: Boolean,
  default: DEFAULT_DISABLED_VALUE,
}
