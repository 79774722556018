export interface IDevice {
  battery_level: number
  device_type: null | string
  id: number
  parameters: DeviceParameters
  reference: string
  residence: DeviceResidence
  space: DeviceSpace
  status: string
  synced_at: Date // DAte à remettre
  type: string
  manufacturerRef: string
  networkId: string
  batteryLevel: number
  equipmentState: number
  networkType: string
  deviceModel: string
  deviceUse: string
  createdBy: string
  createdAt: Date
  updatedBy: string
  updatedAt: Date
  deletedBy: string
  deletedAt: Date
  isActive: boolean
}

export interface DeviceParameters {
  is_contracted: boolean | null
  is_inspection_required: boolean | null
  is_service_order_number_required: boolean | null
  is_transferable_to_manager: boolean | null
  intervention_delay: number | null
}
export interface DeviceSpace {
  is_active: boolean
  parameters: DeviceSpaceParameters
  client: DeviceClient | null
}

export interface DeviceSpaceParameters {
  address_city?: string | null
  address_country?: string | null
  address_locality?: string | null
  daytime_door_code_first?: string | null
  daytime_door_code_second?: string | null
  full_address?: string | null
  is_lessor?: boolean | null
  latitude?: string | null
  longitude?: string | null
  name?: string | null
  night_door_code_first?: string | null
  night_door_code_second?: string | null
  postal_code?: string | null
  street_address?: string | null
}

export interface DeviceResidence {
  is_active: boolean
  parameters: DeviceResidenceParameters
  client: DeviceClient | null
}

export interface DeviceResidenceParameters {
  name: string
  site_code: string
  is_lessor: boolean | null
}
export interface DeviceClient {
  id: string
  name: string
}

export interface ActivityInfoResponse {
  activityInfo: IDevice[]
}

export interface IClientsInfo {
  clientsInfo: IClient[]
}
export interface IClient {
  id: string
  name: string
  spaces: DeviceSpace[]
}


export const mockedActivityInfoResponse = {
  activityInfo: [
    {
      battery_level: 1231241241,
      device_type: "device_type",
      id: 123123124,
      parameters: {
        is_contracted: true,
        is_inspection_required: true,
        is_service_order_number_required: true,
        is_transferable_to_manager: true,
        intervention_delay: 720 ,
      },
      reference: "reference",
      residence: {
        is_active: true,
        parameters: {
          name: "name",
          site_code: "site_code",
          is_lessor: true,
        },
        client: {
          id: "123124124124",
          name: "name",
        },
      },
      space: {
        is_active: true,
        parameters: {
          address_city: "address_city",
          address_country: "address_country",
          address_locality: "address_locality",
          daytime_door_code_first: "daytime_door_code_first",
          daytime_door_code_second: "daytime_door_code_second",
          full_address: "full_address",
          is_lessor: true,
          latitude: "latitude",
          longitude: "longitude",
          name:"name",
          night_door_code_first: "night_door_code_first",
          night_door_code_second: "night_door_code_second",
          postal_code: "postal_code",
          street_address: "street_address",
        },
        client:{
          id: "123124124124",
          name: "name",
        },

      },
      status: "status",
      synced_at: new Date(Date.now()),
      type: "type",
    },
    {
      battery_level: 1231241241,
      device_type: "device_type",
      id: 123123124,
      parameters: {
        is_contracted: true,
        is_inspection_required: true,
        is_service_order_number_required: true,
        is_transferable_to_manager: true,
        intervention_delay: 720,
      },
      reference: "reference",
      residence: {
        is_active: true,
        parameters: {
          name: "name",
          site_code: "site_code",
          is_lessor: true,
        },
        client: {
          id: "123124124124",
          name: "name",
        },
      },
      space: {
        is_active: true,
        parameters: {
          address_city: "address_city",
          address_country: "address_country",
          address_locality: "address_locality",
          daytime_door_code_first: "daytime_door_code_first",
          daytime_door_code_second: "daytime_door_code_second",
          full_address: "full_address",
          is_lessor: true,
          latitude: "latitude",
          longitude: "longitude",
          name:"name",
          night_door_code_first: "night_door_code_first",
          night_door_code_second: "night_door_code_second",
          postal_code: "postal_code",
          street_address: "street_address",
        },
        client:{
          id: "123124124124",
          name: "name",
        },

      },
      status: "status",
      synced_at: new Date(Date.now()),
      type: "type",
    },
    {
      battery_level: 1231241241,
      device_type: "device_type",
      id: 123123124,
      parameters: {
        is_contracted: true,
        is_inspection_required: true,
        is_service_order_number_required: true,
        is_transferable_to_manager: true,
        intervention_delay: 720 ,
      },
      reference: "reference",
      residence: {
        is_active: true,
        parameters: {
          name: "name",
          site_code: "site_code",
          is_lessor: true,
        },
        client: {
          id: "123124124124",
          name: "name",
        },
      },
      space: {
        is_active: true,
        parameters: {
          address_city: "address_city",
          address_country: "address_country",
          address_locality: "address_locality",
          daytime_door_code_first: "daytime_door_code_first",
          daytime_door_code_second: "daytime_door_code_second",
          full_address: "full_address",
          is_lessor: true,
          latitude: "latitude",
          longitude: "longitude",
          name:"name",
          night_door_code_first: "night_door_code_first",
          night_door_code_second: "night_door_code_second",
          postal_code: "postal_code",
          street_address: "street_address",
        },
        client:{
          id: "123124124124",
          name: "name",
        },

      },
      status: "status",
      synced_at: new Date(Date.now()),
      type: "type",
    },
    {
      battery_level: 1231241241,
      device_type: "device_type",
      id: 123123124,
      parameters: {
        is_contracted: true,
        is_inspection_required: true,
        is_service_order_number_required: true,
        is_transferable_to_manager: true,
        intervention_delay: 720,
      },
      reference: "reference",
      residence: {
        is_active: true,
        parameters: {
          name: "name",
          site_code: "site_code",
          is_lessor: true,
        },
        client: {
          id: "123124124124",
          name: "name",
        },
      },
      space: {
        is_active: true,
        parameters: {
          address_city: "address_city",
          address_country: "address_country",
          address_locality: "address_locality",
          daytime_door_code_first: "daytime_door_code_first",
          daytime_door_code_second: "daytime_door_code_second",
          full_address: "full_address",
          is_lessor: true,
          latitude: "latitude",
          longitude: "longitude",
          name:"name",
          night_door_code_first: "night_door_code_first",
          night_door_code_second: "night_door_code_second",
          postal_code: "postal_code",
          street_address: "street_address",
        },
        client:{
          id: "123124124124",
          name: "name",
        },

      },
      status: "status",
      synced_at: new Date(Date.now()),
      type: "type",
    },
    {
      battery_level: 1231241241,
      device_type: "device_type",
      id: 123123124,
      parameters: {
        is_contracted: true,
        is_inspection_required: true,
        is_service_order_number_required: true,
        is_transferable_to_manager: true,
        intervention_delay: 720 ,
      },
      reference: "reference",
      residence: {
        is_active: true,
        parameters: {
          name: "name",
          site_code: "site_code",
          is_lessor: true,
        },
        client: {
          id: "123124124124",
          name: "name",
        },
      },
      space: {
        is_active: true,
        parameters: {
          address_city: "address_city",
          address_country: "address_country",
          address_locality: "address_locality",
          daytime_door_code_first: "daytime_door_code_first",
          daytime_door_code_second: "daytime_door_code_second",
          full_address: "full_address",
          is_lessor: true,
          latitude: "latitude",
          longitude: "longitude",
          name:"name",
          night_door_code_first: "night_door_code_first",
          night_door_code_second: "night_door_code_second",
          postal_code: "postal_code",
          street_address: "street_address",
        },
        client:{
          id: "123124124124",
          name: "name",
        },

      },
      status: "status",
      synced_at: new Date(Date.now()),
      type: "type",
    },
    {
      battery_level: 1231241241,
      device_type: "device_type",
      id: 123123124,
      parameters: {
        is_contracted: true,
        is_inspection_required: true,
        is_service_order_number_required: true,
        is_transferable_to_manager: true,
        intervention_delay: 720,
      },
      reference: "reference",
      residence: {
        is_active: true,
        parameters: {
          name: "name",
          site_code: "site_code",
          is_lessor: true,
        },
        client: {
          id: "123124124124",
          name: "name",
        },
      },
      space: {
        is_active: true,
        parameters: {
          address_city: "address_city",
          address_country: "address_country",
          address_locality: "address_locality",
          daytime_door_code_first: "daytime_door_code_first",
          daytime_door_code_second: "daytime_door_code_second",
          full_address: "full_address",
          is_lessor: true,
          latitude: "latitude",
          longitude: "longitude",
          name:"name",
          night_door_code_first: "night_door_code_first",
          night_door_code_second: "night_door_code_second",
          postal_code: "postal_code",
          street_address: "street_address",
        },
        client:{
          id: "123124124124",
          name: "name",
        },

      },
      status: "status",
      synced_at: new Date(Date.now()),
      type: "type",
    },
    {
      battery_level: 1231241241,
      device_type: "device_type",
      id: 123123124,
      parameters: {
        is_contracted: true,
        is_inspection_required: true,
        is_service_order_number_required: true,
        is_transferable_to_manager: true,
        intervention_delay: 720 ,
      },
      reference: "reference",
      residence: {
        is_active: true,
        parameters: {
          name: "name",
          site_code: "site_code",
          is_lessor: true,
        },
        client: {
          id: "123124124124",
          name: "name",
        },
      },
      space: {
        is_active: true,
        parameters: {
          address_city: "address_city",
          address_country: "address_country",
          address_locality: "address_locality",
          daytime_door_code_first: "daytime_door_code_first",
          daytime_door_code_second: "daytime_door_code_second",
          full_address: "full_address",
          is_lessor: true,
          latitude: "latitude",
          longitude: "longitude",
          name:"name",
          night_door_code_first: "night_door_code_first",
          night_door_code_second: "night_door_code_second",
          postal_code: "postal_code",
          street_address: "street_address",
        },
        client:{
          id: "123124124124",
          name: "name",
        },

      },
      status: "status",
      synced_at: new Date(Date.now()),
      type: "type",
    },
    {
      battery_level: 1231241241,
      device_type: "device_type",
      id: 123123124,
      parameters: {
        is_contracted: true,
        is_inspection_required: true,
        is_service_order_number_required: true,
        is_transferable_to_manager: true,
        intervention_delay: 720,
      },
      reference: "reference",
      residence: {
        is_active: true,
        parameters: {
          name: "name",
          site_code: "site_code",
          is_lessor: true,
        },
        client: {
          id: "123124124124",
          name: "name",
        },
      },
      space: {
        is_active: true,
        parameters: {
          address_city: "address_city",
          address_country: "address_country",
          address_locality: "address_locality",
          daytime_door_code_first: "daytime_door_code_first",
          daytime_door_code_second: "daytime_door_code_second",
          full_address: "full_address",
          is_lessor: true,
          latitude: "latitude",
          longitude: "longitude",
          name:"name",
          night_door_code_first: "night_door_code_first",
          night_door_code_second: "night_door_code_second",
          postal_code: "postal_code",
          street_address: "street_address",
        },
        client:{
          id: "123124124124",
          name: "name",
        },

      },
      status: "status",
      synced_at: new Date(Date.now()),
      type: "type",
    },
    {
      battery_level: 1231241241,
      device_type: "device_type",
      id: 123123124,
      parameters: {
        is_contracted: true,
        is_inspection_required: true,
        is_service_order_number_required: true,
        is_transferable_to_manager: true,
        intervention_delay: 720 ,
      },
      reference: "reference",
      residence: {
        is_active: true,
        parameters: {
          name: "name",
          site_code: "site_code",
          is_lessor: true,
        },
        client: {
          id: "123124124124",
          name: "name",
        },
      },
      space: {
        is_active: true,
        parameters: {
          address_city: "address_city",
          address_country: "address_country",
          address_locality: "address_locality",
          daytime_door_code_first: "daytime_door_code_first",
          daytime_door_code_second: "daytime_door_code_second",
          full_address: "full_address",
          is_lessor: true,
          latitude: "latitude",
          longitude: "longitude",
          name:"name",
          night_door_code_first: "night_door_code_first",
          night_door_code_second: "night_door_code_second",
          postal_code: "postal_code",
          street_address: "street_address",
        },
        client:{
          id: "123124124124",
          name: "name",
        },

      },
      status: "status",
      synced_at: new Date(Date.now()),
      type: "type",
    },
    {
      battery_level: 1231241241,
      device_type: "device_type",
      id: 123123124,
      parameters: {
        is_contracted: true,
        is_inspection_required: true,
        is_service_order_number_required: true,
        is_transferable_to_manager: true,
        intervention_delay: 720,
      },
      reference: "reference",
      residence: {
        is_active: true,
        parameters: {
          name: "name",
          site_code: "site_code",
          is_lessor: true,
        },
        client: {
          id: "123124124124",
          name: "name",
        },
      },
      space: {
        is_active: true,
        parameters: {
          address_city: "address_city",
          address_country: "address_country",
          address_locality: "address_locality",
          daytime_door_code_first: "daytime_door_code_first",
          daytime_door_code_second: "daytime_door_code_second",
          full_address: "full_address",
          is_lessor: true,
          latitude: "latitude",
          longitude: "longitude",
          name:"name",
          night_door_code_first: "night_door_code_first",
          night_door_code_second: "night_door_code_second",
          postal_code: "postal_code",
          street_address: "street_address",
        },
        client:{
          id: "123124124124",
          name: "name",
        },

      },
      status: "status",
      synced_at: new Date(Date.now()),
      type: "type",
    },
    {
      battery_level: 1231241241,
      device_type: "device_type",
      id: 123123124,
      parameters: {
        is_contracted: true,
        is_inspection_required: true,
        is_service_order_number_required: true,
        is_transferable_to_manager: true,
        intervention_delay: 720 ,
      },
      reference: "reference",
      residence: {
        is_active: true,
        parameters: {
          name: "name",
          site_code: "site_code",
          is_lessor: true,
        },
        client: {
          id: "123124124124",
          name: "name",
        },
      },
      space: {
        is_active: true,
        parameters: {
          address_city: "address_city",
          address_country: "address_country",
          address_locality: "address_locality",
          daytime_door_code_first: "daytime_door_code_first",
          daytime_door_code_second: "daytime_door_code_second",
          full_address: "full_address",
          is_lessor: true,
          latitude: "latitude",
          longitude: "longitude",
          name:"name",
          night_door_code_first: "night_door_code_first",
          night_door_code_second: "night_door_code_second",
          postal_code: "postal_code",
          street_address: "street_address",
        },
        client:{
          id: "123124124124",
          name: "name",
        },

      },
      status: "status",
      synced_at: new Date(Date.now()),
      type: "type",
    },
    {
      battery_level: 1231241241,
      device_type: "device_type",
      id: 123123124,
      parameters: {
        is_contracted: true,
        is_inspection_required: true,
        is_service_order_number_required: true,
        is_transferable_to_manager: true,
        intervention_delay: 720,
      },
      reference: "reference",
      residence: {
        is_active: true,
        parameters: {
          name: "name",
          site_code: "site_code",
          is_lessor: true,
        },
        client: {
          id: "123124124124",
          name: "name",
        },
      },
      space: {
        is_active: true,
        parameters: {
          address_city: "address_city",
          address_country: "address_country",
          address_locality: "address_locality",
          daytime_door_code_first: "daytime_door_code_first",
          daytime_door_code_second: "daytime_door_code_second",
          full_address: "full_address",
          is_lessor: true,
          latitude: "latitude",
          longitude: "longitude",
          name:"name",
          night_door_code_first: "night_door_code_first",
          night_door_code_second: "night_door_code_second",
          postal_code: "postal_code",
          street_address: "street_address",
        },
        client:{
          id: "123124124124",
          name: "name",
        },

      },
      status: "status",
      synced_at: new Date(Date.now()),
      type: "type",
    },
    {
      battery_level: 1231241241,
      device_type: "device_type",
      id: 123123124,
      parameters: {
        is_contracted: true,
        is_inspection_required: true,
        is_service_order_number_required: true,
        is_transferable_to_manager: true,
        intervention_delay: 720 ,
      },
      reference: "reference",
      residence: {
        is_active: true,
        parameters: {
          name: "name",
          site_code: "site_code",
          is_lessor: true,
        },
        client: {
          id: "123124124124",
          name: "name",
        },
      },
      space: {
        is_active: true,
        parameters: {
          address_city: "address_city",
          address_country: "address_country",
          address_locality: "address_locality",
          daytime_door_code_first: "daytime_door_code_first",
          daytime_door_code_second: "daytime_door_code_second",
          full_address: "full_address",
          is_lessor: true,
          latitude: "latitude",
          longitude: "longitude",
          name:"name",
          night_door_code_first: "night_door_code_first",
          night_door_code_second: "night_door_code_second",
          postal_code: "postal_code",
          street_address: "street_address",
        },
        client:{
          id: "123124124124",
          name: "name",
        },

      },
      status: "status",
      synced_at: new Date(Date.now()),
      type: "type",
    },
    {
      battery_level: 1231241241,
      device_type: "device_type",
      id: 123123124,
      parameters: {
        is_contracted: true,
        is_inspection_required: true,
        is_service_order_number_required: true,
        is_transferable_to_manager: true,
        intervention_delay: 720,
      },
      reference: "reference",
      residence: {
        is_active: true,
        parameters: {
          name: "name",
          site_code: "site_code",
          is_lessor: true,
        },
        client: {
          id: "123124124124",
          name: "name",
        },
      },
      space: {
        is_active: true,
        parameters: {
          address_city: "address_city",
          address_country: "address_country",
          address_locality: "address_locality",
          daytime_door_code_first: "daytime_door_code_first",
          daytime_door_code_second: "daytime_door_code_second",
          full_address: "full_address",
          is_lessor: true,
          latitude: "latitude",
          longitude: "longitude",
          name:"name",
          night_door_code_first: "night_door_code_first",
          night_door_code_second: "night_door_code_second",
          postal_code: "postal_code",
          street_address: "street_address",
        },
        client:{
          id: "123124124124",
          name: "name",
        },

      },
      status: "status",
      synced_at: new Date(Date.now()),
      type: "type",
    }
  ],
}
export const mockedClientInfoResponse = {
  clientsInfo: [
    {
      id: "123123124123",
      name: "name",
      spaces: [
        {
          is_active: true,
          parameters: {
            address_city: "address_city",
            address_country: "address_country",
            address_locality: "address_locality",
            daytime_door_code_first: "daytime_door_code_first",
            daytime_door_code_second: "daytime_door_code_second",
            full_address: "full_address",
            is_lessor: true,
            latitude: "latitude",
            longitude: "longitude",
            name:"name",
            night_door_code_first: "night_door_code_first",
            night_door_code_second: "night_door_code_second",
            postal_code: "postal_code",
            street_address: "street_address",
          },
          client:{
            id: "123124124124",
            name: "name",
          },  
        },
      ]
    }
  ],
}