import { ActionTree } from 'vuex'
import { Mutations, SPACE_MUTATION_TYPES } from './mutations'
import { State } from './state'
import { RootState } from '@/store'
import { baseURL } from '@/plugins/axios'
import { ApiCallConfig, API_ACTION_TYPES } from '../api/actions'
import { 
  SpacesResponse,
  SpacesItem,
   } from './types'
import { GenericActionContext } from '../types'

export enum SPACE_ACTION_TYPES {
  GET_SPACES = 'GET_SPACES',
  GET_SPACES_FILTER = 'GET_SPACES_FILTER',
  POST_SPACES = "POST_SPACES",
  GET_ONE_SPACES = 'GET_ONE_SPACES',
  PUT_ONE_SPACES = 'PUT_ONE_SPACES',
  DELETE_ONE_SPACES = 'DELETE_ONE_SPACES',
  PATCH_ONE_SPACES = 'PATCH_ONE_SPACES',
  GET_SPACE_DEVICES = 'GET_SPACE_DEVICES',
  POST_SPACE_DEVICES = "POST_SPACE_DEVICES",
  GET_ONE_SPACE_DEVICES = 'GET_ONE_SPACE_DEVICES',
  PUT_ONE_SPACE_DEVICES = 'PUT_ONE_SPACE_DEVICES',
  DELETE_ONE_SPACE_DEVICES = 'DELETE_ONE_SPACE_DEVICES',
  PATCH_ONE_SPACE_DEVICES = 'PATCH_ONE_SPACE_DEVICES',
  GET_SPACE_TECHNICIANS = 'GET_SPACE_TECHNICIANS',
  POST_SPACE_TECHNICIANS = "POST_SPACE_TECHNICIANS",
  GET_ONE_SPACE_TECHNICIANS = 'GET_ONE_SPACE_TECHNICIANS',
  PUT_ONE_SPACE_TECHNICIANS = 'PUT_ONE_SPACE_TECHNICIANS',
  DELETE_ONE_SPACE_TECHNICIANS = 'DELETE_ONE_SPACE_TECHNICIANS',
  PATCH_ONE_SPACE_TECHNICIANS = 'PATCH_ONE_SPACE_TECHNICIANS',
}

enum SPACE_API_ENDPOINT {
  SPACES = '/spaces',
  SPACE_DEVICES = '/space_devices',
  SPACE_TECHNICIANS = '/space_technicians',
}

type AugmentedActionContext = GenericActionContext<State, Mutations>

export type Actions = {
  [SPACE_ACTION_TYPES.GET_SPACES](
    { commit }: AugmentedActionContext,
    payload: any
  ): Promise<SpacesResponse>
  [SPACE_ACTION_TYPES.GET_SPACES_FILTER](
    { commit }: AugmentedActionContext,
    payload: any
  ): Promise<SpacesResponse>
  [SPACE_ACTION_TYPES.POST_SPACES](
    { commit }: AugmentedActionContext,
    payload: any
  ): Promise<SpacesResponse>
  [SPACE_ACTION_TYPES.GET_ONE_SPACES](
    { commit }: AugmentedActionContext,
    payload: any
  ): Promise<SpacesResponse>
  [SPACE_ACTION_TYPES.PUT_ONE_SPACES](
    { commit }: AugmentedActionContext,
    payload: any
  ): Promise<SpacesResponse>
  [SPACE_ACTION_TYPES.DELETE_ONE_SPACES](
    { commit }: AugmentedActionContext,
    payload: any
  ): Promise<SpacesResponse>
  [SPACE_ACTION_TYPES.PATCH_ONE_SPACES](
    { commit }: AugmentedActionContext,
    payload: any
  ): Promise<SpacesResponse>

  [SPACE_ACTION_TYPES.GET_SPACE_DEVICES](
    { commit }: AugmentedActionContext,
    payload: any
  ): Promise<SpacesResponse>
  [SPACE_ACTION_TYPES.POST_SPACE_DEVICES](
    { commit }: AugmentedActionContext,
    payload: any
  ): Promise<SpacesResponse>
  [SPACE_ACTION_TYPES.GET_ONE_SPACE_DEVICES](
    { commit }: AugmentedActionContext,
    payload: any
  ): Promise<SpacesResponse>
  [SPACE_ACTION_TYPES.PUT_ONE_SPACE_DEVICES](
    { commit }: AugmentedActionContext,
    payload: any
  ): Promise<SpacesResponse>
  [SPACE_ACTION_TYPES.DELETE_ONE_SPACE_DEVICES](
    { commit }: AugmentedActionContext,
    payload: any
  ): Promise<SpacesResponse>
  [SPACE_ACTION_TYPES.PATCH_ONE_SPACE_DEVICES](
    { commit }: AugmentedActionContext,
    payload: any
  ): Promise<SpacesResponse>

  [SPACE_ACTION_TYPES.GET_SPACE_TECHNICIANS](
    { commit }: AugmentedActionContext,
    payload: any
  ): Promise<SpacesResponse>
  [SPACE_ACTION_TYPES.POST_SPACE_TECHNICIANS](
    { commit }: AugmentedActionContext,
    payload: any
  ): Promise<SpacesResponse>
  [SPACE_ACTION_TYPES.GET_ONE_SPACE_TECHNICIANS](
    { commit }: AugmentedActionContext,
    payload: any
  ): Promise<SpacesResponse>
  [SPACE_ACTION_TYPES.PUT_ONE_SPACE_TECHNICIANS](
    { commit }: AugmentedActionContext,
    payload: any
  ): Promise<SpacesResponse>
  [SPACE_ACTION_TYPES.DELETE_ONE_SPACE_TECHNICIANS](
    { commit }: AugmentedActionContext,
    payload: any
  ): Promise<SpacesResponse>
  [SPACE_ACTION_TYPES.PATCH_ONE_SPACE_TECHNICIANS](
    { commit }: AugmentedActionContext,
    payload: any
  ): Promise<SpacesResponse>
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [SPACE_ACTION_TYPES.GET_SPACES](
    { dispatch, commit },
    cancelToken
  ) {
    commit(SPACE_MUTATION_TYPES.SET_LOADING_STATE) // chargement en cours
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL+SPACE_API_ENDPOINT.SPACES,
        requestId: cancelToken,
        method:'GET',
        type: API_ACTION_TYPES.API_CALL,
      })
      commit(SPACE_MUTATION_TYPES.SET_SPACES, res["hydra:member"])
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async [SPACE_ACTION_TYPES.GET_SPACES_FILTER](
    { dispatch, commit },
    payload,
  ) {
    const { filter } = payload
    commit(SPACE_MUTATION_TYPES.SET_LOADING_STATE) // chargement en cours
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL+SPACE_API_ENDPOINT.SPACES+filter,
        method:'GET',
        type: API_ACTION_TYPES.API_CALL,
      })
      commit(SPACE_MUTATION_TYPES.SET_SPACES_FILTER, res["hydra:member"])
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async [SPACE_ACTION_TYPES.POST_SPACES](
    { dispatch, commit }, 
    payload,
  ) {
    const { address, name, zipCode, entryCode1, entryCode2,  levelMin, levelMax, stairsMin, stairsMax,isAddressDifferent, city, residence, type,company,userOrganization } = payload
    let data
    if(residence)
    {
      data= {
        parent: '/spaces/'+residence,
        address,
        name,
        zipCode: Number(zipCode),
        entryCode1,
        entryCode2,
        levelMin: Number(levelMin),
        levelMax: Number(levelMax),
        stairsMin,
        stairsMax,
        city,
        type,
        company: "/companies/"+company,
        userOrganization,
      };
    }
    else{
      data= {
        address,
        name,
        zipCode: Number(zipCode),
        entryCode1,
        entryCode2,
        city,
        type,
        company: "/companies/"+company,
      }
    }
    commit(SPACE_MUTATION_TYPES.SET_LOADING_STATE) // chargement en cours
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL+SPACE_API_ENDPOINT.SPACES,
        method: 'POST',
        data,
        type: API_ACTION_TYPES.API_CALL,
      })      
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async [SPACE_ACTION_TYPES.GET_ONE_SPACES](
    { dispatch, commit }, 
    id,
  ) {
    commit(SPACE_MUTATION_TYPES.SET_LOADING_STATE) // chargement en cours
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL+SPACE_API_ENDPOINT.SPACES+"/"+id,
        method: 'GET',
        type: API_ACTION_TYPES.API_CALL,
      })      
      commit(SPACE_MUTATION_TYPES.SET_SPACES, res.SPACEsInfo)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async [SPACE_ACTION_TYPES.PUT_ONE_SPACES](
    { dispatch, commit }, 
    payload,
  ) {
    const { email, firstname, lastname, phoneNumber, id } = payload
    commit(SPACE_MUTATION_TYPES.SET_LOADING_STATE) // chargement en cours
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL+SPACE_API_ENDPOINT.SPACES+"/"+id,
        method: 'PUT',
        data: {
          email,
          firstname,
          lastname,
          phoneNumber,
          userOrgId: "",
          company: "",
          createdBy: "",
          createdAt: "",
          updatedBy: "",
          updatedAt: "",
          deletedBy: "",
          deletedAt: "",
          isActive: true
        },
        type: API_ACTION_TYPES.API_CALL,
      })      
      commit(SPACE_MUTATION_TYPES.SET_SPACES, res.SPACEsInfo)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async [SPACE_ACTION_TYPES.DELETE_ONE_SPACES](
    { dispatch, commit }, 
    payload,
  ) {
    const { id } = payload
    commit(SPACE_MUTATION_TYPES.SET_LOADING_STATE) // chargement en cours
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL+SPACE_API_ENDPOINT.SPACES+"/"+id,
        method: 'DELETE',
        type: API_ACTION_TYPES.API_CALL,
      })
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async [SPACE_ACTION_TYPES.PATCH_ONE_SPACES](
    { dispatch, commit }, 
    payload,
  ) {
    const { address, name, zipCode, entryCode1, entryCode2,  levelMin, levelMax, stairsMin, stairsMax,isAddressDifferent, city, residence, id, } = payload
    let data
    if(residence)
    {
      data= {
        parent: '/spaces/'+residence,
        address,
        name,
        zipCode: Number(zipCode),
        entryCode1,
        entryCode2,
        levelMin: Number(levelMin),
        levelMax: Number(levelMax),
        stairsMin,
        stairsMax,
        city,
      };
    }
    else{
      data= {
        address,
        name,
        zipCode: Number(zipCode),
        entryCode1,
        entryCode2,
        city,
      }
    }
    commit(SPACE_MUTATION_TYPES.SET_LOADING_STATE) // chargement en cours
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL+SPACE_API_ENDPOINT.SPACES+"/"+id,
        method: 'PATCH',
        data,
        type: API_ACTION_TYPES.API_CALL,
      })
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [SPACE_ACTION_TYPES.GET_SPACE_DEVICES](
    { dispatch, commit },
    cancelToken
  ) {
    commit(SPACE_MUTATION_TYPES.SET_LOADING_STATE) // chargement en cours
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL+SPACE_API_ENDPOINT.SPACE_DEVICES,
        requestId: cancelToken,
        method:'GET',
        type: API_ACTION_TYPES.API_CALL,
      })      
      commit(SPACE_MUTATION_TYPES.SET_SPACES, res["hydra:member"])
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async [SPACE_ACTION_TYPES.POST_SPACE_DEVICES](
    { dispatch, commit }, 
    payload,
  ) {
    const { email, firstname, lastname, phoneNumber, id } = payload
    commit(SPACE_MUTATION_TYPES.SET_LOADING_STATE) // chargement en cours
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL+SPACE_API_ENDPOINT.SPACE_DEVICES+"/"+id,
        method: 'POST',
        data: {
          isOwner: true,
          isSharingRequestAccepted: true,
          company: "string",
          sharingRequest: "string",
          device: "string",
          space: "string",
          userOrganization: "string"
        },
        type: API_ACTION_TYPES.API_CALL,
      })      
      commit(SPACE_MUTATION_TYPES.SET_SPACES, res.SPACE_DEVICESInfo)
      dispatch(SPACE_ACTION_TYPES.GET_SPACE_DEVICES)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async [SPACE_ACTION_TYPES.GET_ONE_SPACE_DEVICES](
    { dispatch, commit }, 
    id,
  ) {
    commit(SPACE_MUTATION_TYPES.SET_LOADING_STATE) // chargement en cours
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL+SPACE_API_ENDPOINT.SPACE_DEVICES+"/"+id,
        method: 'GET',
        type: API_ACTION_TYPES.API_CALL,
      })      
      commit(SPACE_MUTATION_TYPES.SET_SPACES, res.SPACE_DEVICESInfo)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async [SPACE_ACTION_TYPES.PUT_ONE_SPACE_DEVICES](
    { dispatch, commit }, 
    payload,
  ) {
    const { email, firstname, lastname, phoneNumber, id } = payload
    commit(SPACE_MUTATION_TYPES.SET_LOADING_STATE) // chargement en cours
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL+SPACE_API_ENDPOINT.SPACE_DEVICES+"/"+id,
        method: 'PUT',
        data: {
          isOwner: true,
          isSharingRequestAccepted: true,
          company: "string",
          sharingRequest: "string",
          device: "string",
          space: "string",
          userOrganization: "string"
        },
        type: API_ACTION_TYPES.API_CALL,
      })      
      commit(SPACE_MUTATION_TYPES.SET_SPACES, res.SPACE_DEVICESInfo)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async [SPACE_ACTION_TYPES.DELETE_ONE_SPACE_DEVICES](
    { dispatch, commit }, 
    payload,
  ) {
    const { id } = payload
    commit(SPACE_MUTATION_TYPES.SET_LOADING_STATE) // chargement en cours
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL+SPACE_API_ENDPOINT.SPACE_DEVICES+"/"+id,
        method: 'DELETE',
        type: API_ACTION_TYPES.API_CALL,
      })
      commit(SPACE_MUTATION_TYPES.SET_SPACES, res.SPACE_DEVICESInfo)
      dispatch(SPACE_ACTION_TYPES.GET_SPACE_DEVICES)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async [SPACE_ACTION_TYPES.PATCH_ONE_SPACE_DEVICES](
    { dispatch, commit }, 
    payload,
  ) {
    const { email, firstname, lastname, phoneNumber, id } = payload
    commit(SPACE_MUTATION_TYPES.SET_LOADING_STATE) // chargement en cours
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL+SPACE_API_ENDPOINT.SPACE_DEVICES+"/"+id,
        method: 'PATCH',
        data: {
          isOwner: true,
          isSharingRequestAccepted: true,
          company: "string",
          sharingRequest: "string",
          device: "string",
          space: "string",
          userOrganization: "string"
        },
        type: API_ACTION_TYPES.API_CALL,
      })
      commit(SPACE_MUTATION_TYPES.SET_SPACES, res.SPACE_DEVICESInfo)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [SPACE_ACTION_TYPES.GET_SPACE_TECHNICIANS](
    { dispatch, commit },
    cancelToken
  ) {
    commit(SPACE_MUTATION_TYPES.SET_LOADING_STATE) // chargement en cours
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL+SPACE_API_ENDPOINT.SPACE_TECHNICIANS,
        requestId: cancelToken,
        method:'GET',
        type: API_ACTION_TYPES.API_CALL,
      })      
      commit(SPACE_MUTATION_TYPES.SET_SPACES, res["hydra:member"])
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async [SPACE_ACTION_TYPES.POST_SPACE_TECHNICIANS](
    { dispatch, commit }, 
    payload,
  ) {
    const { email, firstname, lastname, phoneNumber, id } = payload
    commit(SPACE_MUTATION_TYPES.SET_LOADING_STATE) // chargement en cours
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL+SPACE_API_ENDPOINT.SPACE_TECHNICIANS+"/"+id,
        method: 'POST',
        data: {
          space: "string",
          technician: "string",
          licencedDeviceUseParam: "string",
        },
        type: API_ACTION_TYPES.API_CALL,
      })      
      commit(SPACE_MUTATION_TYPES.SET_SPACES, res.SPACE_TECHNICIANSInfo)
      dispatch(SPACE_ACTION_TYPES.GET_SPACE_TECHNICIANS)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async [SPACE_ACTION_TYPES.GET_ONE_SPACE_TECHNICIANS](
    { dispatch, commit }, 
    id,
  ) {
    commit(SPACE_MUTATION_TYPES.SET_LOADING_STATE) // chargement en cours
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL+SPACE_API_ENDPOINT.SPACE_TECHNICIANS+"/"+id,
        method: 'GET',
        type: API_ACTION_TYPES.API_CALL,
      })      
      commit(SPACE_MUTATION_TYPES.SET_SPACES, res.SPACE_TECHNICIANSInfo)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async [SPACE_ACTION_TYPES.PUT_ONE_SPACE_TECHNICIANS](
    { dispatch, commit }, 
    payload,
  ) {
    const { email, firstname, lastname, phoneNumber, id } = payload
    commit(SPACE_MUTATION_TYPES.SET_LOADING_STATE) // chargement en cours
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL+SPACE_API_ENDPOINT.SPACE_TECHNICIANS+"/"+id,
        method: 'PUT',
        data: {
          space: "string",
          technician: "string",
          licencedDeviceUseParam: "string",
        },
        type: API_ACTION_TYPES.API_CALL,
      })      
      commit(SPACE_MUTATION_TYPES.SET_SPACES, res.SPACE_TECHNICIANSInfo)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async [SPACE_ACTION_TYPES.DELETE_ONE_SPACE_TECHNICIANS](
    { dispatch, commit }, 
    payload,
  ) {
    const { id } = payload
    commit(SPACE_MUTATION_TYPES.SET_LOADING_STATE) // chargement en cours
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL+SPACE_API_ENDPOINT.SPACE_TECHNICIANS+"/"+id,
        method: 'DELETE',
        type: API_ACTION_TYPES.API_CALL,
      })
      commit(SPACE_MUTATION_TYPES.SET_SPACES, res.SPACE_TECHNICIANSInfo)
      dispatch(SPACE_ACTION_TYPES.GET_SPACE_TECHNICIANS)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async [SPACE_ACTION_TYPES.PATCH_ONE_SPACE_TECHNICIANS](
    { dispatch, commit }, 
    payload,
  ) {
    const { email, firstname, lastname, phoneNumber, id } = payload
    commit(SPACE_MUTATION_TYPES.SET_LOADING_STATE) // chargement en cours
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL+SPACE_API_ENDPOINT.SPACE_TECHNICIANS+"/"+id,
        method: 'PATCH',
        data: {
          space: "string",
          technician: "string",
          licencedDeviceUseParam: "string",
        },
        type: API_ACTION_TYPES.API_CALL,
      })
      commit(SPACE_MUTATION_TYPES.SET_SPACES, res.SPACEsInfo)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },
}
