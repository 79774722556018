import name from '@/components/shared/props/name'
import disabled from '@/components/shared/props/disabled'
import id from '@/components/shared/props/id'
import autocomplete from '@/components/shared/props/autocomplete'
import autofocus from '@/components/shared/props/autofocus'
import placeholder from '@/components/shared/props/placeholder'
import readonly from '@/components/shared/props/readonly'
import outline from '@/components/shared/props/outline'

import { IName } from '@/components/shared/props/name'
import { IDisabled } from '@/components/shared/props/disabled'
import { IIdentificator } from '@/components/shared/props/id'
import { IAutocomplete } from '@/components/shared/props/autocomplete'
import { IAutofocus } from '@/components/shared/props/autofocus'
import { IPlaceholder } from '@/components/shared/props/placeholder'
import { IReadonly } from '@/components/shared/props/readonly'
import { IOutline } from '@/components/shared/props/outline'

export interface IProps
  extends IName,
    IDisabled,
    IIdentificator,
    IAutocomplete,
    IAutofocus,
    IPlaceholder,
    IReadonly,
    IOutline {
  rows: number
  cols: number
  resize: boolean
  modelValue: string
  maxLength: number
}

export const DEFAULT_RESIZE_VALUE = false
export const DEFAULT_STRING_MODEL_VALUE = ''
export const DEFAULT_MAX_LENGTH_VALUE = 0
export const DEFAULT_LABEL_VALUE = ''

export const resize = {
  type: Boolean,
  default: DEFAULT_RESIZE_VALUE,
}

export const modelValue = {
  type: String,
  default: DEFAULT_STRING_MODEL_VALUE,
  required: true,
}

export const maxLength = {
  type: Number,
  default: DEFAULT_MAX_LENGTH_VALUE,
}

export const label = {
  type: String,
  required: false,
  default: DEFAULT_LABEL_VALUE,
}

export default {
  modelValue,
  name,
  disabled,
  id,
  autocomplete,
  autofocus,
  placeholder,
  readonly,
  resize,
  outline,
  maxLength,
  label,
}
