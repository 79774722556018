export const DEFAULT_LOCALE = 'fr'
export const MINIMUM_PASSWORD_LENGTH = 10
export const KEY_CODE = {
  backspace: 'Backspace',
  left: 'ArrowLeft',
  up: 'ArrowUp',
  right: 'ArrowRight',
  down: 'ArrowDown',
}
export const TOKEN_KEY = 'userToken'
export const REFRESH_TOKEN_KEY = 'userRefreshToken'
export const MONITORING_TABLE_BATTERY_LEVEL_HIGH = 20
export const MONITORING_TABLE_BATTERY_LEVEL_LOW = 5
export const MIN_PHONE_LENGTH = 10 // 10 digits + 3 spaces (eg: '06 999 999 99')
export const INTERVENTION_DELAY_HOURS: Array<number | string> = [
  0, 1, 2, 3, 4, 6, 8, 10, 12, 16, 24,
]
