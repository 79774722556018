import id from '@/components/shared/props/id'
import { MutationTree } from 'vuex'
import { State } from './state'
import { IContact, IClientsInfo, ContactInfoResponse } from './types'

export enum CONTACT_MUTATION_TYPES {
  SET_CONTACT_INFO = 'SET_CONTACT_INFO',
  SET_ONE_CONTACT_INFO = 'SET_ONE_CONTACT_INFO',
  SET_CLIENTS_INFO = 'SET_CLIENTS_INFO',
  SET_LOADING_STATE = 'SET_LOADING_STATE',
  RESET_ERROR_STATE = 'RESET_ERROR_STATE'
}

export type Mutations = {
  [CONTACT_MUTATION_TYPES.SET_CONTACT_INFO](
    state: State,
    contactsInfo: ContactInfoResponse[]
  ): void
  [CONTACT_MUTATION_TYPES.SET_ONE_CONTACT_INFO](
    state: State,
    contactsInfo: IContact[]
  ): void
  [CONTACT_MUTATION_TYPES.SET_LOADING_STATE](state: State): void
  [CONTACT_MUTATION_TYPES.SET_CLIENTS_INFO](
    state: State,
    clientsInfo: IClientsInfo[]
  ): void
}

export const mutations: MutationTree<State> & Mutations = {
  [CONTACT_MUTATION_TYPES.SET_CONTACT_INFO](state, contactsInfo) {
    const mappingGuardian = contactsInfo.filter((item: any) => item.type === 'guardian').map((item: any) => {
      return {
        id: item.id,
        firstname: item.firstname,
        lastname: item.lastname,
        type: item.type,
        email: item.email,
        phoneNumber: item.phoneNumber,
        addDate: item.addDate,
        userOrganization: item.userOrganization,
        companyName: item.company.name,
        company:item.company["@id"],
        createdBy: item.createdBy,
        createdAt: item.createdAt,
        updatedBy: item.updatedBy,
        updatedAt: item.updatedAt,
        deletedBy: item.deletedBy,
        deletedAt: item.deletedAt,
        isActive: item.isActive,
        active: item.active,
        readOnly: item.readOnly,
        externalDocs: item.externalDocs,
      }
    })
    const mappingManager = contactsInfo.filter((item: any) => item.type === 'manager').map((item: any) => {
      return {
        id: item.id,
        firstname: item.firstname,
        lastname: item.lastname,
        type: item.type,
        email: item.email,
        phoneNumber: item.phoneNumber,
        addDate: item.addDate,
        userOrganization: item.userOrganization,
        companyName: item.company.name,
        company:item.company["@id"],
        createdBy: item.createdBy,
        createdAt: item.createdAt,
        updatedBy: item.updatedBy,
        updatedAt: item.updatedAt,
        deletedBy: item.deletedBy,
        deletedAt: item.deletedAt,
        isActive: item.isActive,
        active: item.active,
        readOnly: item.readOnly,
        externalDocs: item.externalDocs,
      }
    })
    const mappingTechnician = contactsInfo.filter((item: any) => item.type === 'technician').map((item: any) => {
      return {
        id: item.id,
        firstname: item.firstname,
        lastname: item.lastname,
        type: item.type,
        email: item.email,
        phoneNumber: item.phoneNumber,
        addDate: item.addDate,
        userOrganization: item.userOrganization,
        companyName: item.company.name,
        company:item.company["@id"],
        createdBy: item.createdBy,
        createdAt: item.createdAt,
        updatedBy: item.updatedBy,
        updatedAt: item.updatedAt,
        deletedBy: item.deletedBy,
        deletedAt: item.deletedAt,
        isActive: item.isActive,
        active: item.active,
        readOnly: item.readOnly,
        externalDocs: item.externalDocs,
      }
    })
    state.guardianInfo = mappingGuardian
    state.managerInfo = mappingManager
    state.technicianInfo = mappingTechnician
    state.isLoaded = false
  },
  [CONTACT_MUTATION_TYPES.SET_ONE_CONTACT_INFO](state, contactsInfo) {
    state.guardianInfo = contactsInfo
    state.managerInfo = contactsInfo
    state.technicianInfo = contactsInfo
    state.isLoaded = false
  },
  [CONTACT_MUTATION_TYPES.SET_LOADING_STATE](state) {
    state.isLoaded = true
  },
  [CONTACT_MUTATION_TYPES.SET_CLIENTS_INFO](state, clientsInfo) {
    state.clientsInfo = clientsInfo
    state.isLoaded = false
  },
}
