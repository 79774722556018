import { IContact, IClientsInfo,ContactInfoResponse } from './types'

export interface State {
  guardianInfo: IContact[]
  technicianInfo: IContact[]
  managerInfo: IContact[]
  clientsInfo: IClientsInfo[]
  isLoaded: boolean
}

export default (): State => ({
  guardianInfo: [],
  technicianInfo: [],
  managerInfo: [],
  clientsInfo: [],
  isLoaded: false,
})
