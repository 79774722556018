import { ActionTree } from 'vuex'
import { Mutations, DASHBOARD_MUTATION_TYPES } from './mutations'
import { State } from './state'
import { RootState } from '@/store'
import { ApiCallConfig, API_ACTION_TYPES } from '../api/actions'
import {
  DashboardInfoResponse,
  newNoteRequest,
  newOsNumberRequest,
  mockedDashboardInfoResponse,
} from './types'
import { GenericActionContext } from '../types'
import { sleep } from '@/helpers/utils'

export enum DASHBOARD_ACTION_TYPES {
  GET_DASHBOARD_INFO = 'GET_DASHBOARD_INFO',
  SEND_NEW_NOTE = 'SEND_NEW_NOTE',
  SEND_NEW_OS_NUMBER = 'SEND_NEW_OS_NUMBER',
}
type AugmentedActionContext = GenericActionContext<State, Mutations>

export type Actions = {
  [DASHBOARD_ACTION_TYPES.GET_DASHBOARD_INFO](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<DashboardInfoResponse>
  [DASHBOARD_ACTION_TYPES.SEND_NEW_NOTE](
    arg1: AugmentedActionContext,
    payload: newNoteRequest
  ): Promise<unknown>
  [DASHBOARD_ACTION_TYPES.SEND_NEW_OS_NUMBER](
    arg1: AugmentedActionContext,
    payload: newOsNumberRequest
  ): Promise<unknown>
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [DASHBOARD_ACTION_TYPES.GET_DASHBOARD_INFO](
    { dispatch, commit },
    cancelToken
  ) {
    commit(DASHBOARD_MUTATION_TYPES.SET_LOADING_STATE)
    try {
      // let res = await dispatch<ApiCallConfig>({
      //   url: 'events',
      //   requestId: cancelToken,
      //   type: API_ACTION_TYPES.API_CALL,
      // })     
      await sleep(1500) 
      const res = mockedDashboardInfoResponse
      commit(DASHBOARD_MUTATION_TYPES.SET_DASHBOARD_INFO, res.dashboardInfo)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DASHBOARD_ACTION_TYPES.SEND_NEW_NOTE]({ dispatch }, { id, text }) {
    try {
      const res = await dispatch<ApiCallConfig>({
        url: `events/${id}/notes`,
        method: 'POST',
        data: { text: text },
        type: API_ACTION_TYPES.API_CALL,
      })
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DASHBOARD_ACTION_TYPES.SEND_NEW_OS_NUMBER](
    { dispatch },
    { id, serviceOrderNumber }
  ) {
    try {
      const res = await dispatch<ApiCallConfig>({
        url: `events/${id}`,
        method: 'PATCH',
        data: { serviceOrderNumber },
        type: API_ACTION_TYPES.API_CALL,
      })
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },
}
