import { MutationTree } from 'vuex'
import { State } from './state'
import { FilterCounterInfo, DashboardInfoItem } from './types'

export enum DASHBOARD_MUTATION_TYPES {
  SET_DASHBOARD_INFO = 'SET_DASHBOARD_INFO',
  SET_COUNTER_INFO = 'SET_COUNTER_INFO',
  SET_LOADING_STATE = 'SET_LOADING_STATE',
}

export type Mutations = {
  [DASHBOARD_MUTATION_TYPES.SET_DASHBOARD_INFO](
    state: State,
    dashboardInfo: DashboardInfoItem[]
  ): void
  [DASHBOARD_MUTATION_TYPES.SET_COUNTER_INFO](
    state: State,
    counterInfo: FilterCounterInfo
  ): void
  [DASHBOARD_MUTATION_TYPES.SET_LOADING_STATE](state: State): void
}

export const mutations: MutationTree<State> & Mutations = {
  [DASHBOARD_MUTATION_TYPES.SET_DASHBOARD_INFO](state, dashboardInfo) {
    state.dashboardInfo = dashboardInfo
    state.isLoaded = false
  },
  [DASHBOARD_MUTATION_TYPES.SET_COUNTER_INFO](state, counterInfo) {
    state.counterInfo = counterInfo
    state.isLoaded = false
  },
  [DASHBOARD_MUTATION_TYPES.SET_LOADING_STATE](state) {
    state.isLoaded = true
  },
}
