import { defineComponent, inject } from 'vue'
import { DEFAULT_PREFIX } from '@/components/shared/constants/global'
import { default as props } from './props'

export default defineComponent({
  name: 'QNativeRadio',
  props,
  emits: ['update:modelValue'],
  setup() {
    const prefix = inject('prefix', DEFAULT_PREFIX)

    return { prefix }
  },
})
