export interface MonitoringInfoItemAdditional {
  LastAlert: string
  SiteCode: string
  PostalCode: string
  ObjectName: string
}

export interface MonitoringInfoItem {
  Type: string
  Link: string
  Synchronization: string
  Client: string
  Residence: string
  Building: string
  Status: string
  Battery: string
  Additional: MonitoringInfoItemAdditional
  IsOpened?: boolean
}

export interface MonitoringDeviceItem {
  Id: number
  Type: string
  Status: string
  BatteryLevel: number
  SyncedAt: number
}

export interface MonitoringInfoResponse {
  monitoringInfo: MonitoringInfoItem[]
}

export interface MonitoringDevicesResponse {
  monitoringInfo: MonitoringDeviceItem[]
}

export enum MonitoringInfoStatuses {
  OK = 'OK',
  KO = 'KO',
  NA = 'NA',
}

export const mockedMonitoringInfoResponse = {
  monitoringInfo: [
    {
      Type: 'Skydome',
      Link: '13455678YHJ',
      Synchronization: '18/05/21',
      Client: 'CDC Habitat',
      Residence: 'Saouzelong',
      Building: 'B',
      Floor: 'Étage 1',
      Status: 'OK',
      Battery: '36%',
      Additional: {
        LastAlert: '18/05/21 - 12:35',
        SiteCode: '1234567890',
        PostalCode: '32 100',
        ObjectName: 'Skydome',
      },
    },
    {
      Type: 'Skydome',
      Link: '13455678YHJ',
      Synchronization: '18/05/21',
      Client: 'Adoma',
      Residence: 'Epivier',
      Building: 'A',
      Floor: 'Étage 7',
      Status: 'KO',
      Battery: '2%',
      Additional: {
        LastAlert: '18/05/21 - 12:35',
        SiteCode: '1234567890',
        PostalCode: '32 100',
        ObjectName: 'Skydome',
      },
    },
    {
      Type: 'Skydome',
      Link: '13455678YHJ',
      Synchronization: '18/05/21',
      Client: 'Sainte-Barbe',
      Residence: 'Loreaux',
      Building: 'B',
      Floor: 'Étage 3',
      Status: 'KO',
      Battery: '15%',
      Additional: {
        LastAlert: '18/05/21 - 12:35',
        SiteCode: '1234567890',
        PostalCode: '32 100',
        ObjectName: 'Skydome',
      },
    },
    {
      Type: 'Skydome',
      Link: '13455678YHJ',
      Synchronization: '18/05/21',
      Client: 'Les Sidom',
      Residence: 'Guérizon',
      Building: 'A',
      Floor: 'Étage 2',
      Status: 'KO',
      Battery: '19%',
      Additional: {
        LastAlert: '18/05/21 - 12:35',
        SiteCode: '1234567890',
        PostalCode: '32 100',
        ObjectName: 'Skydome',
      },
    },
    {
      Type: 'Skydome',
      Link: '13455678YHJ',
      Synchronization: '18/05/21',
      Client: 'CDC Habitat',
      Residence: 'Saouzelong',
      Building: 'B',
      Floor: 'Étage 7',
      Status: 'KO',
      Battery: '19%',
      Additional: {
        LastAlert: '18/05/21 - 12:35',
        SiteCode: '1234567890',
        PostalCode: '32 100',
        ObjectName: 'Skydome',
      },
    },
    {
      Type: 'Skydome',
      Link: '13455678YHJ',
      Synchronization: '18/05/21',
      Client: 'CDC Habitat',
      Residence: 'Saouzelong',
      Building: 'B',
      Floor: 'Étage 4',
      Status: 'NA',
      Battery: '19%',
      Additional: {
        LastAlert: '18/05/21 - 12:35',
        SiteCode: '1234567890',
        PostalCode: '32 100',
        ObjectName: 'Skydome',
      },
    },
    {
      Type: 'Skydome',
      Link: '13455678YHJ',
      Synchronization: '18/05/21',
      Client: 'Sainte-Barbe',
      Residence: 'Loreaux',
      Building: 'B',
      Floor: 'Étage 1',
      Status: 'NA',
      Battery: '19%',
      Additional: {
        LastAlert: '18/05/21 - 12:35',
        SiteCode: '1234567890',
        PostalCode: '32 100',
        ObjectName: 'Skydome',
      },
    },
    {
      Type: 'Skydome',
      Link: '13455678YHJ',
      Synchronization: '18/05/21',
      Client: 'Sainte-Barbe',
      Residence: 'Loreaux',
      Building: 'A',
      Floor: 'Étage 1',
      Status: 'NA',
      Battery: '19%',
      Additional: {
        LastAlert: '18/05/21 - 12:35',
        SiteCode: '1234567890',
        PostalCode: '32 100',
        ObjectName: 'Skydome',
      },
    },
    {
      Type: 'Skydome',
      Link: '13455678YHJ',
      Synchronization: '18/05/21',
      Client: 'Adoma',
      Residence: 'Floritin',
      Building: 'B',
      Floor: 'Étage 4',
      Status: 'OK',
      Battery: '19%',
      Additional: {
        LastAlert: '18/05/21 - 12:35',
        SiteCode: '1234567890',
        PostalCode: '32 100',
        ObjectName: 'Skydome',
      },
    },
    {
      Type: 'Ouverture du skydome 3',
      Link: '13455678YHJ',
      Synchronization: '18/05/21',
      Client: 'CDC Habitat',
      Residence: 'Saouzelong',
      Building: 'B',
      Floor: 'Étage 7',
      Status: 'OK',
      Battery: '19%',
      Additional: {
        LastAlert: '18/05/21 - 12:35',
        SiteCode: '1234567890',
        PostalCode: '32 100',
        ObjectName: 'Skydome',
      },
    },
    {
      Type: 'Ouverture du skydome 3',
      Link: '13455678YHJ',
      Synchronization: '18/05/21',
      Client: 'CDC Habitat',
      Residence: 'Saouzelong',
      Building: 'B',
      Floor: 'Étage 7',
      Status: 'OK',
      Battery: '19%',
      Additional: {
        LastAlert: '18/05/21 - 12:35',
        SiteCode: '1234567890',
        PostalCode: '32 100',
        ObjectName: 'Skydome',
      },
    },
    {
      Type: 'Ouverture du skydome 2',
      Link: '13455678YHJ',
      Synchronization: '18/05/21',
      Client: 'CDC Habitat',
      Residence: 'Saouzelong',
      Building: 'B',
      Floor: 'Étage 7',
      Status: 'OK',
      Battery: '19%',
      Additional: {
        LastAlert: '18/05/21 - 12:35',
        SiteCode: '1234567890',
        PostalCode: '32 100',
        ObjectName: 'Skydome',
      },
    },
    {
      Type: 'Ouverture du skydome 1',
      Link: '13455678YHJ',
      Synchronization: '18/05/21',
      Client: 'CDC Habitat',
      Residence: 'Saouzelong',
      Building: 'B',
      Floor: 'Étage 7',
      Status: 'OK',
      Battery: '19%',
      Additional: {
        LastAlert: '18/05/21 - 12:35',
        SiteCode: '1234567890',
        PostalCode: '32 100',
        ObjectName: 'Skydome',
      },
    },
    {
      Type: 'Ouverture du skydome 1',
      Link: '13455678YHJ',
      Synchronization: '18/05/21',
      Client: 'CDC Habitat',
      Residence: 'Saouzelong',
      Building: 'B',
      Floor: 'Étage 7',
      Status: 'OK',
      Battery: '36%',
      Additional: {
        LastAlert: '18/05/21 - 12:35',
        SiteCode: '1234567890',
        PostalCode: '32 100',
        ObjectName: 'Skydome',
      },
    },
    {
      Type: 'Ouverture du skydome 1',
      Link: '13455678YHJ',
      Synchronization: '18/05/21',
      Client: 'CDC Habitat',
      Residence: 'Saouzelong',
      Building: 'B',
      Floor: 'Étage 7',
      Status: 'KO',
      Battery: '2%',
      Additional: {
        LastAlert: '18/05/21 - 12:35',
        SiteCode: '1234567890',
        PostalCode: '32 100',
        ObjectName: 'Skydome',
      },
    },
    {
      Type: 'Ouverture du skydome 2',
      Link: '13455678YHJ',
      Synchronization: '18/05/21',
      Client: 'CDC Habitat',
      Residence: 'Saouzelong',
      Building: 'B',
      Floor: 'Étage 7',
      Status: 'KO',
      Battery: '15%',
      Additional: {
        LastAlert: '18/05/21 - 12:35',
        SiteCode: '1234567890',
        PostalCode: '32 100',
        ObjectName: 'Skydome',
      },
    },
    {
      Type: 'Ouverture du skydome 2',
      Link: '13455678YHJ',
      Synchronization: '18/05/21',
      Client: 'CDC Habitat',
      Residence: 'Saouzelong',
      Building: 'B',
      Floor: 'Étage 7',
      Status: 'KO',
      Battery: '19%',
      Additional: {
        LastAlert: '18/05/21 - 12:35',
        SiteCode: '1234567890',
        PostalCode: '32 100',
        ObjectName: 'Skydome',
      },
    },
    {
      Type: 'Ouverture du skydome 2',
      Link: '13455678YHJ',
      Synchronization: '18/05/21',
      Client: 'CDC Habitat',
      Residence: 'Saouzelong',
      Building: 'B',
      Floor: 'Étage 7',
      Status: 'KO',
      Battery: '19%',
      Additional: {
        LastAlert: '18/05/21 - 12:35',
        SiteCode: '1234567890',
        PostalCode: '32 100',
        ObjectName: 'Skydome',
      },
    },
    {
      Type: 'Ouverture du skydome 1',
      Link: '13455678YHJ',
      Synchronization: '18/05/21',
      Client: 'CDC Habitat',
      Residence: 'Saouzelong',
      Building: 'B',
      Floor: 'Étage 7',
      Status: 'NA',
      Battery: '19%',
      Additional: {
        LastAlert: '18/05/21 - 12:35',
        SiteCode: '1234567890',
        PostalCode: '32 100',
        ObjectName: 'Skydome',
      },
    },
    {
      Type: 'Ouverture du skydome 3',
      Link: '13455678YHJ',
      Synchronization: '18/05/21',
      Client: 'CDC Habitat',
      Residence: 'Saouzelong',
      Building: 'B',
      Floor: 'Étage 7',
      Status: 'NA',
      Battery: '19%',
      Additional: {
        LastAlert: '18/05/21 - 12:35',
        SiteCode: '1234567890',
        PostalCode: '32 100',
        ObjectName: 'Skydome',
      },
    },
    {
      Type: 'Ouverture du skydome 3',
      Link: '13455678YHJ',
      Synchronization: '18/05/21',
      Client: 'CDC Habitat',
      Residence: 'Saouzelong',
      Building: 'B',
      Floor: 'Étage 7',
      Status: 'NA',
      Battery: '19%',
      Additional: {
        LastAlert: '18/05/21 - 12:35',
        SiteCode: '1234567890',
        PostalCode: '32 100',
        ObjectName: 'Skydome',
      },
    },
    {
      Type: 'Ouverture du skydome 3',
      Link: '13455678YHJ',
      Synchronization: '18/05/21',
      Client: 'CDC Habitat',
      Residence: 'Saouzelong',
      Building: 'B',
      Floor: 'Étage 7',
      Status: 'OK',
      Battery: '19%',
      Additional: {
        LastAlert: '18/05/21 - 12:35',
        SiteCode: '1234567890',
        PostalCode: '32 100',
        ObjectName: 'Skydome',
      },
    },
    {
      Type: 'Ouverture du skydome 3',
      Link: '13455678YHJ',
      Synchronization: '18/05/21',
      Client: 'CDC Habitat',
      Residence: 'Saouzelong',
      Building: 'B',
      Floor: 'Étage 7',
      Status: 'OK',
      Battery: '19%',
      Additional: {
        LastAlert: '18/05/21 - 12:35',
        SiteCode: '1234567890',
        PostalCode: '32 100',
        ObjectName: 'Skydome',
      },
    },
    {
      Type: 'Ouverture du skydome 3',
      Link: '13455678YHJ',
      Synchronization: '18/05/21',
      Client: 'CDC Habitat',
      Residence: 'Saouzelong',
      Building: 'B',
      Floor: 'Étage 7',
      Status: 'OK',
      Battery: '19%',
      Additional: {
        LastAlert: '18/05/21 - 12:35',
        SiteCode: '1234567890',
        PostalCode: '32 100',
        ObjectName: 'Skydome',
      },
    },
  ],
}
