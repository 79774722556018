import { MutationTree } from 'vuex'
import { State } from './state'
import { MonitoringInfoItem } from './types'

export enum MONITORING_MUTATION_TYPES {
  SET_MONITORING_INFO = 'SET_MONITORING_INFO',
  SET_LOADING_STATE = 'SET_LOADING_STATE',
  SET_DEVICES = 'SET_DEVICES',
}

export type Mutations = {
  [MONITORING_MUTATION_TYPES.SET_MONITORING_INFO](
    state: State,
    dashboardInfo: MonitoringInfoItem[]
  ): void
  [MONITORING_MUTATION_TYPES.SET_DEVICES](state: State, events: any): void
  [MONITORING_MUTATION_TYPES.SET_LOADING_STATE](state: State): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MONITORING_MUTATION_TYPES.SET_MONITORING_INFO](state, monitoringInfo) {
    state.monitoringInfo = monitoringInfo
    state.isLoaded = false
  },
  [MONITORING_MUTATION_TYPES.SET_DEVICES](state, events) {
    state.events = events
  },
  [MONITORING_MUTATION_TYPES.SET_LOADING_STATE](state) {
    state.isLoaded = true
  },
}
