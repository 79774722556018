import { MutationTree } from 'vuex'
import { State } from './state'
import { UsersItem, PersonalInfo } from './types'

export enum USER_SETTINGS_MUTATION_TYPES {
  SET_USERS = 'SET_USERS',
  SET_PERSONAL_INFO = 'SET_PERSONAL_INFO',
  SET_LOADING_STATE = 'SET_LOADING_STATE',
  SET_ERROR_STATE = 'SET_ERROR_STATE',
  RESET_ERROR_STATE = 'RESET_ERROR_STATE'
}

export type Mutations = {
  [USER_SETTINGS_MUTATION_TYPES.SET_USERS](
    state: State,
    dashboardInfo: UsersItem[]
  ): void
  [USER_SETTINGS_MUTATION_TYPES.SET_PERSONAL_INFO](
    state: State,
    personalInfo: PersonalInfo
  ): void
}

export const mutations: MutationTree<State> & Mutations = {
  [USER_SETTINGS_MUTATION_TYPES.SET_USERS](state, users) {
    state.users = users
    state.isLoaded = false
  },
  [USER_SETTINGS_MUTATION_TYPES.SET_PERSONAL_INFO](state, personalInfo) {
    state.personalInfo = personalInfo
    state.isLoaded = false
  },
  [USER_SETTINGS_MUTATION_TYPES.SET_LOADING_STATE](state) {
    state.isLoaded = true
  },
  [USER_SETTINGS_MUTATION_TYPES.SET_ERROR_STATE](state, error) {
    state.isError = true
    state.error = {status: error.status, message: error.message}
  },
  [USER_SETTINGS_MUTATION_TYPES.RESET_ERROR_STATE](state) {
    state.isError = false
    state.error = {status: '', message: ''}
  },
}
