type binding = { value: (arg0: Event, arg1: HTMLElement) => any }

export const clickOutsideEvent = (
  event: Event,
  el: HTMLElement,
  binding: binding
): void => {
  if (!(event.target instanceof HTMLElement)) return
  if (!(el === event.target || el.contains(event.target))) {
    binding.value(event, el)
  }
}

interface clickOutsideEl extends HTMLElement {
  clickOutsideEvent: (arg0: Event) => void
}

export default {
  beforeMount(el: clickOutsideEl, binding: binding): void {
    el.clickOutsideEvent = function (event: Event) {
      clickOutsideEvent(event, el, binding)
    }
    document.body.addEventListener('pointerdown', el.clickOutsideEvent)
  },
  unmounted(el: clickOutsideEl): void {
    document.body.removeEventListener('pointerdown', el.clickOutsideEvent)
  },
}
