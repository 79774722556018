import { defineComponent, ref, computed, nextTick, watch } from 'vue'
import QIcon from '../Icon/Icon.vue'
import clickOutside from '@/directives/ClickOutside'
import { default as props, IProps } from './props'

const COMBO_BOX_WIDTH_DEFAULT = ''

export default defineComponent({
  directives: { clickOutside },
  components: { QIcon },
  props,
  emits: ['update:modelValue', 'input', 'change', 'input:search'],
  setup(props: IProps, { emit }) {
    const itemsListVisible = ref(false)
    const listboxEl = ref<null | HTMLDivElement>(null)
    const comboBoxWidth = ref<string>(COMBO_BOX_WIDTH_DEFAULT)
    const searchFilter = ref('')

    const getValue = (item: any) => {
      if (typeof item === 'object' && typeof props.keyValue === 'string')
        return item[props.keyValue]
      return item
    }

    const getName = (item: any) => {
      if (typeof item === 'object' && typeof props.keyName === 'string')
        return item[props.keyName]
      return item
    }

    const onSelect = (event: any) => {
      const value = getValue(event)

      emit('update:modelValue', value)
      emit('change', event)
      emit('input', event)
      itemsListVisible.value = false
    }

    const onClickOutside = () => {
      itemsListVisible.value = false
      searchFilter.value = ''
    }

    const onSearchFilterInput = (e: Event) => {
      emit('input:search', e)
    }

    const selectedOption = computed(() =>
      props.options.find((option) => getValue(option) === props.modelValue)
    )

    const visibleOptions = computed(() => {
      if (!props.search) return props.options
      if (!searchFilter.value) return props.options.slice(0, props.maxItems)
      return props.options
        .filter((option) =>
          getName(option)
            .toString()
            .toLowerCase()
            .includes(searchFilter.value.toLowerCase())
        )
        .slice(0, props.maxItems)
    })

    watch(
      () => itemsListVisible.value,
      () => {
        props.autoExpand &&
          nextTick(() => {
            comboBoxWidth.value = `max(100%, ${listboxEl.value?.offsetWidth}px)`
          })
      }
    )

    return {
      selectedOption,
      itemsListVisible,
      comboBoxWidth,
      listboxEl,
      searchFilter,
      visibleOptions,
      getValue,
      getName,
      onSelect,
      onClickOutside,
      onSearchFilterInput,
    }
  },
})
