import { ActionTree } from 'vuex'
import { Mutations, USER_SETTINGS_MUTATION_TYPES } from './mutations'
import { State } from './state'
import { RootState, store } from '@/store'
import { ApiCallConfig, API_ACTION_TYPES } from '../api/actions'
import { baseURL } from '@/plugins/axios'
import { AxiosError } from 'axios'
import {
  mockedUsersResponse,
  mockedPersonalInfoResponse,
  UsersResponse,
  PersonalInfo,
  PersonalInfoResponse,
  UsersItem,
  INewPassword,
} from './types'
import { GenericActionContext } from '../types'

export enum USER_SETTINGS_ACTION_TYPES {
  GET_USERS = 'GET_USERS',
  ADD_USER = 'ADD_USER',
  GET_PERSONAL_INFO = 'GET_PERSONAL_INFO',
  UPDATE_PERSONAL_INFO = 'UPDATE_PERSONAL_INFO',
  UPDATE_PASSWORD = 'UPDATE_PASSWORD',
  RESET_ERROR_STATE = 'RESET_ERROR_STATE'
}

enum USER_API_ENDPOINT {
  GET_PERSONAL_INFO = '/user/current',
  UPDATE_PERSONAL_INFO = '',
  UPDATE_PASSWORD = '/change_password_requests',
  USERS = '/users',
}


type AugmentedActionContext = GenericActionContext<State, Mutations>

export type Actions = {
  [USER_SETTINGS_ACTION_TYPES.GET_USERS](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<UsersResponse>
  [USER_SETTINGS_ACTION_TYPES.ADD_USER](
    arg1: AugmentedActionContext,
    payload: UsersItem
  ): Promise<unknown>
  [USER_SETTINGS_ACTION_TYPES.GET_PERSONAL_INFO](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<PersonalInfoResponse>
  [USER_SETTINGS_ACTION_TYPES.UPDATE_PERSONAL_INFO](
    arg1: AugmentedActionContext,
    payload: PersonalInfo
  ): Promise<unknown>
  [USER_SETTINGS_ACTION_TYPES.UPDATE_PASSWORD](
    arg1: AugmentedActionContext,
    payload: INewPassword
  ): Promise<INewPassword>
  [USER_SETTINGS_ACTION_TYPES.RESET_ERROR_STATE](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<unknown>
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [USER_SETTINGS_ACTION_TYPES.GET_USERS]({ dispatch, commit }) {
    commit(USER_SETTINGS_MUTATION_TYPES.SET_LOADING_STATE)
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + USER_API_ENDPOINT.USERS,
        method: 'GET',
        type: API_ACTION_TYPES.API_CALL,
      })
      commit(USER_SETTINGS_MUTATION_TYPES.SET_USERS, res["hydra:member"])
      return Promise.resolve(res)
    } catch (error) {
      const axiosErr = error as AxiosError
      const errorStatus = axiosErr.response?.status
      const data = axiosErr.response?.data
      commit(USER_SETTINGS_MUTATION_TYPES.SET_ERROR_STATE, {status:errorStatus, message: data?.message })
      return Promise.reject(error)
    }
  },

  async [USER_SETTINGS_ACTION_TYPES.ADD_USER]({ dispatch, commit }, newUser) {
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + USER_API_ENDPOINT.USERS,
        method: 'POST',
        data: {
          lastname: newUser.lastname,
          firstname: newUser.firstname,
          login: newUser.email,
          email: newUser.email,
          phoneNumber: newUser.phoneNumber,
          password: "test",
          roles: ["ROLE_USER"],
          userOrganization: store.state.userSettings.personalInfo.userOrganization,
        },
        type: API_ACTION_TYPES.API_CALL,
      })
      return Promise.resolve(res)
    } catch (error) {
      const axiosErr = error as AxiosError
      const errorStatus = axiosErr.response?.status
      const data = axiosErr.response?.data
      commit(USER_SETTINGS_MUTATION_TYPES.SET_ERROR_STATE, {status:errorStatus, message: data?.message })
      return Promise.reject(error)
    }
  },

  async [USER_SETTINGS_ACTION_TYPES.GET_PERSONAL_INFO](
    { dispatch, commit },
    cancelToken
  ) {
    commit(USER_SETTINGS_MUTATION_TYPES.SET_LOADING_STATE)
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + USER_API_ENDPOINT.GET_PERSONAL_INFO,
        method: 'GET',
        requestId: cancelToken,
        type: API_ACTION_TYPES.API_CALL,
      })
      commit(USER_SETTINGS_MUTATION_TYPES.SET_PERSONAL_INFO, res)
      return Promise.resolve(res)
    } catch (error) {
      const axiosErr = error as AxiosError
      const errorStatus = axiosErr.response?.status
      const data = axiosErr.response?.data
      commit(USER_SETTINGS_MUTATION_TYPES.SET_ERROR_STATE, {status:errorStatus, message: data?.message })
      return Promise.reject(error)
    }
  },

  async [USER_SETTINGS_ACTION_TYPES.UPDATE_PERSONAL_INFO](
    { dispatch, commit },
    updatedUserInfo
  ) {
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + USER_API_ENDPOINT.USERS + "/" + updatedUserInfo.id,
        method: 'PUT',
        data: updatedUserInfo,
        type: API_ACTION_TYPES.API_CALL,
      })
      return Promise.resolve(res)
    } catch (error) {
      const axiosErr = error as AxiosError
      const errorStatus = axiosErr.response?.status
      const data = axiosErr.response?.data
      commit(USER_SETTINGS_MUTATION_TYPES.SET_ERROR_STATE, {status:errorStatus, message: data?.message })
      return Promise.reject(error)
    }
  },

  async [USER_SETTINGS_ACTION_TYPES.UPDATE_PASSWORD](
    { dispatch, commit },
    updatedPasswordInfo
  ) {
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL + USER_API_ENDPOINT.UPDATE_PASSWORD,
        method: 'POST',
        data: updatedPasswordInfo,
        type: API_ACTION_TYPES.API_CALL,
      })
      return Promise.resolve(res)
    } catch (error) {      
      const axiosErr = error as AxiosError
      const errorStatus = axiosErr.response?.status
      const data = axiosErr.response?.data
      commit(USER_SETTINGS_MUTATION_TYPES.SET_ERROR_STATE, {status:errorStatus, message: data?.message })
      return Promise.reject(error)
    }
  },

  async [USER_SETTINGS_ACTION_TYPES.RESET_ERROR_STATE](
    { dispatch, commit }
  ) {
    commit(USER_SETTINGS_MUTATION_TYPES.RESET_ERROR_STATE)
    return Promise.resolve()
  },
}
