import { TOKEN_KEY, REFRESH_TOKEN_KEY } from '@/constants'

export const saveToken = (token: string) => {
  localStorage.setItem(TOKEN_KEY, token)
}

export const saveRefreshToken = (token: string) => {
  localStorage.setItem(REFRESH_TOKEN_KEY, token)
}

export const getToken = () => {
  return localStorage.getItem(TOKEN_KEY)
}

export const getRefreshToken = () => {
  return localStorage.getItem(REFRESH_TOKEN_KEY)
}

export const isRefreshTokenExist = () => {
  return ![null, undefined, '', 'undefined'].includes(getRefreshToken())
}

export const isTokenExist = () => {
  return ![null, undefined, '', 'undefined'].includes(getToken())
}

export const clearToken = () => {
  localStorage.setItem(TOKEN_KEY, '')
  localStorage.setItem(REFRESH_TOKEN_KEY, '')
}

export const deleteToken = () => {
  localStorage.removeItem(TOKEN_KEY)
  localStorage.removeItem(REFRESH_TOKEN_KEY)
}
