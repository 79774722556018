import { PropType } from 'vue'
import { ILoading, default as loading } from '../../shared/props/loading'

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface IHeader {
  label: string
  field: string
  sortable: boolean
  customSort?: (arg1: IDataItem, arg2: IDataItem) => number
}

export interface IDataItem {
  [key: string]: any
}

export type Filters = (((...args: any) => []) | string)[]

export interface IProps extends ILoading {
  headers: IHeader[]
  data: IDataItem[]
  sortable: boolean
  defaultSortColumn?: string | null
  defaultSortDirection?: SortDirection | null
  pagination: boolean
  perPageControl: boolean
  perPageItems: number[]
  perPage: number
  selectable: boolean
  bordered: boolean
  headerClass?: string
  cellClass?: string
  rowClass?: string
  controlsClass?: string
  filters: Filters
  priorityRowKey?: string
}

export const DEFAULT_HEADERS = []
export const DEFAULT_DATA = []
export const DEFAULT_SORTABLE = false
export const DEFAULT_DEFAULT_SORT_COLUMN = null
export const DEFAULT_DEFAULT_SORT_DIRECTION = SortDirection.ASC
export const DEFAULT_LOADING = false
export const DEFAULT_PAGINATION = true
export const DEFAULT_PER_PAGE = 10
export const DEFAULT_PER_PAGE_CONTROL = false
export const DEFAULT_PER_PAGE_ITEMS = [10, 25, 50, 100]
export const DEFAULT_SELECTABLE = false
export const DEFAULT_BORDERED = false
export const DEFAULT_HEADER_CLASS = ''
export const DEFAULT_CELL_CLASS = ''
export const DEFAULT_BODY_CLASS = ''
export const DEFAULT_ROW_CLASS = ''
export const DEFAULT_CONTROLS_CLASS = ''
export const DEFAULT_FILTERS = []
export const DEFAULT_PRIORITY_ROW_KEY = ''

const headers = {
  type: Array as PropType<IHeader[]>,
  required: true,
  default: [],
}
const data = {
  type: Array as PropType<IDataItem[]>,
  required: true,
  default: [],
}
const sortable = {
  type: Boolean,
  default: DEFAULT_SORTABLE,
}
const defaultSortColumn = {
  type: String,
  default: DEFAULT_DEFAULT_SORT_COLUMN,
}
const defaultSortDirection = {
  type: String as PropType<SortDirection | null>,
  default: DEFAULT_DEFAULT_SORT_DIRECTION,
}
const pagination = {
  type: Boolean,
  default: DEFAULT_PAGINATION,
}
const perPageControl = {
  type: Boolean,
  default: DEFAULT_PER_PAGE_CONTROL,
}
const perPage = {
  type: Number,
  default: DEFAULT_PER_PAGE,
}
const perPageItems = {
  type: Array as PropType<number[]>,
  default: DEFAULT_PER_PAGE_ITEMS,
}
const selectable = {
  type: Boolean,
  default: DEFAULT_SELECTABLE,
}
const bordered = {
  type: Boolean,
  default: DEFAULT_BORDERED,
}
const headerClass = {
  type: String,
  required: false,
  default: DEFAULT_HEADER_CLASS,
}
const cellClass = {
  type: String,
  required: false,
  default: DEFAULT_CELL_CLASS,
}
const rowClass = {
  type: String,
  required: false,
  default: DEFAULT_ROW_CLASS,
}
const bodyClass = {
  type: String,
  required: false,
  default: DEFAULT_BODY_CLASS,
}
const controlsClass = {
  type: String,
  required: false,
  default: DEFAULT_CONTROLS_CLASS,
}
const filters = {
  type: Array as PropType<Filters>,
  required: false,
  default: DEFAULT_FILTERS,
}
const priorityRowKey = {
  type: String,
  required: false,
  default: DEFAULT_PRIORITY_ROW_KEY,
}

export default {
  headers,
  data,
  sortable,
  defaultSortColumn,
  defaultSortDirection,
  loading,
  pagination,
  perPageControl,
  perPage,
  perPageItems,
  selectable,
  bordered,
  headerClass,
  cellClass,
  rowClass,
  bodyClass,
  controlsClass,
  filters,
  priorityRowKey,
}
