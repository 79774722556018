import {
  default as disabled,
  IDisabled,
} from '@/components/shared/props/disabled'
import {
  default as placeholder,
  IPlaceholder,
} from '@/components/shared/props/placeholder'
import { default as keyName, IKeyName } from '@/components/shared/props/keyName'
import {
  default as keyValue,
  IKeyValue,
} from '@/components/shared/props/keyValue'
import { PropType } from 'vue'

type options = unknown[]
export interface IProps extends IDisabled, IPlaceholder, IKeyName, IKeyValue {
  options: options
  modelValue: unknown
  bgColor?: string
  autoExpand?: boolean
  search?: boolean
  searchPlaceholder?: string
  searchInputAttrs?: string
  maxItems: number
  placeholderClass: string
}

export const options = {
  type: Array as PropType<options>,
  required: true,
  default: (): [] => [],
}

export const modelValue = {
  required: true,
  default: '',
}

export const bgColor = {
  type: String,
  required: false,
  default: 'white',
}

export const autoExpand = {
  type: Boolean,
  required: false,
  default: false,
}

export const search = {
  type: Boolean,
  required: false,
  default: false,
}

export const searchPlaceholder = {
  type: String,
  required: false,
  default: '',
}

export const searchInputAttrs = {
  type: Object,
  required: false,
  default: (): Record<string, unknown> => ({}),
}

export const filterAttrs = {
  type: Object,
  required: false,
  default: (): Record<string, unknown> => ({}),
}

export const errorMessage = {
  type: String,
  required: false,
  default: '',
}

export const maxItems = {
  type: Number,
  required: false,
  default: 150,
}

export const placeholderClass = {
  type: String,
  required: false,
  default: '',
}

export default {
  options,
  modelValue,
  keyName,
  keyValue,
  disabled,
  placeholder,
  bgColor,
  autoExpand,
  search,
  searchPlaceholder,
  filterAttrs,
  errorMessage,
  maxItems,
  placeholderClass,
}
