export interface IProps {
  modelValue: number | string
  value: number | string
}

export const modelValue = {
  type: [String, Number],
  default: '',
}

export const value = {
  type: [String, Number],
  required: true,
}

export default {
  modelValue,
  value,
}
