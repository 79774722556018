import { IDataItem, SortDirection } from './props'
import { IModifiedDataItem } from './script'

export const getSortedDataByField = (
  items: IDataItem[],
  field: string,
  sortDirection: SortDirection
): IDataItem[] => {
  const data = [...items]

  data.sort((a, b) => {
    return sortDirection === SortDirection.ASC
      ? a[field] - b[field]
      : b[field] - a[field]
  })

  return data
}

export const filterDataByString = (
  filterString: string,
  data: (IDataItem | IModifiedDataItem)[]
): typeof data => {
  return data.filter((row: IDataItem) =>
    Object.values(row)
      .map((el) => el.toString().toLowerCase())
      .map((rowValue: string) => rowValue.includes(filterString.toLowerCase()))
      .reduce((acc, curr) => acc || curr, false)
  )
}
