import { ActionTree } from 'vuex'
import { Mutations, AUTH_MUTATION_TYPES } from './mutations'
import { State } from './state'
import { RootState } from '@/store'
import { ApiCallConfig, API_ACTION_TYPES } from '../api/actions'
import { baseURL } from '@/plugins/axios'
import { AxiosError } from 'axios'
import {
  LoginRequestBody,
  SendCodeRequest,
  UserInfo,
  // SmsCodeCheck,
} from './types'
import { GenericActionContext } from '../types'
import { saveToken, saveRefreshToken } from '@/helpers/token'

export enum AUTH_ACTION_TYPES {
  LOGIN = 'AUTH_LOGIN',
  CREATE_PASSWORD = 'AUTH_CREATE_PASSWORD',
  CHECK_SMS = 'CHECK_SMS',
  SEND_VALIDATION_CODE = 'SEND_VALIDATION_CODE',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  RESET_ERROR_STATE = 'RESET_ERROR_STATE'
}

enum AUTH_API_ENDPOINT {
  LOGIN = '/authentication/token',
  REFRESH_TOKEN = '/authentication/token/refresh',
  FORGET_PASSWORD = '/public/forget_password_requests'
}
type AugmentedActionContext = GenericActionContext<State, Mutations>

export type Actions = {
  [AUTH_ACTION_TYPES.LOGIN](
    { commit }: AugmentedActionContext,
    payload: LoginRequestBody
  ): Promise<unknown>
  [AUTH_ACTION_TYPES.CREATE_PASSWORD](
    ctx: AugmentedActionContext,
    payload: unknown
  ): Promise<unknown>
  [AUTH_ACTION_TYPES.CHECK_SMS](
    arg1: AugmentedActionContext,
    payload: unknown
  ): Promise<unknown>
  [AUTH_ACTION_TYPES.SEND_VALIDATION_CODE](
    ctx: AugmentedActionContext,
    payload: SendCodeRequest
  ): Promise<UserInfo>
  [AUTH_ACTION_TYPES.REFRESH_TOKEN](
    ctx: AugmentedActionContext,
    payload: string
  ): Promise<unknown>
  [AUTH_ACTION_TYPES.RESET_ERROR_STATE](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<unknown>
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [AUTH_ACTION_TYPES.LOGIN]({ dispatch, commit }, payload) {
    try {
      const { email, password } = payload
      const  res = await dispatch<ApiCallConfig & { type: string }>(
        {
          url: baseURL+AUTH_API_ENDPOINT.LOGIN,
          method: 'POST',
          data: {
            email,
            password,
          },
          type: API_ACTION_TYPES.API_CALL,
        },
        {
          root: true,
        }
      )
      commit(AUTH_MUTATION_TYPES.SET_TOKEN, { token: res.token, refresh_token : res.refresh_token })
      saveToken(res.token)
      saveRefreshToken(res.refresh_token)
      return Promise.resolve(res.token)
    } catch (error) {
      const axiosErr = error as AxiosError
      const errorStatus = axiosErr.response?.status
      const data = axiosErr.response?.data
      commit(AUTH_MUTATION_TYPES.SET_ERROR_STATE, {status:errorStatus, message: data?.message })
      return Promise.reject(error)
    }
  },

  async [AUTH_ACTION_TYPES.SEND_VALIDATION_CODE]({ dispatch, commit }, email) {
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL+AUTH_API_ENDPOINT.FORGET_PASSWORD,
        method: 'POST',
        data: email,
        type: API_ACTION_TYPES.API_CALL,
      })
      commit(AUTH_MUTATION_TYPES.SET_USER_INFO, res.data)
      return Promise.resolve(res)
    } catch (error) {
      const axiosErr = error as AxiosError
      const errorStatus = axiosErr.response?.status
      const data = axiosErr.response?.data
      commit(AUTH_MUTATION_TYPES.SET_ERROR_STATE, {status:errorStatus, message: data?.message })
      return Promise.reject(error)
    }
  },

  async [AUTH_ACTION_TYPES.CHECK_SMS]({ dispatch,commit }, smsCode) {
    try {
      const res = await dispatch<ApiCallConfig>({
        url: 'forgot-password/verify-code',
        method: 'POST',
        data: smsCode,
        type: API_ACTION_TYPES.API_CALL,
      })
      return Promise.resolve(res)
    } catch (error) {
      const axiosErr = error as AxiosError
      const errorStatus = axiosErr.response?.status
      const data = axiosErr.response?.data
      commit(AUTH_MUTATION_TYPES.SET_ERROR_STATE, {status:errorStatus, message: data?.message })
      return Promise.reject(error)
    }
  },

  async [AUTH_ACTION_TYPES.CREATE_PASSWORD]({ dispatch,commit }, password) {
    try {
      const res = await dispatch<ApiCallConfig>({
        url: 'forgot-password/reset-password',
        method: 'patch',
        data: password,
        type: API_ACTION_TYPES.API_CALL,
      })
      return Promise.resolve(res)
    } catch (error) {
      const axiosErr = error as AxiosError
      const errorStatus = axiosErr.response?.status
      const data = axiosErr.response?.data
      commit(AUTH_MUTATION_TYPES.SET_ERROR_STATE, {status:errorStatus, message: data?.message })
      return Promise.reject(error)
    }
  },

  async [AUTH_ACTION_TYPES.REFRESH_TOKEN]({ commit, dispatch }, refreshToken) {
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL+AUTH_API_ENDPOINT.REFRESH_TOKEN,
        method: 'post',
        data: {
          refresh_token: refreshToken
        },
        type: API_ACTION_TYPES.API_CALL,
      })
      commit(AUTH_MUTATION_TYPES.SET_TOKEN, { token:res.token, refresh_token:res.refresh_token })
      saveToken(res.token)
      saveRefreshToken(res.refresh_token)
      return Promise.resolve(res.token)
    } catch (error) {
      const axiosErr = error as AxiosError
      const errorStatus = axiosErr.response?.status
      const data = axiosErr.response?.data
      commit(AUTH_MUTATION_TYPES.SET_ERROR_STATE, {status:errorStatus, message: data?.message })
      return Promise.reject(error)
    }
  },
  
  async [AUTH_ACTION_TYPES.RESET_ERROR_STATE](
    { dispatch, commit }
  ) {
    commit(AUTH_MUTATION_TYPES.RESET_ERROR_STATE)
    return Promise.resolve()
  },
}
