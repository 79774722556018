import autocomplete from '@/components/shared/props/autocomplete'
import placeholder from '@/components/shared/props/placeholder'
import disabled from '@/components/shared/props/disabled'
import readonly from '@/components/shared/props/readonly'
import clearable from '@/components/shared/props/clearable'
import tabindex from '@/components/shared/props/tabindex'

import { IAutocomplete } from '@/components/shared/props/autocomplete'
import { IPlaceholder } from '@/components/shared/props/placeholder'
import { IDisabled } from '@/components/shared/props/disabled'
import { IReadonly } from '@/components/shared/props/readonly'
import { IClearable } from '@/components/shared/props/clearable'
import { ITabIndex } from '@/components/shared/props/tabindex'

export const DEFAULT_STRING_MODEL_VALUE = ''
export const DEFAULT_LABEL_VALUE = ''

export interface IProps
  extends IAutocomplete,
    IPlaceholder,
    IDisabled,
    IReadonly,
    IClearable,
    ITabIndex {
  modelValue: string
  errorMessage?: string
  bgColor?: string
  required?: boolean
  noErrorField?: boolean
  nativeClass?: string
}

export const label = {
  type: String,
  required: false,
  default: DEFAULT_LABEL_VALUE,
}

export const modelValue = {
  type: String,
  required: true,
  default: DEFAULT_STRING_MODEL_VALUE,
}

export const errorMessage = {
  type: String,
  required: false,
  default: '',
}

export const fieldClass = {
  type: String,
  required: false,
  default: '',
}

export const type = {
  type: String,
  required: false,
  default: 'text',
}

export const required = {
  type: Boolean,
  required: false,
  default: false,
}

export const noErrorField = {
  type: Boolean,
  required: false,
  default: false,
}

export const nativeClass = {
  type: String,
  required: false,
  default: '',
}

export const nativeElAttrs = {
  type: Object,
  required: false,
  default: (): Record<string, unknown> => ({}),
}

export default {
  modelValue,
  autocomplete,
  placeholder,
  disabled,
  readonly,
  clearable,
  tabindex,
  label,
  errorMessage,
  fieldClass,
  type,
  required,
  noErrorField,
  nativeClass,
  nativeElAttrs,
}
