import {
  default as disabled,
  IDisabled,
} from '@/components/shared/props/disabled'

export interface IProps extends IDisabled {
  hideTimeout: number
  listOffset: number
}

export const DEFAULT_HIDE_TIMEOUT = 200
export const DEFAULT_LIST_OFFSET = 5

export const HIDE_TIMEOUT_STORY_SETTINGS = {
  name: 'hideTimeout',
  type: { name: 'number', required: false },
  table: {
    type: { summary: 'number' },
    defaultValue: { summary: DEFAULT_HIDE_TIMEOUT },
  },
  control: 'number',
  description: 'Timeout for hide event trigger',
  defaultValue: DEFAULT_HIDE_TIMEOUT,
}

export const LIST_OFFSET_STORY_SETTINGS = {
  name: 'listOffset',
  type: { name: 'number', required: false },
  table: {
    type: { summary: 'number' },
    defaultValue: { summary: DEFAULT_LIST_OFFSET },
  },
  control: 'number',
  description: 'Value of list offset(px) from trigger element',
  defaultValue: DEFAULT_LIST_OFFSET,
}

export const hideTimeout = {
  type: Number,
  default: DEFAULT_HIDE_TIMEOUT,
}

export const listOffset = {
  type: Number,
  default: DEFAULT_LIST_OFFSET,
}

export default {
  hideTimeout,
  listOffset,
  disabled,
}
