import { defineComponent } from 'vue'
import NativeCheckbox from '../NativeCheckbox/NativeCheckbox.vue'
import { default as props } from './props'

export default defineComponent({
  name: 'QInputCheckbox',
  components: {
    NativeCheckbox,
  },
  props,
  emits: ['update:modelValue', 'change'],
  setup() {
    return {}
  },
})
