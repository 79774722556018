import {
  default as disabled,
  IDisabled,
} from '@/components/shared/props/disabled'
import { default as name, IName } from '@/components/shared/props/name'
import { default as id, IIdentificator } from '@/components/shared/props/id'
import {
  default as autofocus,
  IAutofocus,
} from '@/components/shared/props/autofocus'
import {
  default as placeholder,
  IPlaceholder,
} from '@/components/shared/props/placeholder'
import { default as keyName, IKeyName } from '@/components/shared/props/keyName'
import {
  default as keyValue,
  IKeyValue,
} from '@/components/shared/props/keyValue'

export interface IProps
  extends IDisabled,
    IName,
    IIdentificator,
    IAutofocus,
    IPlaceholder,
    IKeyName,
    IKeyValue {
  modelValue: any
  options: (string | number)[] | Record<string, unknown>[]
}

export const options = {
  required: true,
  default: (): any[] => [],
}

export const modelValue = {
  required: true,
  default: '',
}

export const label = {
  type: String,
  default: '',
}

export const required = {
  type: Boolean,
  default: false,
}

export default {
  options,
  modelValue,
  keyName,
  keyValue,
  autofocus,
  disabled,
  id,
  name,
  placeholder,
  label,
  required,
}
