import { IDevice, IClientsInfo } from './types'

export interface State {
  activityInfo: IDevice[]
  clientsInfo: IClientsInfo[]
  isLoaded: boolean
}

export default (): State => ({
  activityInfo: [],
  clientsInfo: [],
  isLoaded: false,
})
