import { MutationTree } from 'vuex'
import { State } from './state'
import { CompanyInfoResponse, IClientsInfo } from './types'

export enum COMPANY_MUTATION_TYPES {
  SET_COMPANY_INFO = 'SET_COMPANY_INFO',
  SET_CLIENTS_INFO = 'SET_CLIENTS_INFO',
  SET_LOADING_STATE = 'SET_LOADING_STATE',
}

export type Mutations = {
  [COMPANY_MUTATION_TYPES.SET_COMPANY_INFO](
    state: State,
    companyInfo: CompanyInfoResponse[]
  ): void
  [COMPANY_MUTATION_TYPES.SET_LOADING_STATE](state: State): void
  [COMPANY_MUTATION_TYPES.SET_CLIENTS_INFO](
    state: State,
    clientsInfo: IClientsInfo[]
  ): void
}

export const mutations: MutationTree<State> & Mutations = {
  [COMPANY_MUTATION_TYPES.SET_COMPANY_INFO](state, companyInfo) {
    state.companyInfo = companyInfo
    state.isLoaded = false
  },
  [COMPANY_MUTATION_TYPES.SET_LOADING_STATE](state) {
    state.isLoaded = true
  },
  [COMPANY_MUTATION_TYPES.SET_CLIENTS_INFO](state, clientsInfo) {
    state.clientsInfo = clientsInfo
    state.isLoaded = false
  },
}
