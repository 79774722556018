import { defineComponent, ref } from 'vue'
import { default as props } from './props'

export default defineComponent({
  props,
  emits: ['update:modelValue', 'change', 'clear', 'input'],
  setup(props, { emit }) {
    const focused = ref(false)

    const onChange = (event: { target: HTMLInputElement }) => {
      const value = event.target.value.trim()

      if (props.maxLength && value.length > props.maxLength) {
        emit('update:modelValue', value.substring(0, props.maxLength))

        return false
      }

      emit('change', event)
      emit('update:modelValue', value)
    }

    const onInput = (event: { target: HTMLInputElement }) => {
      emit('input', event)
      emit('update:modelValue', event.target.value.trim())
    }

    const onClear = () => {
      emit('update:modelValue', '')
      emit('clear')
    }

    const onFocus = () => {
      focused.value = true
    }

    const onBlur = () => {
      focused.value = false
    }

    const modelValue = props.modelValue.trim()

    if (props.maxLength && modelValue.length > props.maxLength) {
      emit('update:modelValue', modelValue.substring(0, props.maxLength))
    }

    return {
      focused,
      onFocus,
      onBlur,
      onChange,
      onInput,
      onClear,
    }
  },
})
