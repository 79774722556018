import {
  default as disabled,
  IDisabled,
} from '@/components/shared/props/disabled'

export interface IProps extends IDisabled {
  checked: boolean
  value?: string
  label?: string
}

export const DEFAULT_CHECKED = false
export const DEFAULT_VALUE = ''
export const DEFAULT_LABEL_VALUE = ''

export const checked = {
  type: Boolean,
  default: DEFAULT_CHECKED,
}

export const value = {
  type: String,
  default: DEFAULT_VALUE,
}

export const label = {
  type: String,
  default: DEFAULT_LABEL_VALUE,
  required: false,
}

export default {
  checked,
  value,
  disabled,
  label,
}
