import { UserInfo, ErrorInfo } from './types'
export interface State {
  token: string
  refreshToken: string
  userInfo: UserInfo
  smsCode: string
  isError: boolean
  error: ErrorInfo
}

export default (): State => ({
  token: '',
  refreshToken: '',
  userInfo: { user_id: null, phone_number: null, expired_at: null },
  smsCode: '',
  isError: false,
  error: { status: '', message: '' }
})
