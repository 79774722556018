export type { IIdentificator } from './types'

export const DEFAULT_ID_VALUE = ''

export const STORY_SETTINGS = {
  name: 'id',
  type: { name: 'string', required: false },
  table: {
    type: { summary: 'string' },
    defaultValue: { summary: DEFAULT_ID_VALUE },
  },
  control: 'text',
  description: 'Element`s id',
  defaultValue: DEFAULT_ID_VALUE,
}

export default {
  type: String,
  default: DEFAULT_ID_VALUE,
}
