import { ActionTree } from 'vuex'
import { Mutations, CONTACT_MUTATION_TYPES } from './mutations'
import { State } from './state'
import { RootState } from '@/store'
import { baseURL } from '@/plugins/axios'
import { ApiCallConfig, API_ACTION_TYPES } from '../api/actions'
import { ContactInfoResponse, IDevice, IClientsInfo, mockedContactInfoResponse,mockedClientInfoResponse } from './types'
import { GenericActionContext } from '../types'
import { sleep } from '@/helpers/utils'

export enum CONTACT_ACTION_TYPES {
  GET_CONTACT_INFO = 'GET_CONTACT_INFO',
  GET_ONE_CONTACT_INFO = 'GET_ONE_CONTACT_INFO',
  PUT_ONE_CONTACT_INFO = 'PUT_ONE_CONTACT_INFO',
  DELETE_ONE_CONTACT_INFO = 'DELETE_ONE_CONTACT_INFO',
  PATCH_ONE_CONTACT_INFO = 'PATCH_ONE_CONTACT_INFO',
  UPDATE_RESIDENCE = 'UPDATE_RESIDENCE',
  DELETE_RESIDENCE = 'DELETE_RESIDENCE',
  GET_CLIENTS_INFO = 'GET_CLIENTS_INFO',
  ADD_NEW_RESIDENCE = 'ADD_NEW_RESIDENCE',
  ADD_CONTACT_INFO = 'ADD_CONTACT_INFO',
  RESET_ERROR_STATE = 'RESET_ERROR_STATE'
}
type AugmentedActionContext = GenericActionContext<State, Mutations>

enum CONTACT_API_ENDPOINT {
  CONTACTS = '/contacts'
}

export type Actions = {
  [CONTACT_ACTION_TYPES.GET_CONTACT_INFO](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<ContactInfoResponse>
  [CONTACT_ACTION_TYPES.GET_ONE_CONTACT_INFO](
    arg1: AugmentedActionContext,
    id: any,
  ): Promise<ContactInfoResponse>
  [CONTACT_ACTION_TYPES.PUT_ONE_CONTACT_INFO](
    arg1: AugmentedActionContext,
    id: any,
  ): Promise<ContactInfoResponse>
  [CONTACT_ACTION_TYPES.DELETE_ONE_CONTACT_INFO](
    arg1: AugmentedActionContext,
    id: any,
  ): Promise<ContactInfoResponse>
  [CONTACT_ACTION_TYPES.PATCH_ONE_CONTACT_INFO](
    arg1: AugmentedActionContext,
    id: any,
  ): Promise<ContactInfoResponse>
  [CONTACT_ACTION_TYPES.UPDATE_RESIDENCE](
    arg1: AugmentedActionContext,
    payload: IDevice
  ): Promise<unknown>
  [CONTACT_ACTION_TYPES.DELETE_RESIDENCE](
    arg1: AugmentedActionContext,
    id: number
  ): Promise<unknown>
  [CONTACT_ACTION_TYPES.GET_CLIENTS_INFO](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<IClientsInfo>
  [CONTACT_ACTION_TYPES.ADD_NEW_RESIDENCE](
    arg1: AugmentedActionContext,
    residence: unknown
  ): Promise<unknown>
  [CONTACT_ACTION_TYPES.ADD_CONTACT_INFO](
    arg1: AugmentedActionContext,
    payload: any,
  ): Promise<unknown>
  [CONTACT_ACTION_TYPES.RESET_ERROR_STATE](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<unknown>
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [CONTACT_ACTION_TYPES.GET_CONTACT_INFO](
    { dispatch, commit },
    cancelToken
  ) {
    commit(CONTACT_MUTATION_TYPES.SET_LOADING_STATE) // chargement en cours
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL+CONTACT_API_ENDPOINT.CONTACTS,
        requestId: cancelToken,
        method:'GET',
        type: API_ACTION_TYPES.API_CALL,
      })      
      console.log( res["hydra:member"])
      commit(CONTACT_MUTATION_TYPES.SET_CONTACT_INFO, res["hydra:member"])
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async [CONTACT_ACTION_TYPES.GET_ONE_CONTACT_INFO](
    { dispatch, commit }, 
    id,
  ) {
    commit(CONTACT_MUTATION_TYPES.SET_LOADING_STATE) // chargement en cours
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL+CONTACT_API_ENDPOINT.CONTACTS+"/"+id,
        method: 'GET',
        type: API_ACTION_TYPES.API_CALL,
      })      
      commit(CONTACT_MUTATION_TYPES.SET_ONE_CONTACT_INFO, res.contactsInfo)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async [CONTACT_ACTION_TYPES.ADD_CONTACT_INFO](
    { dispatch, commit }, 
    payload,
  ) {
    const { email, firstname, lastname, phoneNumber, id } = payload
    commit(CONTACT_MUTATION_TYPES.SET_LOADING_STATE) // chargement en cours
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL+CONTACT_API_ENDPOINT.CONTACTS,
        method: 'POST',
        data: {
          email,
          firstname,
          lastname,
          phoneNumber,
          userOrgId: "",
          company: "",
          createdBy: "",
          createdAt: "",
          updatedBy: "",
          updatedAt: "",
          deletedBy: "",
          deletedAt: "",
          isActive: true
        },
        type: API_ACTION_TYPES.API_CALL,
      })      
      commit(CONTACT_MUTATION_TYPES.SET_ONE_CONTACT_INFO, res.contactsInfo)
      dispatch(CONTACT_ACTION_TYPES.GET_CONTACT_INFO)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async [CONTACT_ACTION_TYPES.PUT_ONE_CONTACT_INFO](
    { dispatch, commit }, 
    payload,
  ) {
    const { email, firstname, lastname, phoneNumber, id } = payload
    commit(CONTACT_MUTATION_TYPES.SET_LOADING_STATE) // chargement en cours
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL+CONTACT_API_ENDPOINT.CONTACTS+"/"+id,
        method: 'PUT',
        data: {
          email,
          firstname,
          lastname,
          phoneNumber,
          userOrgId: "",
          company: "",
          createdBy: "",
          createdAt: "",
          updatedBy: "",
          updatedAt: "",
          deletedBy: "",
          deletedAt: "",
          isActive: true
        },
        type: API_ACTION_TYPES.API_CALL,
      })      
      commit(CONTACT_MUTATION_TYPES.SET_ONE_CONTACT_INFO, res.contactsInfo)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async [CONTACT_ACTION_TYPES.DELETE_ONE_CONTACT_INFO](
    { dispatch, commit }, 
    payload,
  ) {
    const { id } = payload
    commit(CONTACT_MUTATION_TYPES.SET_LOADING_STATE) // chargement en cours
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL+CONTACT_API_ENDPOINT.CONTACTS+"/"+id,
        method: 'DELETE',
        type: API_ACTION_TYPES.API_CALL,
      })
      commit(CONTACT_MUTATION_TYPES.SET_ONE_CONTACT_INFO, res.contactsInfo)
      dispatch(CONTACT_ACTION_TYPES.GET_CONTACT_INFO)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async [CONTACT_ACTION_TYPES.PATCH_ONE_CONTACT_INFO](
    { dispatch, commit }, 
    payload,
  ) {
    const { email, firstname, lastname, phoneNumber, id, company, userOrganization, updatedBy } = payload
    commit(CONTACT_MUTATION_TYPES.SET_LOADING_STATE) // chargement en cours
    try {
      const res = await dispatch<ApiCallConfig>({
        url: baseURL+CONTACT_API_ENDPOINT.CONTACTS+"/"+id,
        method: 'PATCH',
        data: {
          email,
          firstname,
          lastname,
          phoneNumber,
          userOrganization,
          company,
          updatedBy: "/users/"+updatedBy,
          updatedAt: new Date(),
          isActive: true,
          active: true
        },
        type: API_ACTION_TYPES.API_CALL,
      })
      commit(CONTACT_MUTATION_TYPES.SET_ONE_CONTACT_INFO, res.contactsInfo)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async [CONTACT_ACTION_TYPES.UPDATE_RESIDENCE](
    { dispatch },
    { id, status, parameters }
  ) {
    try {
      const res = await dispatch<ApiCallConfig>({
        url: `devices/${id}`,
        method: 'patch',
        data: { status, parameters },
        type: API_ACTION_TYPES.API_CALL,
      })
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [CONTACT_ACTION_TYPES.DELETE_RESIDENCE]({ dispatch }, id) {
    try {
      const res = await dispatch<ApiCallConfig>({
        url: `devices/${id}`,
        method: 'delete',
        type: API_ACTION_TYPES.API_CALL,
      })
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [CONTACT_ACTION_TYPES.ADD_NEW_RESIDENCE]({ dispatch }, residence) {
    try {
      const res = await dispatch<ApiCallConfig>({
        url: `devices/new-residence`,
        method: 'POST',
        data: residence,
        type: API_ACTION_TYPES.API_CALL,
      })
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [CONTACT_ACTION_TYPES.GET_CLIENTS_INFO](
    { dispatch, commit },
    cancelToken
  ) {
    commit(CONTACT_MUTATION_TYPES.SET_LOADING_STATE)
    try {      
      await sleep(1500)
      const res = mockedClientInfoResponse
      //  await dispatch<ApiCallConfig>({
      //   url: 'clients',
      //   requestId: cancelToken,
      //   type: API_ACTION_TYPES.API_CALL,
      // })
      commit(CONTACT_MUTATION_TYPES.SET_CLIENTS_INFO, res.clientsInfo)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [CONTACT_MUTATION_TYPES.RESET_ERROR_STATE](
    { dispatch, commit }
  ) {
    commit(CONTACT_MUTATION_TYPES.RESET_ERROR_STATE)
    return Promise.resolve()
  },
}
