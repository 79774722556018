export type { IPrefix } from './types'

export const DEFAULT_PREFIX = 'q'

export const STORY_SETTINGS = {
  name: 'prefix',
  type: { name: 'string', required: false },
  table: {
    type: { summary: 'string' },
    defaultValue: { summary: DEFAULT_PREFIX },
  },
  control: 'text',
  description: 'Prefix prop',
  defaultValue: DEFAULT_PREFIX,
}

export default {
  type: String,
  default: DEFAULT_PREFIX,
}
