export type { IOutline } from './types'

export const DEFAULT_OUTLINE_VALUE = false

export const STORY_SETTINGS = {
  name: 'outline',
  type: { name: 'boolean', required: false },
  table: {
    type: { summary: 'boolean' },
    defaultValue: { summary: DEFAULT_OUTLINE_VALUE },
  },
  control: 'boolean',
  description: 'Alternative outlined design',
  defaultValue: DEFAULT_OUTLINE_VALUE,
}

export default {
  type: Boolean,
  default: DEFAULT_OUTLINE_VALUE,
  required: false,
}
