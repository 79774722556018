import { defineComponent, inject } from 'vue'
import { DEFAULT_PREFIX } from '@/components/shared/constants/global'
import { default as props, IProps } from './props'

export default defineComponent({
  name: 'QNativeSelect',
  props,
  emits: ['input', 'change', 'update:modelValue'],
  setup(props: IProps, { emit }) {
    const prefix = inject('prefix', DEFAULT_PREFIX)

    const emitValue = (
      eventName: 'input' | 'change' | 'update:modelValue',
      event: { target: HTMLSelectElement }
    ) => {
      const value = event.target.value
      const isNan = Number.isNaN(Number(value))

      emit(eventName, isNan ? value : Number(value))
    }

    const getValue = (item: any) => {
      if (typeof item === 'object' && typeof props.keyValue === 'string')
        return item[props.keyValue]
      return item
    }

    return {
      prefix,
      emitValue,
      getValue,
    }
  },
})
