import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-00293956"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]

export function render(_ctx, _cache) {
  const _component_UiIcon = _resolveComponent("UiIcon")

  return (_openBlock(), _createElementBlock("button", {
    disabled: _ctx.disabled || _ctx.loading,
    class: _normalizeClass([
    `ui-button`,
    { [`ui-button--icon`]: _ctx.icon || false },
    { [`ui-button--plain`]: _ctx.plain || false },
    { [`ui-button--loading`]: _ctx.loading || false },
    { [`ui-button--outline`]: _ctx.outline || false },
    `rounded-${_ctx.rounded}`
  ]),
    onClick: _cache[0] || (_cache[0] = $event => (_ctx.$emit('click', $event)))
  }, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        _withDirectives(_createVNode(_component_UiIcon, {
          name: "loading",
          class: "ui-button__spinner"
        }, null, 512), [
          [_vShow, _ctx.loading]
        ])
      ]),
      _: 1
    }),
    _renderSlot(_ctx.$slots, "default", {}, () => [
      _createTextVNode(_toDisplayString(_ctx.label), 1)
    ], true)
  ], 10, _hoisted_1))
}