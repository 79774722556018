export type { IReadonly } from './types'

export const DEFAULT_READONLY_VALUE = false

export const STORY_SETTINGS = {
  name: 'readonly',
  type: { name: 'boolean', required: false },
  table: {
    type: { summary: 'boolean' },
    defaultValue: { summary: DEFAULT_READONLY_VALUE },
  },
  control: 'boolean',
  description: 'Indicates that the user cannot modify the value of the control',
  defaultValue: DEFAULT_READONLY_VALUE,
}

export default {
  type: Boolean,
  default: DEFAULT_READONLY_VALUE,
}
