import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-27bb3e16"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = { class: "ui-text-area__field" }
const _hoisted_3 = ["value", "name", "id", "disabled", "autocomplete", "autofocus", "placeholder", "readonly", "maxlength"]

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
  `ui-text-area`,
  {[`ui-text-area--resize`]: _ctx.resize},
  {[`ui-text-area--outline`]: _ctx.outline},
  {[`ui-text-area--focused`]: _ctx.focused},
  
  ])
  }, [
    _createElementVNode("label", {
      class: "ui-text-area__label",
      for: _ctx.id
    }, [
      _renderSlot(_ctx.$slots, "label", {}, () => [
        _createTextVNode(_toDisplayString(_ctx.label), 1)
      ], true)
    ], 8, _hoisted_1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("textarea", _mergeProps({
        class: {[`ui-text-area--disabled`]: _ctx.disabled},
        value: _ctx.modelValue,
        name: _ctx.name,
        id: _ctx.id,
        disabled: _ctx.disabled,
        autocomplete: _ctx.autocomplete,
        autofocus: _ctx.autofocus,
        placeholder: _ctx.placeholder,
        readonly: _ctx.readonly,
        maxlength: _ctx.maxLength ? _ctx.maxLength : -1
      }, _ctx.$attrs, {
        onChange: _cache[0] || (_cache[0] = (...args) => (_ctx.onChange && _ctx.onChange(...args))),
        onInput: _cache[1] || (_cache[1] = (...args) => (_ctx.onInput && _ctx.onInput(...args))),
        onFocus: _cache[2] || (_cache[2] = (...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
        onBlur: _cache[3] || (_cache[3] = (...args) => (_ctx.onBlur && _ctx.onBlur(...args)))
      }), null, 16, _hoisted_3)
    ])
  ], 2))
}