import disabled from '@/components/shared/props/disabled'
import loading from '@/components/shared/props/loading'
import outline from '@/components/shared/props/outline'

import type { IDisabled } from '@/components/shared/props/disabled'
import type { ILoading } from '@/components/shared/props/loading'
import type { IOutline } from '@/components/shared/props/outline'

export interface IProps extends IDisabled, ILoading, IOutline {
  icon: boolean
  plain: boolean
  rounded?: string
}

export const DEFAULT_ICON_VALUE = false
export const DEFAULT_PLAIN_VALUE = true
export const DEFAULT_ROUNDED_VALUE = 'lg'
export const DEFAULT_SQUARE_VALUE = false
export const DEFAULT_LABEL_VALUE = ''

export const icon = {
  type: Boolean,
  default: DEFAULT_ICON_VALUE,
}

export const plain = {
  type: Boolean,
  default: DEFAULT_PLAIN_VALUE,
}

export const rounded = {
  type: String,
  required: false,
  default: DEFAULT_ROUNDED_VALUE,
}

export const label = {
  type: String,
  required: false,
  default: DEFAULT_LABEL_VALUE,
}

export default {
  disabled,
  loading,
  icon,
  plain,
  rounded,
  outline,
  label,
}
