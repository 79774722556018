import name from '@/components/shared/props/name'
import { IName } from '@/components/shared/props/name/types'
export const SIZE_DEFAULT_VALUE = 'icon-sm'
export const TITLE_DEFAULT_VALUE = ''
export interface IProps extends IName {
  size?: string
  title?: string
}

export const size = {
  type: String,
  default: SIZE_DEFAULT_VALUE,
  required: false,
}

export const title = {
  type: String,
  default: TITLE_DEFAULT_VALUE,
  required: false,
}

export default {
  name,
  size,
  title,
}
