import { ActionTree } from 'vuex'
import { Mutations, MONITORING_MUTATION_TYPES } from './mutations'
import { State } from './state'
import { RootState } from '@/store'
import { ApiCallConfig, API_ACTION_TYPES } from '../api/actions'
import {
  mockedMonitoringInfoResponse,
  MonitoringInfoResponse,
  MonitoringDevicesResponse,
} from './types'
import { GenericActionContext } from '../types'
import { sleep } from '@/helpers/utils'

export enum MONITORING_ACTION_TYPES {
  GET_MONITORING_INFO = 'GET_MONITORING_INFO',
  GET_DEVICES = 'GET_DEVICES',
}
type AugmentedActionContext = GenericActionContext<State, Mutations>

export type Actions = {
  [MONITORING_ACTION_TYPES.GET_MONITORING_INFO](
    arg1: AugmentedActionContext,
    cancelToken: string
  ): Promise<MonitoringInfoResponse>
  [MONITORING_ACTION_TYPES.GET_DEVICES](
    { commit }: AugmentedActionContext,
    payload: string
  ): Promise<MonitoringDevicesResponse>
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [MONITORING_ACTION_TYPES.GET_MONITORING_INFO]({ commit }) {
    commit(MONITORING_MUTATION_TYPES.SET_LOADING_STATE)
    try {
      await sleep(1500)
      const res = mockedMonitoringInfoResponse
      commit(MONITORING_MUTATION_TYPES.SET_MONITORING_INFO, res.monitoringInfo)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [MONITORING_ACTION_TYPES.GET_DEVICES](
    { dispatch, commit },
    cancelToken
  ) {
    commit(MONITORING_MUTATION_TYPES.SET_LOADING_STATE)
    try {
      const data = await dispatch<ApiCallConfig>({
        url: 'devices',
        requestId: cancelToken,
        type: API_ACTION_TYPES.API_CALL,
      })
      commit(MONITORING_MUTATION_TYPES.SET_DEVICES, data)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
}
