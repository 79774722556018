import { defineComponent, computed, watch } from 'vue'
import { default as props, IProps } from './props'

import QButton from '../Button/Button.vue'

export default defineComponent({
  components: {
    QButton,
  },
  props,
  emits: ['update:modelValue'],
  setup(props: IProps, { emit }) {
    const currentPage = computed({
      get(): number {
        return props.modelValue
      },
      set(value: number): void {
        if (props.disabled) {
          return
        }

        emit('update:modelValue', value)
      },
    })

    const pagesNumber = computed(() =>
      Math.ceil(props.itemsLength / props.perPage)
    )

    const startingPageNumber = computed(() => {
      if (currentPage.value <= 4) return 0
      if (currentPage.value >= pagesNumber.value - 2)
        return pagesNumber.value - 5
      return currentPage.value - 3
    })

    watch(
      () => pagesNumber.value,
      (newVal) => {
        if (newVal < currentPage.value)
          emit('update:modelValue', pagesNumber.value)
      }
    )

    return {
      currentPage,
      pagesNumber,
      startingPageNumber,
    }
  },
})
