import {
  default as nativeProps,
  IProps as INativeProps,
} from '../NativeSelect/props'
import { default as native, INative } from '@/components/shared/props/native'

export interface IProps extends INativeProps, INative {}

export default {
  ...nativeProps,
  native,
}
