import {
  DARK_THEME,
  LIGHT_THEME,
} from '@/components/shared/props/theme/settings'

export { LIGHT_THEME as DEFAULT_THEME }
export { DEFAULT_PREFIX } from '@/components/shared/props/prefix/index'

export const DEFAULT_BOOLEAN_MODEL_VALUE = false

export const TEXT_THEMES_COLOR = {
  [LIGHT_THEME]: '#000',
  [DARK_THEME]: '#fff',
}
