import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { store, storeKey } from './store'
import './assets/scss/tailwind.scss'
import i18n from './plugins/i18n'
import installAxios from './plugins/axios'
import UiKit from './plugins/uiKit'
import './plugins/veeValidate'

createApp(App)
  .use(store, storeKey)
  .use(router)
  .use(UiKit, { prefix: 'Ui' })
  .use(installAxios)
  .use(i18n)
  .mount('#app')
