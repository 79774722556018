import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-48cd70f6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "ui-input__label"
}
const _hoisted_2 = { class: "whitespace-nowrap" }
const _hoisted_3 = {
  key: 0,
  class: "inline-block text-red"
}
const _hoisted_4 = {
  key: 0,
  class: "ui-input__error-message"
}
const _hoisted_5 = ["autofocus", "disabled", "name", "id"]
const _hoisted_6 = {
  key: 0,
  value: "",
  selected: ""
}
const _hoisted_7 = ["value", "selected"]

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.label || _ctx.$slots.label)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "label", {}, () => [
              _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
              (_ctx.required)
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, "*"))
                : _createCommentVNode("", true)
            ], true)
          ]),
          (!_ctx.noErrorField)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_Transition, { name: "fade" }, {
                  default: _withCtx(() => [
                    _withDirectives(_createElementVNode("span", null, [
                      _renderSlot(_ctx.$slots, "error", { errorMessage: _ctx.errorMessage }, () => [
                        _createTextVNode(_toDisplayString(_ctx.errorMessage), 1)
                      ], true)
                    ], 512), [
                      [_vShow, _ctx.errorMessage]
                    ])
                  ]),
                  _: 3
                })
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass([`${_ctx.prefix}-native-select-wrapper`])
    }, [
      _createElementVNode("select", {
        class: _normalizeClass([`${_ctx.prefix}-native-select`]),
        autofocus: _ctx.autofocus,
        disabled: _ctx.disabled,
        name: _ctx.name,
        id: _ctx.id,
        onInput: _cache[0] || (_cache[0] = $event => (_ctx.emitValue('input', $event))),
        onChange: _cache[1] || (_cache[1] = $event => {_ctx.emitValue('change', $event); _ctx.emitValue('update:modelValue', $event)})
      }, [
        (_ctx.placeholder)
          ? (_openBlock(), _createElementBlock("option", _hoisted_6, _toDisplayString(_ctx.placeholder), 1))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
          return (_openBlock(), _createElementBlock("option", {
            key: _ctx.getValue(option),
            value: _ctx.getValue(option),
            selected: _ctx.getValue(option) === _ctx.getValue(_ctx.modelValue)
          }, _toDisplayString(typeof(option) === 'object' ? option[_ctx.keyName] : option), 9, _hoisted_7))
        }), 128))
      ], 42, _hoisted_5)
    ], 2)
  ]))
}