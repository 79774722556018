import { SpacesItem } from './types'
import { IContact } from '@/store/contact/types'
import { ICompany } from '@/store/company/types'

export interface State {
  managerFilter: IContact[]
  guardianFilter: IContact[]
  companyFilter: ICompany[]
  residenceFilter: SpacesItem[]
  residenceList: SpacesItem[]
  spaces: SpacesItem[]
  isLoaded: boolean
}

export default (): State => ({
  managerFilter: [],
  guardianFilter: [],
  companyFilter: [],
  residenceFilter: [],
  residenceList: [],
  spaces: [],
  isLoaded: false,
})
