export type { IAutofocus } from './types'

export const DEFAULT_AUTOFOCUS_VALUE = false

export const STORY_SETTINGS = {
  name: 'autofocus',
  type: { name: 'boolean', required: false },
  table: {
    type: { summary: 'boolean' },
    defaultValue: { summary: DEFAULT_AUTOFOCUS_VALUE },
  },
  control: 'boolean',
  description:
    'Specify that a form control should have input focus when the page loads',
  defaultValue: DEFAULT_AUTOFOCUS_VALUE,
}

export default {
  type: Boolean,
  default: DEFAULT_AUTOFOCUS_VALUE,
}
