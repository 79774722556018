import { defineComponent, inject, computed } from 'vue'
import {
  DEFAULT_PREFIX,
  DEFAULT_THEME,
} from '@/components/shared/constants/global'
import QNativeSelect from '../NativeSelect/NativeSelect.vue'
import QCustomSelect from '../CustomSelect/CustomSelect.vue'
import { default as props, IProps } from './props'

export default defineComponent({
  components: { QNativeSelect, QCustomSelect },
  props,
  setup(props: IProps) {
    const prefix = inject('prefix', DEFAULT_PREFIX)
    const theme = inject('theme', DEFAULT_THEME)

    const selectComponent = computed(() =>
      props.native ? QNativeSelect : QCustomSelect
    )

    return {
      prefix,
      theme,
      selectComponent,
    }
  },
})
