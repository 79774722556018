import { UsersItem, PersonalInfo, ErrorInfo } from './types'

export interface State {
  users: UsersItem[]
  isLoaded: boolean
  isError: boolean
  personalInfo: PersonalInfo
  error: ErrorInfo
}

export default (): State => ({
  users: [],
  personalInfo: { id: "string",
  lastname: "string",
  firstname: "string",
  login: "string",
  email: "string",
  phoneNumber: "string",
  roles: [""],
  userOrganization: "string" },
  isLoaded: false,
  isError: false,
  error: { status: '', message: '' }
})
