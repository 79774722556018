import { default as disabled, IDisabled } from '../../shared/props/disabled'

export interface IProps extends IDisabled {
  modelValue: number
  itemsLength: number
  perPage: number
  pages?: number
}

export const DEFAULT_ITEMS_LENGTH_VALUE = 0
export const DEFAULT_PER_PAGE_VALUE = 10
export const DEFAULT_MODEL_VALUE_VALUE = 1
export const DEFAULT_PAGES_VALUE = 0

export const ITEMS_LENGTH_STORY_SETTINGS = {
  name: 'itemsLength',
  type: { name: 'number', required: true },
  table: {
    type: { summary: 'number' },
    defaultValue: DEFAULT_ITEMS_LENGTH_VALUE,
  },
  description: 'Paginated items length',
  defaultValue: DEFAULT_ITEMS_LENGTH_VALUE,
}

export const PER_PAGE_STORY_SETTINGS = {
  name: 'perPage',
  type: { name: 'number', required: false },
  table: {
    type: { summary: 'number' },
    defaultValue: DEFAULT_PER_PAGE_VALUE,
  },
  description: 'per page items count',
  defaultValue: DEFAULT_PER_PAGE_VALUE,
}

export const MODEL_VALUE_STORY_SETTINGS = {
  name: 'modelValue',
  type: { name: 'number', required: true },
  table: {
    type: { summary: 'number' },
    defaultValue: DEFAULT_MODEL_VALUE_VALUE,
  },
  description: 'Current page number',
  defaultValue: DEFAULT_MODEL_VALUE_VALUE,
}

const modelValue = {
  type: Number,
  default: DEFAULT_MODEL_VALUE_VALUE,
  required: true,
}

const itemsLength = {
  type: Number,
  default: DEFAULT_ITEMS_LENGTH_VALUE,
  required: true,
}

const perPage = {
  type: Number,
  default: DEFAULT_PER_PAGE_VALUE,
}

export default {
  modelValue,
  itemsLength,
  perPage,
  disabled,
}
