export type { IKeyName } from './types'

export const DEFAULT_KEY_NAME_VALUE = 'label'

export const STORY_SETTINGS = {
  name: 'keyName',
  type: { name: 'string', required: false },
  table: {
    type: { summary: 'string' },
    defaultValue: { summary: DEFAULT_KEY_NAME_VALUE },
  },
  control: 'text',
  description: 'The name key in the options object',
  defaultValue: DEFAULT_KEY_NAME_VALUE,
}

export default {
  type: String,
  default: DEFAULT_KEY_NAME_VALUE,
}
