import { mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b6cc956"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["checked", "value", "disabled"]

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("input", _mergeProps({
    type: "checkbox",
    checked: _ctx.checked,
    value: _ctx.value,
    disabled: _ctx.disabled,
    class: [
    `ui-native-checkbox`,
  ]
  }, _ctx.$attrs, {
    onChange: _cache[0] || (_cache[0] = $event => {_ctx.$emit('update:checked', $event.target.checked); _ctx.$emit('change', $event.target.checked)})
  }), null, 16, _hoisted_1))
}