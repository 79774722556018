export default {
  "LOGIN_FORM_LOGIN_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "LOGIN_FORM_PASSWORD_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
  "LOGIN_FORM_SUBMIT_BUTTON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
  "LOGIN_FORM_FORGET_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié ?"])},
  "FORGET_PASSWORD_FORM_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié"])},
  "FORGET_PASSWORD_FORM_SENT_SMS_TEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour votre sécurité, Smarties va vous envoyer un code de sécurité à 6 chiffres par SMS à votre numéro de téléphone terminant"])},
  "FORGET_PASSWORD_FORM_LINK_RESEND_SMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code non reçu ?"])},
  "FORGET_PASSWORD_INSERT_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir votre identifiant afin de réinitialiser votre mot de passe"])},
  "FORGET_PASSWORD_INSERT_SMS_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir le code à 6 chiffres reçu par SMS"])},
  "FORGET_PASSWORD_NEW_PASSWORD_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez votre nouveau mot de passe"])},
  "FORGET_PASSWORD_NEW_PASSWORD_OLD_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe"])},
  "FORGET_PASSWORD_NEW_PASSWORD_NEW_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation du nouveau mot de passe"])},
  "FORGET_PASSWORD_FORM_SUBMIT_BUTTON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])},
  "FORGET_PASSWORD_FORM_RETURN_BUTTON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
  "PASSWORD_REQUIREMENTS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre mot de passe doit contenir au moins :"])},
  "PASSWORD_REQUIREMENTS_ENOUGH_LETTERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10 caractères"])},
  "PASSWORD_REQUIREMENTS_ENOUGH_CAPITAL_LETTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 majuscule"])},
  "PASSWORD_REQUIREMENTS_ENOUGH_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 chiffre"])},
  "PASSWORD_REQUIREMENTS_ENOUGH_SPECIAL_SYMBOL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 caractère spécial"])},
  "PASSWORD_REQUIREMENTS_PASSWORDS_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mots de passe se correspondent"])},
  "HEADER_LINK_TITLE_REAL_ESTATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parc immobilier"])},
  "HEADER_LINK_DESCRIPTION_REAL_ESTATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accédez à toutes les informations de vos immeubles et ajoutez de nouveaux immeubles"])},
  "HEADER_LINK_TITLE_ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramétrage objets"])},
  "HEADER_LINK_DESCRIPTION_ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurez les alertes et validez les OS en cours de votre parc immobilier"])},
  "HEADER_LINK_TITLE_MONITORING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monitoring objets"])},
  "HEADER_LINK_DESCRIPTION_MONITORING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accédez à votre parc d’objets connectés et surveillez vos objets"])},
  "HEADER_LINK_TITLE_CONTACT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "HEADER_LINK_DESCRIPTION_CONTACT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accédez à votre parc d’objets connectés et surveillez vos objets"])},
  "FILTER_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer"])},
  "FILTER_INPUT_PLACEHOLDER_RESIDENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résidences"])},
  "FILTER_INPUT_PLACEHOLDER_OBJECTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d'objets"])},
  "FILTER_INPUT_PLACEHOLDER_CLIENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Géré par"])},
  "FILTER_INPUT_PLACEHOLDER_STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
  "FILTER_INPUT_PLACEHOLDER_BATTERYS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piles"])},
  "FILTER_INPUT_PLACEHOLDER_BUILDINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batiments"])},
  "FILTER_INPUT_PLACEHOLDER_STATES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etat temps réel de l’appareillage"])},
  "FILTER_INPUT_PLACEHOLDER_GUARDIAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gardien"])},
  "FILTER_INPUT_PLACEHOLDER_MANAGER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestionnaire"])},
  "FILTER_INPUT_BUTTON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer"])},
  "FILTER_COUNTER_NUMBER_OF_INTERVENTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d’intervention en cours"])},
  "FILTER_COUNTER_NUMBER_OF_AWAITING_INTERVENTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d’objets en attente d’interventions"])},
  "FILTER_COUNTER_AVERAGE_TIME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Délai moyen de résolution"])},
  "LOADING_SPINNER_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading"])},
  "DASHBOARD_TABLE_HEADER_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "DASHBOARD_TABLE_HEADER_STATE_SENSOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["État temps réel du capteur"])},
  "DASHBOARD_TABLE_HEADER_TIME_STAMP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Début incident"])},
  "DASHBOARD_TABLE_HEADER_RESOLUTION_STAMP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin incident"])},
  "DASHBOARD_TABLE_HEADER_LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référence"])},
  "DASHBOARD_TABLE_HEADER_CLIENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer par"])},
  "DASHBOARD_TABLE_HEADER_RESIDENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résidence"])},
  "DASHBOARD_TABLE_HEADER_BUILDING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bâtiment"])},
  "DASHBOARD_TABLE_HEADER_STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "DASHBOARD_TABLE_HEADER_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N°OS"])},
  "DASHBOARD_TABLE_SHOW_ROWS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher"])},
  "DASHBOARD_TABLE_SHOW_ROWS_OF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sur"])},
  "DASHBOARD_TABLE_HIDDEN_INFO_ADDITIONAL_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations complémentaires"])},
  "DASHBOARD_TABLE_HIDDEN_INFO_OPENING_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouverture du skydome"])},
  "DASHBOARD_TABLE_HIDDEN_INFO_ADD_OS_BUTTON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter le numéro d’OS"])},
  "DASHBOARD_TABLE_HIDDEN_INFO_NOTES_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
  "DASHBOARD_TABLE_HIDDEN_INFO_ADD_NOTE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une note"])},
  "DASHBOARD_TABLE_POP_UP_ADD_OS_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N° d’os"])},
  "DASHBOARD_TABLE_POP_UP_ADD_OS_CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
  "DASHBOARD_TABLE_POP_UP_ADD_OS_CONFIRM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider"])},
  "DASHBOARD_TABLE_POP_UP_ADD_OS_CHANGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
  "DASHBOARD_TABLE_POP_UP_ADD_OS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter le n° d’OS"])},
  "DASHBOARD_TABLE_POP_UP_CHANGE_OS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le n° d’OS"])},
  "DASHBOARD_TABLE_STATUS_SENT_TO_PROVIDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyé au prestataire"])},
  "DASHBOARD_TABLE_STATUS_SENT_TO_MANAGER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyé au gestionnaire"])},
  "DASHBOARD_TABLE_STATUS_READY_TO_SEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bon à envoyer"])},
  "DASHBOARD_TABLE_STATUS_TRANSFERED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alerte transmise"])},
  "DASHBOARD_TABLE_STATUS_COMPLETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminé"])},
  "DASHBOARD_TABLE_STATUS_CANCELLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulé"])},
  "TABLE_INPUT_SEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher un objet, un client ou une résidence…"])},
  "TABLE_DEFAULT_NO_FILTERED_DATA_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de correspondance"])},
  "TABLE_DEFAULT_NO_DATA_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun enregistrement trouvé"])},
  "MONITORING_REFRESH_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualiser les données"])},
  "MONITORING_TABLE_HEADER_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "MONITORING_TABLE_HEADER_STAIRWELL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cage d'escalier"])},
  "MONITORING_TABLE_HEADER_TOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
  "MONITORING_TABLE_HEADER_SYNCHRONIZATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synchro"])},
  "MONITORING_TABLE_HEADER_CLIENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Géré par"])},
  "MONITORING_TABLE_HEADER_RESIDENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résidence"])},
  "MONITORING_TABLE_HEADER_BUILDING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bât."])},
  "MONITORING_TABLE_HEADER_FLOOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étage"])},
  "MONITORING_TABLE_HEADER_STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "MONITORING_TABLE_HEADER_BATTERY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piles"])},
  "MONITORING_TABLE_HEADER_REALTIMESTATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etat en temps réel de l'appareillage"])},
  "MONITORING_TABLE_HIDDEN_INFO_LAST_ALERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière alerte"])},
  "MONITORING_TABLE_HIDDEN_INFO_SITE_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code du site"])},
  "MONITORING_TABLE_HIDDEN_INFO_POSTAL_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal"])},
  "MONITORING_TABLE_HIDDEN_INFO_OBJECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objet"])},
  "CONTACT_BUTTON_NEW_ITEM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un contact"])},
  "CONTACT_MODAL_ADMIN_HEADER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un gestionnaire"])},
  "CONTACT_MODAL_GUARDIAN_HEADER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un gardien"])},
  "CONTACT_MODAL_TECH_HEADER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un technicien"])},
  "CONTACT_MODAL_COMPANY_HEADER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une entreprise"])},
  "CONTACT_MODAL_ORGANISM_HEADER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un organisme"])},
  "CONTACT_MODAL_CONTENT_FIRSTNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
  "CONTACT_MODAL_CONTENT_LASTNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
  "CONTACT_MODAL_CONTENT_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "CONTACT_MODAL_CONTENT_PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
  "CONTACT_MODAL_CONTENT_P": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un espace va être créé pour ce gestionnaire pour la webapp, il recevra ses identifiants par mail ainsi que vos coordonnées pour d’éventuelles informations complémentaires"])},
  "CONTACT_MODAL_BUTTONS_CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
  "CONTACT_MODAL_BUTTONS_SUBMIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider"])},
  "CONTACT_TABLE_HEADER_FIRSTNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
  "CONTACT_TABLE_HEADER_LESSOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bailleur"])},
  "CONTACT_TABLE_HEADER_LASTNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
  "CONTACT_TABLE_HEADER_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail"])},
  "CONTACT_TABLE_HEADER_ADD_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouté le"])},
  "CONTACT_TABLE_HEADER_PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
  "CONTACT_MY_INFORMATION_NAME_FIELD_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
  "CONTACT_MY_INFORMATION_SURNAME_FIELD_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
  "CONTACT_MY_INFORMATION_EMAIL_FIELD_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "CONTACT_MY_INFORMATION_PHONE_FIELD_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
  "SETTINGS_MY_INFORMATION_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes informations"])},
  "SETTINGS_PASSWORD_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
  "SETTINGS_USERS_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs"])},
  "SETTINGS_MY_INFORMATION_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes informations personnelles"])},
  "SETTINGS_MY_INFORMATION_ROLE_ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrateur"])},
  "SETTINGS_MY_INFORMATION_ROLE_USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur"])},
  "SETTINGS_MY_INFORMATION_NAME_FIELD_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
  "SETTINGS_MY_INFORMATION_SURNAME_FIELD_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
  "SETTINGS_MY_INFORMATION_EMAIL_FIELD_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email (identifiant)"])},
  "SETTINGS_MY_INFORMATION_PHONE_FIELD_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
  "SETTINGS_CONFIRMATION_BUTTON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer les modifications"])},
  "SETTINGS_MY_PASSWORD_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier mon mot de passe"])},
  "SETTINGS_MY_PASSWORD_OLD_PASSWORD_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe actuel"])},
  "SETTINGS_MY_PASSWORD_NEW_PASSWORD_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe"])},
  "SETTINGS_MY_PASSWORD_NEW_PASSWORD_AGAIN_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer le nouveau mot de passe"])},
  "SETTINGS_USERS_DROPDOWN_BUTTON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un utilisateur"])},
  "SETTINGS_USERS_UPDATE_BUTTON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier un utilisateur"])},
  "SETTINGS_USERS_ROLE_USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur"])},
  "SETTINGS_USERS_ROLE_ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrateur"])},
  "SETTINGS_USERS_DROPDOWN_MANUAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuellement"])},
  "SETTINGS_USERS_DROPDOWN_CSV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À partir d’un fichier .csv"])},
  "SETTINGS_USERS_MODAL_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En tant que Super Admin, chaque utilisateur que vous ajouterez sera défini comme contributeur"])},
  "MONITORING_CHART_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Équipements fonctionnels"])},
  "MONITORING_CHART_OK_STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
  "MONITORING_CHART_KO_STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KO"])},
  "MONITORING_CHART_KO_STATUS_SUB_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ de 24h"])},
  "MONITORING_CHART_KO_STATUS_SUB_TITLE_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entre 12 et 24h"])},
  "MONITORING_CHART_BAD_STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non appairé"])},
  "MONITORING_CHART_SKYDOME_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouverture du skydome"])},
  "MONITORING_CHART_RAT_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispositif rat"])},
  "MONITORING_CHART_VMC_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VMC"])},
  "MONITORING_CHART_PUMP_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pompe de relevage"])},
  "REAL_EASTATE_BUTTON_NEW_ITEM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un bâtiment"])},
  "REAL_ESTATE_TABLE_HEADER_CLIENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Géré par"])},
  "REAL_ESTATE_TABLE_HEADER_RESIDENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résidence"])},
  "REAL_ESTATE_TABLE_HEADER_BUILD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bâtiment"])},
  "REAL_ESTATE_TABLE_HEADER_POSTAL_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal"])},
  "REAL_ESTATE_TABLE_HEADER_POSTAL_CITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
  "REAL_ESTATE_MODAL_HEADER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un bâtiment à votre parc immobilier"])},
  "REAL_ESTATE_UPDATE_RESIDENCE_MODAL_HEADER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modification de la résidence"])},
  "REAL_ESTATE_UPDATE_BUILDING_MODAL_HEADER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modification du batiment"])},
  "REAL_ESTATE_MODAL_CONTENT_MAIN_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations générales"])},
  "REAL_ESTATE_MODAL_CONTENT_MAIN_LABEL_CLIENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Géré par"])},
  "REAL_ESTATE_MODAL_CONTENT_MAIN_LABEL_RESIDENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la résidence"])},
  "REAL_ESTATE_MODAL_CONTENT_MAIN_LABEL_BUILDING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du bâtiment"])},
  "REAL_ESTATE_MODAL_CONTENT_FLOOR_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etage min"])},
  "REAL_ESTATE_MODAL_CONTENT_FLOOR_MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etage max"])},
  "REAL_ESTATE_MODAL_CONTENT_STAIRCASE_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cage d'escalier min"])},
  "REAL_ESTATE_MODAL_CONTENT_STAIRCASE_MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cage d'escalier max"])},
  "REAL_ESTATE_MODAL_CONTENT_LOCATION_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localisation"])},
  "REAL_ESTATE_MODAL_CONTENT_IS_ADDRESS_DIFFERENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse différente de la résidence"])},
  "REAL_ESTATE_MODAL_CONTENT_LOCATION_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
  "REAL_ESTATE_MODAL_CONTENT_LOCATION_POSTALCODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal"])},
  "REAL_ESTATE_MODAL_CONTENT_LOCATION_CITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
  "REAL_ESTATE_MODAL_BUTTONS_CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
  "REAL_ESTATE_MODAL_BUTTONS_SUBMIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider"])},
  "REAL_ESTATE_ADDITIONAL_LOCATION_BLOCK_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localisation"])},
  "REAL_ESTATE_ADDITIONAL_LOCATION_BLOCK_LABEL_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
  "REAL_ESTATE_ADDITIONAL_LOCATION_BLOCK_LABEL_CODEPOSTAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal"])},
  "REAL_ESTATE_ADDITIONAL_LOCATION_BLOCK_LABEL_CITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
  "REAL_ESTATE_ADDITIONAL_ACCESS_MORNING_BLOCK_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès"])},
  "REAL_ESTATE_ADDITIONAL_ACCESS_MORNING_BLOCK_LABEL_MORNING_CODE_01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code porte 01"])},
  "REAL_ESTATE_ADDITIONAL_ACCESS_MORNING_BLOCK_LABEL_MORNING_CODE_02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code porte 02"])},
  "REAL_ESTATE_ADDITIONAL_ACCESS_NIGHT_BLOCK_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès nuit"])},
  "REAL_ESTATE_ADDITIONAL_ACCESS_MORNING_BLOCK_LABEL_NIGHT_CODE_01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code porte 01"])},
  "REAL_ESTATE_ADDITIONAL_ACCESS_MORNING_BLOCK_LABEL_NIGHT_CODE_02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code porte 02"])},
  "REAL_ESTATE_ADDITIONAL_DETAILS_BLOCK_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordonnées du gestionnaire"])},
  "REAL_ESTATE_GUARDIAN_DETAILS_BLOCK_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordonnées du gardien"])},
  "USER_SETTINGS_TABLE_HEADER_SURNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
  "USER_SETTINGS_TABLE_HEADER_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
  "USER_SETTINGS_TABLE_HEADER_PROFILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
  "USER_SETTINGS_TABLE_HEADER_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "USER_SETTINGS_TABLE_HEADER_PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
  "ACTIVITY_ADD_OBJECT_BUTTON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un objet"])},
  "ACTIVITY_TABLE_HEADER_CLIENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Géré par"])},
  "ACTIVITY_TABLE_HEADER_RESIDENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résidence"])},
  "ACTIVITY_TABLE_HEADER_POSTAL_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CP"])},
  "ACTIVITY_TABLE_HEADER_TOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
  "ACTIVITY_TABLE_HEADER_BUILD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bât."])},
  "ACTIVITY_TABLE_HEADER_FLOOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etages"])},
  "ACTIVITY_TABLE_HEADER_OBJECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usage"])},
  "ACTIVITY_TABLE_HEADER_UNDER_CONTRACT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation bailleur"])},
  "ACTIVITY_TABLE_HEADER_LOCALLY_VERIFIED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérif locale"])},
  "ACTIVITY_TABLE_HEADER_OS_NUMBER_OBLIGATORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N° d’OS obligatoire"])},
  "ACTIVITY_TABLE_HEADER_TIME_BEFORE_INTERVENTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Délai"])},
  "ACTIVITY_TABLE_HEADER_INFO_FOR_MANAGER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info pour gestionnaire"])},
  "ACTIVITY_TABLE_YES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
  "ACTIVITY_TABLE_NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
  "ACTIVITY_TABLE_IMMEDIATELY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immédiat"])},
  "ACTIVITY_TABLE_NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Y/n"])},
  "ACTIVITY_TABLE_MODAL_HEADER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les informations de"])},
  "ACTIVITY_TABLE_MODAL_TITLE_GENERAL_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations générales"])},
  "ACTIVITY_TABLE_MODAL_TITLE_CONFIGURATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration"])},
  "ACTIVITY_TABLE_MODAL_HELPER_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment configurez un objet Sigfox ?"])},
  "ACTIVITY_TABLE_MODAL_HELPER_STEP_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Renseignez l’ID situé sur la boîte de l’objet"])},
  "ACTIVITY_TABLE_MODAL_HELPER_STEP_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Souscrivez à l’abonnement de l’objet"])},
  "ACTIVITY_TABLE_MODAL_HELPER_STEP_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Configurez l’objet"])},
  "ACTIVITY_TABLE_MODAL_CANNCEL_BUTTON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
  "ACTIVITY_TABLE_MODAL_APPROVE_BUTTON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer les modifications"])},
  "ACTIVITY_TABLE_MODAL_DELETE_OBJECT_BUTTON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer l’objet"])},
  "ACTIVITY_TABLE_ADD_RESIDENCE_MODAL_HEADER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un objet"])},
  "ACTIVITY_TABLE_ADD_RESIDENCE_MODAL_FIELD_CLIENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Géré par"])},
  "ACTIVITY_TABLE_ADD_RESIDENCE_MODAL_FIELD_RESIDENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la résidence"])},
  "ACTIVITY_TABLE_ADD_RESIDENCE_MODAL_FIELD_OBJECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usage"])},
  "ACTIVITY_TABLE_ADD_RESIDENCE_MODAL_FIELD_BUILD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bâtiment"])},
  "ACTIVITY_TABLE_ADD_RESIDENCE_MODAL_FIELD_STAIRCASE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cage d'escalier"])},
  "ACTIVITY_TABLE_ADD_RESIDENCE_MODAL_FIELD_MODEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marque et modèle"])},
  "ACTIVITY_TABLE_ADD_RESIDENCE_MODAL_FIELD_SELECT_OBJECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez les objets que vous souhaitez installer"])},
  "ACTIVITY_TABLE_ADD_RESIDENCE_MODAL_FIELD_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de l’objet"])},
  "ACTIVITY_TABLE_ADD_RESIDENCE_MODAL_CHECKBOX_TYPE_SKYDOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skydome"])},
  "ACTIVITY_TABLE_ADD_RESIDENCE_MODAL_CHECKBOX_TYPE_RAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispositif rat"])},
  "ACTIVITY_TABLE_ADD_RESIDENCE_MODAL_CHECKBOX_TYPE_POMPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pompe de relevage"])},
  "ACTIVITY_TABLE_ADD_RESIDENCE_MODAL_CHECKBOX_TYPE_VMC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VMC"])}
}