import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = { class: "ui-custom-select__prepend" }
const _hoisted_2 = {
  key: 1,
  class: "ui-custom-select__selected-name"
}
const _hoisted_3 = { class: "ui-custom-select__suffix" }
const _hoisted_4 = { class: "ui-custom-select__chevron" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "ui-custom-select__error" }

export function render(_ctx, _cache) {
  const _component_UiInputText = _resolveComponent("UiInputText")
  const _directive_click_outside = _resolveDirective("click-outside")

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
    `ui-custom-select`,
    {'ui-custom-select--auto-expand': _ctx.autoExpand,
    'ui-custom-select--active': _ctx.itemsListVisible,
    'ui-custom-select--disabled': _ctx.disabled },
  ]),
    style: _normalizeStyle({width: _ctx.comboBoxWidth})
  }, [
    _createElementVNode("div", {
      role: "combobox",
      class: _normalizeClass([
      `ui-custom-select__selected`,
      {'ui-custom-select__selected--active': _ctx.itemsListVisible},
      `bg-${_ctx.bgColor}`
    ]),
      onClick: _cache[0] || (_cache[0] = $event => (_ctx.itemsListVisible = !_ctx.itemsListVisible))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "prepend")
      ]),
      (_ctx.placeholder && !_ctx.getValue(_ctx.selectedOption))
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["ui-custom-select__placeholder", _ctx.placeholderClass])
          }, _toDisplayString(_ctx.placeholder), 3))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.getName(_ctx.selectedOption)), 1)),
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "append", { active: _ctx.itemsListVisible }, () => [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", {
              class: _normalizeClass(["ui-custom-select__chevron-arrow ui-custom-select__chevron-arrow--up", {'ui-custom-select__chevron--active': _ctx.itemsListVisible}])
            }, null, 2),
            _createElementVNode("div", {
              class: _normalizeClass(["ui-custom-select__chevron-arrow ui-custom-select__chevron-arrow--down", {'ui-custom-select__chevron--active': _ctx.itemsListVisible}])
            }, null, 2)
          ])
        ])
      ])
    ], 2),
    _withDirectives(_createElementVNode("div", {
      role: "listbox",
      ref: "listboxEl",
      class: _normalizeClass(['ui-custom-select__options', `bg-${_ctx.bgColor}`, 
    {'ui-custom-select__options--auto-expand': _ctx.autoExpand}])
    }, [
      (_ctx.search)
        ? (_openBlock(), _createBlock(_component_UiInputText, {
            key: 0,
            modelValue: _ctx.searchFilter,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.searchFilter) = $event)),
            class: "ui-custom-select__filter",
            "native-el-attrs": _ctx.filterAttrs,
            "no-error-field": "",
            placeholder: _ctx.searchPlaceholder,
            onInput: _ctx.onSearchFilterInput
          }, null, 8, ["modelValue", "native-el-attrs", "placeholder", "onInput"]))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visibleOptions, (option, i) => {
        return (_openBlock(), _createElementBlock("div", {
          role: "option",
          key: i,
          class: _normalizeClass([
        `ui-custom-select__option`,
        {
          [`ui-custom-select__option--selected`]:
            option === _ctx.selectedOption,
        },
        {'ui-custom-select__option--auto-expand': _ctx.autoExpand}
      ]),
          onClick: $event => (_ctx.onSelect(option))
        }, _toDisplayString(typeof option === 'object' ? option[_ctx.keyName] : option), 11, _hoisted_5))
      }), 128))
    ], 2), [
      [_vShow, _ctx.itemsListVisible]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_6, [
      _renderSlot(_ctx.$slots, "error", {}, () => [
        _createTextVNode(_toDisplayString(_ctx.errorMessage), 1)
      ])
    ], 512), [
      [_vShow, _ctx.errorMessage]
    ])
  ], 6)), [
    [_directive_click_outside, _ctx.onClickOutside]
  ])
}