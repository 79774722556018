export type { ITabIndex } from './types'

export const DEFAULT_TAB_INDEX_VALUE = 0

export const STORY_SETTINGS = {
  name: 'tabIndex',
  type: { name: 'number', required: false },
  table: {
    type: { summary: 'number' },
    defaultValue: { summary: DEFAULT_TAB_INDEX_VALUE },
  },
  control: 'number',
  description: 'Indicates that the user cannot modify the value of the control',
  defaultValue: DEFAULT_TAB_INDEX_VALUE,
}

export default {
  type: Number,
  default: DEFAULT_TAB_INDEX_VALUE,
}
