export const DEFAULT_MODEL_VALUE = true
export const DEFAULT_TITLE_VALUE = ''
export const DEFAULT_SHOW_CLOSE_VALUE = true
export const DEFAULT_WIDTH_VALUE = '50%'
export const DEFAULT_OVERLAY_VALUE = true
export const DEFAULT_WRAPPER_CLASS_VALUE = ''
export const DEFAULT_ROUNDED_VALUE = 'md'
export const DEFAULT_PERSISTENT_VALUE = false
export interface IProps {
  modelValue: boolean
  header?: string
  showClose?: boolean
  width?: string
  wrapperClass?: string
}

export const modelValue = {
  type: Boolean,
  default: DEFAULT_MODEL_VALUE,
  required: false,
}

export const title = {
  type: String,
  default: DEFAULT_TITLE_VALUE,
  required: false,
}

export const showClose = {
  type: Boolean,
  default: DEFAULT_SHOW_CLOSE_VALUE,
  required: false,
}

export const width = {
  type: String,
  default: DEFAULT_WIDTH_VALUE,
  required: false,
}

export const overlay = {
  type: Boolean,
  default: DEFAULT_OVERLAY_VALUE,
  required: false,
}

export const wrapperClass = {
  type: String,
  default: DEFAULT_WRAPPER_CLASS_VALUE,
  required: false,
}

export const persistent = {
  type: Boolean,
  default: DEFAULT_PERSISTENT_VALUE,
  required: false,
}

export default {
  modelValue,
  title,
  showClose,
  width,
  overlay,
  wrapperClass,
  persistent,
}
