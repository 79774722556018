import { defineComponent, inject, ref } from 'vue'
import {
  DEFAULT_PREFIX,
  DEFAULT_THEME,
} from '@/components/shared/constants/global'
import QButton from '../Button/Button.vue'
import QIcon from '../Icon/Icon.vue'
import { default as props, IProps } from './props'

export default defineComponent({
  components: { QButton, QIcon },
  props,
  emits: ['hide', 'show'],
  setup(props: IProps, { emit }) {
    const prefix = inject('prefix', DEFAULT_PREFIX)
    const theme = inject('theme', DEFAULT_THEME)

    const dropdownMenuVisible = ref(false)
    const triggerEl = ref(document.createElement('div'))
    const listTop = ref(0)

    const show = () => {
      listTop.value = triggerEl.value?.offsetHeight + props.listOffset
      dropdownMenuVisible.value = !dropdownMenuVisible.value
    }

    
    return {
      prefix,
      theme,
      dropdownMenuVisible,
      triggerEl,
      listTop,
      show,
    }
  },
})
