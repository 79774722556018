import { createRouter, createWebHistory } from 'vue-router'
import { get } from 'lodash'
import { isTokenExist } from '@/helpers/token'
import { routes } from './routes'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, form, next) => {
  if (get(to, 'meta.requiresAuth', false)) {
    if (!isTokenExist()) {
      next({
        path: '/auth',
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
