import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.selectComponent), _mergeProps({
    class: [
    `${_ctx.prefix}-select`,
    `${_ctx.prefix}-select--theme-${_ctx.theme}`,
    { [`${_ctx.prefix}-select--native`]: _ctx.native },
  ]
  }, {..._ctx.$attrs, ..._ctx.$props}), _createSlots({ _: 2 }, [
    _renderList(_ctx.$slots, (_, slot) => {
      return {
        name: slot,
        fn: _withCtx((scope) => [
          _renderSlot(_ctx.$slots, slot, _normalizeProps(_guardReactiveProps(scope)), undefined, true)
        ])
      }
    })
  ]), 1040, ["class"]))
}