import { defineComponent, inject } from 'vue'
import {
  DEFAULT_PREFIX,
  DEFAULT_THEME,
} from '@/components/shared/constants/global'

export default defineComponent({
  setup() {
    const prefix = inject('prefix', DEFAULT_PREFIX)
    const theme = inject('theme', DEFAULT_THEME)

    return {
      prefix,
      theme,
    }
  },
})
