import {
  createI18n,
  LocaleMessages,
  VueMessageType,
  IntlDateTimeFormats,
} from 'vue-i18n'
import { DEFAULT_LOCALE } from '@/constants'

function loadLocaleMessages(): LocaleMessages<VueMessageType> {
  const locales = require.context('../i18n', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages: LocaleMessages<VueMessageType> = {}
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key).default
    }
  })
  return messages
}

const datetimeFormats: IntlDateTimeFormats = {
  fr: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    medium: { dateStyle: 'medium' },
    long: {
      month: 'short',
      year: 'numeric',
      weekday: 'long',
      minute: 'numeric',
      hour: 'numeric',
      day: 'numeric',
      hour12: true,
    },
    date: {
      weekday: 'long',
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    },
    time: {
      hour: 'numeric',
      minute: 'numeric',
    },
  },
}

export default createI18n({
  legacy: false,
  locale: DEFAULT_LOCALE,
  fallbackLocale: DEFAULT_LOCALE,
  globalInjection: true,
  messages: loadLocaleMessages(),
  datetimeFormats,
})
