import { defineComponent, ref, computed } from 'vue'
import QIcon from '../Icon/Icon.vue'
import { default as props, IProps } from './props'

export default defineComponent({
  components: { QIcon },
  props,
  emits: [
    'update:modelValue',
    'showPassword',
    'clear',
    'input',
    'change',
    'focus',
    'blur',
    'mouseenter',
    'mouseleave',
  ],
  setup(props: IProps, { emit }) {
    const focused = ref(false)

    const onFocus = (event: InputEvent) => {
      focused.value = true
      emit('focus', event)
    }

    const onBlur = (event: InputEvent) => {
      focused.value = false
      emit('blur', event)
    }

    const showClearCross = computed(() => {
      return Boolean(
        props.clearable && props.modelValue?.length > 0 && props.errorMessage
      )
    })

    return {
      focused,
      onFocus,
      onBlur,
      showClearCross,
    }
  },
})
