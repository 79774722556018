import Button from '@/components/UI/Button/Button.vue'
import Icon from '@/components/UI/Icon/Icon.vue'
import InputText from '@/components/UI/InputText/InputText.vue'
import InputCheckbox from '@/components/UI/InputCheckbox/InputCheckbox.vue'
import Modal from '@/components/UI/Modal/Modal.vue'
import Select from '@/components/UI/Select/Select.vue'
import InputSearch from '@/components/UI/InputSearch/InputSearch.vue'
import Table from '@/components/UI/Table/Table.vue'
import Pagination from '@/components/UI/Pagination/Pagination.vue'
import TextArea from '@/components/UI/TextArea/TextArea.vue'
import NativeSelect from '@/components/UI/NativeSelect/NativeSelect.vue'
import Dropdown from '@/components/UI/Dropdown/Dropdown.vue'
import DropdownMenu from '@/components/UI/DropdownMenu/DropdownMenu.vue'
import DropdownMenuItem from '@/components/UI/DropdownMenuItem/DropdownMenuItem.vue'
import NativeRadio from '@/components/UI/NativeRadio/NativeRadio.vue'

//isUsed: Pagination, Table, NativeSelect, Button, Icon, InputSearch, Modal, Dropdown

import { App } from 'vue'

const components = {
  Button,
  Icon,
  InputText,
  InputCheckbox,
  Modal,
  Select,
  InputSearch,
  Table,
  Pagination,
  TextArea,
  NativeSelect,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  NativeRadio,
}

const install = (
  app: App,
  options: {
    prefix: string
  }
): void => {
  Object.entries(components).forEach(([name, component]) => {
    app.component(`${options.prefix}${name}`, {
      ...component,
      name: `${options.prefix}${name}`,
    })
  })
}

export default install
